import { Injectable } from '@angular/core';
import { ClientErreur } from "../models/clientErreur.model";
import { Subject } from "rxjs";

@Injectable()
export class ClientErreurService {
    public clientErreur: Subject<ClientErreur> = new Subject<ClientErreur>();
    public erreurSurvenue(erreur: ClientErreur) {
      this.clientErreur.next(erreur);
  }
} 