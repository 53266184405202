export const typesEvenement = [
    {
      'TypeId': 1,
      'TypeName': 'None'
    },
    {
      'TypeId': 2,
      'TypeName': 'Commentaire'
    },
    {
      'TypeId': 3,
      'TypeName': 'Action'
    },
    {
      'TypeId': 4,
      'TypeName': 'Recommandation'
    },
    {
      'TypeId': 5,
      'TypeName': 'EnvoiCourrier'
    }
  ];