import { Component, OnInit, ViewChild } from "@angular/core";
import { Observable } from 'rxjs/Observable';
import { TransactionService } from "src/app/services/transaction.service";
import { ITransaction } from 'src/app/models.interfaces/transaction.model.interface';

@Component({
    selector: 'app-payer-transaction',
    templateUrl: './payer-transaction.component.html',
    providers: [TransactionService]
})
export class PayerTransactionComponent implements OnInit {
    constructor(private _transactionService: TransactionService) { }
    ngOnInit() { }

    public transactionCode: number;
    public isSearchSpinning: boolean = false;
    public transaction: ITransaction;
    public showTransaction: boolean = false;
    public datePaiement: Date = new Date();
    public modeReglementCheque: boolean = false;
    public isPayerSpinning: boolean = false;
    public payerMessage: string;

    public onSearchClick() {
        if (this.transactionCode) {
            this.isSearchSpinning = true;
            this.showTransaction = false;
            this._transactionService.searchTransaction(this.transactionCode)
                .subscribe((transaction) => {
                    this.transaction = transaction;
                    this.isSearchSpinning = false;
                    this.showTransaction = true;
                });
        }
    }
    public onPayerClick() {
        this.isPayerSpinning = true;
        this.payerMessage = "Paiement en cours..."
        this._transactionService.fondsRecus(this.transactionCode, this.datePaiement, this.modeReglementCheque ? "CHEQUE" : "VIREMENT")
            .subscribe(() => {
                this.payerMessage = "Transaction payée."
                this.isPayerSpinning = false;
            });
    }
}