import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import {Subject} from 'rxjs/Subject';
import { IPackage } from '../../../models.interfaces/package.model.interface';
import { EmptyPackage } from '../../../models/package.model';
import { PackagesService } from 'src/app/services/packages.service';

@Component({
  selector: 'app-package-selector',
  templateUrl: './package-selector.component.html',
  styleUrls: ['./package-selector.component.scss'],
  providers: [PackagesService]
})
export class PackageSelectorComponent implements OnInit {

  public packages: IPackage[] = [];
  public searchPackageTerm = new Subject<string>();
  private _package: IPackage;
  @Input() public get package(): IPackage {
    return this._package;
  }
  public set package(value: IPackage) {
    if (value instanceof EmptyPackage)
    {
      this.autocomplete.reset();
      this.packages = [];
    }
    this._package = value;
    this.packageChange.emit(this._package);
  }
  @Output() public packageChange: EventEmitter<IPackage> = new EventEmitter<IPackage>();
  @ViewChild(AutoCompleteComponent, {static: true}) autocomplete: AutoCompleteComponent;

  constructor(private _packagesService: PackagesService) { }

  ngOnInit() {
    this
      .searchPackageTerm
      .distinctUntilChanged()
      .debounceTime(400)
      .subscribe(term => {
                          this.packages = [];
                          this
                            ._packagesService
                            .getPackages(term)
                            .subscribe(packages => this.packages = packages);
                      });
  }

  public handleFilter(text: string) {
    this.searchPackageTerm.next(text);
  }

  public onValueChange(value: string) {
    const selectedPackage = this.packages.find(c => c.nom === value);
    this.package = selectedPackage;
  }

}
