import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Helper } from '../utils/helper';
import { IFactureHonorairesSummaryDto } from '../dtos/factureHonorairesSummary.dto';
import { IFactureHonorairesSummary } from '../models.interfaces/factureHonorairesSummary.model.interface';
import { IFactureHonorairesSearchModel } from '../models.interfaces/factureHonorairesSearch.model.interface';
import { BuInterceptorService } from './buInterceptor.service';
import { FactureHonorairesGenerationResult } from '../models/factureHonorairesGenerationResult.model';
import { suiviEnvoiMailDto } from '../dtos/suiviEnvoiMail.dto';

@Injectable()
export class FactureHonorairesService {

    public results: BehaviorSubject<GridDataResult> = new BehaviorSubject<GridDataResult>(null);
    public errorMessages: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private http: HttpClient) {}

    private getApiUrl(): string {
        return `${environment.apiBaseUrl}/FacturesHonoraires/${this.bu()}`
    }

    private bu(): string {
        return BuInterceptorService.Bu;
    }

    public query(searchModel: IFactureHonorairesSearchModel, state: any): void {
        this.getFactures(searchModel, state)
            .subscribe(
                x => this.results.next(x),
                (err: Error) => this.errorMessages.next(err.message))
    }

    public getFactures(searchModel: IFactureHonorairesSearchModel, state: any): Observable<GridDataResult> {
        return this
            .http
            .post<any>(
                `${this.getApiUrl()}/search`,
                {
                    skip: state.skip,
                    take: state.take,
                    numeroFacture: searchModel.numero,
                    dateFactureDu: searchModel.du,
                    dateFactureAu: searchModel.au,
                    statutFacture: searchModel.statut.statut,
                    montantTtcMin: searchModel.min,
                    montantTtcMax: searchModel.max,
                    transactionCodeNum: searchModel.transactionCodeNum,
                    nom: searchModel.nom
                }
            )
            .map(res => <GridDataResult>{total: res.Count, data: Helper.mapDtoToModels(res.Result, this.mapFacture)});
    }


    private mapFacture(dto: IFactureHonorairesSummaryDto): IFactureHonorairesSummary {
        return <IFactureHonorairesSummary> {
          id: dto.Id,
          date: new Date(dto.DateFacture),
          datePaiement: (dto.DatePaiement == null) ? null : new Date(dto.DatePaiement),
          statut: dto.Statut,
          numero: dto.NumeroFacture,
          documenturl: Helper.fileManagerUrlFactureHonoraires(BuInterceptorService.Bu, dto.Id),
          typeAffaireEtTypeBien: `${dto.TypeAffaire} / ${dto.TypeBien}`,
          transactionCodeNum: dto.TransactionCodeNum,
          transactionUrl: Helper.tremplinTransactionRemunerationUrl(BuInterceptorService.Bu, dto.TransactionCodeNum),
          totalHt: dto.Ht,
          totalTva: dto.Tva,
          totalTtc: dto.Ttc,
          nomClients: dto.NomClients
        };
    }

    public getTransactionsForFacturationHonoraires(): Observable<number[]> {
        return this
            .http
            .get<number[]>(`${this.getApiUrl()}/transactionsForFacturation`);
    }

    public genererFacturesHonoraires(transactionCodeNum: number): Observable<FactureHonorairesGenerationResult> {
        return this
            .http
            .post<any>(`${this.getApiUrl()}/generer/transactioncode/${transactionCodeNum}`, '')
            .map(x => {
                var res = new FactureHonorairesGenerationResult();
                res.transactionCodeNum = transactionCodeNum;
                res.transactionUrl = Helper.tremplinTransactionRemunerationUrl(BuInterceptorService.Bu, transactionCodeNum);
                if(x.ErrorMessage) {
                    res.erreur = true;
                    res.erreurMessage = x.ErrorMessage;
                }
                return res;
            });
    }

    public getFacturesHonorairesEnPreparation(): Observable<string[]> {
        return this
            .http
            .get<string[]>(`${this.getApiUrl()}/enpreparation`);
    }
    
    public numeroterFactureHonoraires(factureId: string): Observable<string> {
        return this
            .http
            .post<string>(`${this.getApiUrl()}/numeroter/${factureId}`, '');
    }

    public getFacturesHonorairesNumerotees(): Observable<string[]> {
        return this
            .http
            .get<string[]>(`${this.getApiUrl()}/numerotees`);
    }
    
    public envoyerFactureHonoraires(factureId: string): Observable<any> {
        return this
            .http
            .post<any>(`${this.getApiUrl()}/envoyer/${factureId}`, '');
    }

    public getSuiviEnvoi(factureId: string): Observable<suiviEnvoiMailDto[]> {
        return this
            .http
            .get<suiviEnvoiMailDto[]>(`${this.getApiUrl()}/suiviEnvoi/${factureId}`);
    }

    public payerFactureHonoraires(factureId: string, datePaiement: Date, modeReglement: string): Observable<any> {
        return this
            .http
            .post<any>(`${this.getApiUrl()}/payer/${factureId}`, {
                DatePaiement: datePaiement, ModeReglement: modeReglement
            });
    }

    public remplacerFacture(file: File, factureId: string): Observable<any> {
        const payload = new FormData();
        payload.append('file', file);
        return this
            .http
            .post<any>(`${this.getApiUrl()}/replace/${factureId}`, payload);
    }
    public annuler(factureId: string): Observable<any> {
        return this
            .http
            .post<any>(`${this.getApiUrl()}/annuler/${factureId}`, null);
    }

}
