export enum FactureStatutEnum {
    all = 0,
    enPreparation = 1 ,
    numerotee = 2 ,
    envoyee = 3,
    aPayer = 4,
    payee = 5,
    annulee = 6,
    payeeAvoir = 7
}
