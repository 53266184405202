import { Component, OnInit, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/Observable';
import { RowClassArgs, PageChangeEvent, GridDataResult, GridComponent, DataStateChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { FactureStatutEnum } from '../../../enums/factureStatut.enum';
import { IFacture } from '../../../models.interfaces/facture.model.interface';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { FactureCommissionService } from 'src/app/services/factureCommission.service';
import { IFactureCommissionSearchModel } from 'src/app/models.interfaces/factureCommissionSearch.model.interface';
import { EmptyFactureCommissionSearchModel } from 'src/app/models/emptyFactureCommissionSearch.model';
import { IFactureCommissionSummary } from 'src/app/models.interfaces/factureCommissionSummary.model.interface';
import { AlertService} from '../../../services/alert.service';

@Component({
  selector: 'app-factures-commission-grid',
  templateUrl: './factures-commission-grid.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./factures-commission-grid.component.scss'],
  providers: [FactureCommissionService]
})
export class FacturesCommissionGridComponent implements OnInit {

    public searchModel: IFactureCommissionSearchModel = new EmptyFactureCommissionSearchModel();
    public factures: Observable<GridDataResult>;
    private errors: Observable<string>;
    private totalBaseCalculHt: number;
    private totalHt: number;
    private totalTva: number;
    private totalTtc: number;
    private selectedFactures: IFactureCommissionSummary[] = [];
    public selectedFactureIds: string[] = [];
    public pageSize = 100;
    public skip: number;
    public infoMessage: string;
    private isSpinning: boolean;

    @ViewChild(GridComponent, {static: true}) private grid: GridComponent;

    public selectableSettings: SelectableSettings = {
        checkboxOnly: true,
        mode: 'multiple'
    };

    public sort: SortDescriptor[] = [{
        field: 'dateFacture',
        dir: 'desc'
      }]; 
    
    constructor(private _factureCommissionService: FactureCommissionService, private alertService: AlertService) {
        this.factures = _factureCommissionService.results;
        this.errors = _factureCommissionService.errorMessages;
    }
    
    public refresh() {
        this.isSpinning = true;
        this.infoMessage = null;
        this._factureCommissionService.query(this.searchModel, { skip: this.skip, take: this.pageSize });
    }
    
    public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;
        this.refresh();
    }
    
    public ngOnInit(): void {
        this
          .factures
          .subscribe(
            f =>
            {
              if (!f) return;
              this.setFacturesAndSum(f.data);
              f.data = orderBy(f.data, this.sort);
            },
            error => this.infoMessage = error.message
        );
        this
          .errors
          .subscribe(x => this.infoMessage = x);
        Observable
          .merge(this.factures, this.errors)
          .do(() => this.isSpinning = false)
          .subscribe();
      }
    
    public ngAfterViewInit(): void {
        setTimeout(() => {
        this.grid
        .dataStateChange
        .do(({ skip, take }: DataStateChangeEvent) => {
            this.skip = skip;
            this.pageSize = take;
        })
        .subscribe(x => this.refresh());    
    });
    }

    public applySearchModel(searchModel: IFactureCommissionSearchModel) {
        this.searchModel = searchModel;
        this.refresh();
      }
    
      public rowCallback(context: RowClassArgs) {
        switch (context.dataItem.statut as FactureStatutEnum) {
          default:
            return {};
          case FactureStatutEnum.enPreparation:
            return { enPreparation: true };
          case FactureStatutEnum.numerotee:
            return { numerotee: true };
          case FactureStatutEnum.envoyee:
            return { envoyee: true };
          case FactureStatutEnum.aPayer:
            return { aPayer: true };
          case FactureStatutEnum.payee:
            return { payee: true };
          case FactureStatutEnum.annulee:
            return { annulee: true };
          case FactureStatutEnum.payeeAvoir:
            return { payeeAvoir: true };
        }
      }

      public onSelectionChange(event: SelectionEvent) {
        if(event.selectedRows.length === 0) this.clearSelection();
        else event.selectedRows.forEach(r => this.selectedFactures.push(r.dataItem));
      }

      
  private setFacturesAndSum(factures: any[]) {
    let totBase = 0;
    let totHt = 0;
    let totTva = 0;
    let totTtc = 0;
    factures.forEach(f => {
      totBase += f.baseCalculHt;
      totHt += f.totalHt;
      totTva += f.totalTva;
      totTtc += f.totalTtc;
    });
    this.totalBaseCalculHt = totBase;
    this.totalHt = totHt;
    this.totalTva = totTva;
    this.totalTtc = totTtc;
  }

  private clearSelection() {
    this.selectedFactures = [];
    this.selectedFactureIds = [];
  }

  private displayError(message: string) {
    this.alertService.error(message,
      { autoClose: false }
    );
  }

  public validerClick() {
    this.isSpinning = true;
    this.infoMessage = null;
    if (this.selectedFactures.length > 0) {
      this.validerFacture(0);
    }
  }

  private validerFacture(index: number) {
    if (index < this.selectedFactures.length) {
      this.infoMessage = `Validation facture (${index + 1}/${this.selectedFactures.length})`
      this._factureCommissionService
        .valider(this.selectedFactures[index].id)
        .subscribe(
          res => {
            if (res.HasErreur) {
              this.infoMessage = res.ErreurMessage;
              this.isSpinning = false;
            } else {
              if (res.FactureValidee) {
                this.selectedFactures[index].statut = FactureStatutEnum.aPayer;
              } else {
                this.selectedFactures[index].statut = FactureStatutEnum.annulee;
              }
              this.validerFacture(index + 1);
            }
          },
          error => {
            this.infoMessage = error.message;
          }
        )
    } else {
      this.infoMessage = "Validation terminée."
      this.isSpinning = false;
    }
  }

  public payerClick() {
    this.isSpinning = true;
    this.infoMessage = null;
    if (this.selectedFactures.length > 0) {
      this.payerFacture(0);
    }
  }

  private payerFacture(index: number) {
    if (index < this.selectedFactures.length) {
      this.infoMessage = `Paiement facture (${index + 1}/${this.selectedFactures.length})`
      this._factureCommissionService
        .payer(this.selectedFactures[index].id)
        .subscribe(
          res => {
            if (res.HasErreur) {
              this.infoMessage = res.ErreurMessage;
              this.isSpinning = false;
            } else {
              this.selectedFactures[index].statut = FactureStatutEnum.payee;
              this.payerFacture(index + 1);
            }
          },
          error => {
            this.infoMessage = error.message;
          }
        )
    } else {
      this.infoMessage = "Paiement terminée."
      this.isSpinning = false;
    }
  }

  public annulerClick() {
    this.isSpinning = true;
    this.infoMessage = null;
    if (this.selectedFactures.length > 0) {
      this.annulerFacture(0);
    }
  }

  private annulerFacture(index: number) {
    if (index < this.selectedFactures.length) {
      this.infoMessage = `Annulation facture (${index + 1}/${this.selectedFactures.length})`
      this._factureCommissionService
        .annuler(this.selectedFactures[index].id)
        .subscribe(
          res => {
            if (res.HasErreur) {
              this.infoMessage = res.ErreurMessage;
              this.isSpinning = false;
            } else {
              this.selectedFactures[index].statut = FactureStatutEnum.annulee;
              this.annulerFacture(index + 1);
            }
          },
          error => {
            this.infoMessage = error.message;
          }
        )
    } else {
      this.infoMessage = "Annulation terminée."
      this.isSpinning = false;
    }
  }

}