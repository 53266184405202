import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { environment } from "src/environments/environment";
import { IArticleDto } from "../dtos/article.dto";
import { IEnvelope } from "../dtos/envelope.dto";
import { IArticle } from "../models.interfaces/article.model.interface";
import { Helper } from "../utils/helper";
import { BuInterceptorService } from "./buInterceptor.service";

@Injectable()
export class ArticlesService {

    constructor(private http: HttpClient) {}

    private getApiUrl(): string {
        return `${environment.apiBaseUrl}/Articles/${this.bu()}`
    }

    private bu(): string {
        return BuInterceptorService.Bu;
    }

    public getArticles(isPlannifiablesOnly: boolean): Observable<IArticle[]> {
        return this
            .http
            .get<IEnvelope<IArticleDto[]>>(`${this.getApiUrl()}/?isPlannifiableOnly=${isPlannifiablesOnly}`)
            .map(dtos => Helper.mapDtoToModels(dtos.Result, this.mapArticle));
    }

    public addArticle(article: IArticleDto): Observable<any> {
        return this.http
            .post(`${this.getApiUrl()}`, article);
    }

    public updateArticle(article: IArticleDto): Observable<any> {
        return this.http
            .put(`${this.getApiUrl()}/${article.Id}`, article);
    }

    public deleteArticle(article: IArticleDto) : Observable<any> {
        return this.http
            .delete(`${this.getApiUrl()}/${article.Id}`);
    }

    private mapArticle(dto: IArticleDto): IArticle {
        const res = <IArticle>{
            nom: dto.Designation,
            ht: dto.PrixUnitaireHt,
            isPlannifiable: dto.IsPlannifiable,
            compteDeProduitMetropole: dto.CompteDeProduitMetropole,
            compteDeProduitDomTom: dto.CompteDeProduitDomTom,
            compteDeProduitGuyaneMayote: dto.CompteDeProduitGuyaneMayote,
            compteDeProduitSaintMartin: dto.CompteDeProduitSaintMartin,
            code: dto.Code,
            id: dto.Id,
            nePasAppliquerTva: dto.NePasAppliquerTva
        };
        return res;
    }

}