import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiService {

    private baseapiUrl = environment.apiBaseUrl;

    constructor(private http: HttpClient) {
    }

    public getVersion(): Observable<string> {
        return this.http.get<string>(`${this.baseapiUrl}/version`);
    }    

}
