import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { firstDayOfMonth, lastDayOfMonth, addMonths } from '@progress/kendo-date-math';
import { FactureStatutsArray } from '../../../models.interfaces/factureStatut.model.interface';
import { IFacturePackSearchModel } from '../../../models.interfaces/facturePackSearch.model.interface';
import { EmptyFacturePackSearchModel } from '../../../models/emptyFacturePackSearch.model';
import { IConseiller } from '../../../models.interfaces/conseiller.model.interface';

@Component({
  selector: 'app-factures-pack-search',
  templateUrl: './factures-pack-search.component.html',
  styleUrls: ['./factures-pack-search.component.scss']
})
export class FacturesPackSearchComponent implements OnInit {

  public statuts = FactureStatutsArray;
  public searchModel: IFacturePackSearchModel = new EmptyFacturePackSearchModel();
  @Output() public searchClicked: EventEmitter<IFacturePackSearchModel> = new EventEmitter<IFacturePackSearchModel>();

  constructor() {
  }

  ngOnInit() {
  }
/*
  public setConseiller(value: IConseiller) {
    this.searchModel.conseiller = value;
  }
*/
  public setMoisPasses() {
    this.searchModel.du = null;
    this.searchModel.au = lastDayOfMonth(addMonths(new Date(), -1));
  }

  public setMoisEnCours() {
    const today = new Date();
    this.searchModel.du = firstDayOfMonth(today);
    this.searchModel.au = lastDayOfMonth(today);
  }

  public setMoisFuturs() {
    this.searchModel.du = firstDayOfMonth(addMonths(new Date(), 1));
    this.searchModel.au = null;
  }

  public onSearchClick() {
    this.searchClicked.emit(this.searchModel);
  }

  public onClearClick() {
    this.searchModel = new EmptyFacturePackSearchModel();
  }
}
