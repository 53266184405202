<kendo-grid
  [data]="factureDetails"
  >
  <kendo-grid-column field="libelle" title="Libellé">
  </kendo-grid-column>
  <kendo-grid-column field="quantite" title="Quantité">
  </kendo-grid-column>
  <kendo-grid-column field="proRataPrixUnitaireHt" title="PU" format="{0:c}">
  </kendo-grid-column>
  <kendo-grid-column field="ht" title="HT" format="{0:c}">
  </kendo-grid-column>
  <kendo-grid-column field="tva" title="TVA" format="{0:c}">
  </kendo-grid-column>
  <kendo-grid-column field="ttc" title="TTC" format="{0:c}">
  </kendo-grid-column>
  <kendo-grid-command-column title="" width="50">
      <ng-template kendoGridCellTemplate let-dataItem *ngIf='isEnPreparation'>
          <button class="btn btn-danger" (click)="deleteClick(dataItem.id)"><span class="fa fa-trash"></span></button>
      </ng-template>
  </kendo-grid-command-column>
</kendo-grid>