<form class="k-form row">
    <div class="col-md-3">
        <label class="k-form-field">
            <span>Conseiller:</span>
            <app-conseiller-selector
                [(conseiller)]='newFactureDetailModel.conseiller'
                name="conseiller">
            </app-conseiller-selector>
        </label>
        <div class="row">
            <div class="k-form-field col-md-6">
                <span>Type de produit:</span>
                <input type="radio" name="typeDeProduit" id="article" class="k-radio" [checked]="newFactureDetailModel.typeDeProduit === 0"  (change)="onTypeDeProduitChange(0)" /><label class="k-radio-label" for="article">Article</label>
                <input type="radio" name="typeDeProduit" id="pack" class="k-radio" [checked]="newFactureDetailModel.typeDeProduit === 1" (change)="onTypeDeProduitChange(1)" /><label class="k-radio-label" for="pack">Pack</label>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div *ngIf="newFactureDetailModel.typeDeProduit==1; else elseArticleBlock">
            <label class="k-form-field">
                <span>Pack</span>
                <app-package-selector
                    [(package)]='newFactureDetailModel.pack'
                    name="pack">
                </app-package-selector>
            </label>
            <div class="row">
                <label class="k-form-field col-md-6">
                    <span>Début de consommation:</span>
                    <kendo-datepicker
                        [(ngModel)]="newFactureDetailModel.du" name="du">
                    </kendo-datepicker>
                </label>
                <label class="k-form-field col-md-6">
                    <span>Fin de consommation:</span>
                    <kendo-datepicker
                        [(ngModel)]="newFactureDetailModel.au" name="au">
                    </kendo-datepicker>
                </label>
            </div>
        </div>
        <ng-template #elseArticleBlock>
            <label class="k-form-field">
                <span>Article</span>
                <app-article-selector
                    [(article)]='newFactureDetailModel.article'
                    name="article">
                </app-article-selector>
            </label>
            <label class="k-form-field">
                <span>Date de consommation:</span>
                <kendo-datepicker
                    [(ngModel)]="newFactureDetailModel.le" name="le">
                </kendo-datepicker>
            </label>
        </ng-template>
    </div>
    <div class="col-md-6">
        <div class="row">
            <label class="k-form-field col-md-4">
                <span>Compte:</span>
                <input
                    [(ngModel)]="newFactureDetailModel.compte"
                    name="compte"
                    class="k-textbox"
                    placeholder="ex 70800000" />
            </label>
            <label class="k-form-field col-md-4">
                <span>Désignation:</span>
                <input
                    [(ngModel)]="newFactureDetailModel.designation"
                    name="designation"
                    class="k-textbox"
                    placeholder="désignation..." />
            </label>
            <label class="k-form-field col-md-4">
                <span>Ne pas appliquer la TVA:</span>
                <input
                    name="NePasAppliquerTva"
                    type="checkbox"
                    class="k-checkbox"
                    (change)="changeNoTva(newFactureDetailModel.NePasAppliquerTva)"/>
            </label>
        </div>
        <div class="row">
            <label class="k-form-field col-md-4">
                <span>Prix Unitaire HT</span>
                <kendo-numerictextbox
                    [(value)]="newFactureDetailModel.puHt"
                    [format]="'c2'"
                    placeholder="ex 9,99" >
                </kendo-numerictextbox>
            </label>
            <label class="k-form-field col-md-4">
                <span>Quantité:{{newFactureDetailModel.quantite}}</span>
                <kendo-numerictextbox
                    [(value)]="newFactureDetailModel.quantite"
                    [format]="'n'"
                    placeholder="ex 5" 
                    decimals="0"
                    min="1"
                    >
                </kendo-numerictextbox>
            </label>
            <label class="k-form-field col-md-4">
                <span>Total HT:</span>
                <label
                    name="ht"
                    class="k-textbox">
                    {{newFactureDetailModel.totalHt | currency:'EUR':'symbol':'1.2-2'}}
                </label>
            </label>
        </div>
        <div class="text-right">
            <button kendoButton (click)="onSaveClick()" [primary]="true">Enregistrer</button>
            <button kendoButton (click)="onClearClick()" >Effacer</button>
        </div>
    </div>
    <div>
        <span *ngIf='isSpinning' class="k-i-loading k-icon" style="font-size: 32px;"></span>
        <span [ngStyle]="{'color': infoColor}">{{infoMessage}}</span>
    </div>
</form>
