import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { BuInterceptorService } from "./buInterceptor.service";
import { ITransaction } from '../models.interfaces/transaction.model.interface';

@Injectable()
export class TransactionService {
  constructor(private http: HttpClient) {}
  private getApiUrl(): string {
    return `${environment.apiBaseUrl}/Transactions/${this.bu()}`
  }

  private bu(): string {
      return BuInterceptorService.Bu;
  }

  public searchTransaction(transactionCode:number): Observable<ITransaction>{
    return this.http.get<any>(`${this.getApiUrl()}/${transactionCode}`)
      .map(transaction => <ITransaction> {
        transactionCodeNum: transaction.TransactionCodeNum,
        statut: transaction.Statut,
        typeAffaire: transaction.TypeAffaire,
        dateRdvSignatureActe: (transaction.DateRdvSignatureActe == null) ? null : new Date(transaction.DateRdvSignatureActe),
        dateEffectiveSignature: (transaction.DateEffectiveSignature == null) ? null : new Date(transaction.DateEffectiveSignature),
        honorairesRecuLe: (transaction.HonorairesRecuLe == null) ? null : new Date(transaction.HonorairesRecuLe),
        honoraireAgenceHt: transaction.HonoraireAgenceHt,
        honoraireAgenceTtc: transaction.HonoraireAgenceTtc,
        client1Nom: transaction.Client1Nom,
        client2Nom: transaction.Client2Nom
      });
  }

  public fondsRecus(transactionCode:number, datePaiement:Date, modeReglement:string): Observable<any>{
    return this.http.post<any>(`${this.getApiUrl()}/${transactionCode}/fondsRecus`, {
      DatePaiement: datePaiement, ModeReglement: modeReglement
    });
  }

}