<div>
    <span>{{infoMessage}}</span><div class="alert-section">
        <alert></alert>
      </div>
</div>
<kendo-grid
    [data]="factures | async"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="true"
    [selectable]="selectableSettings"
    [rowClass]="rowCallback"
    [kendoGridSelectBy]="'id'"
    [selectedKeys]="selectedFactureIds"
    (selectionChange)="onSelectionChange($event)"
    [sortable]="{ allowUnsort: true, mode: 'single' }"
    [sort]="sort"
    (sortChange)="sortChange($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <div class="row align-items-center col-md-12">
        <div class="col-md-3">
            <button title="Numéroter" class="btn numeroteeLegend" [disabled]='isSpinning' (click)="numeroterClick()"><span class="fa fa-sort-numeric-asc"></span></button>
            <button title="Envoyer" class="btn btn-info" [disabled]='isSpinning' (click)="envoyerClick()"><span class="fa fa-paper-plane"></span></button>
            <button title="Suivre envoi" class="btn btn-info" [disabled]='isSpinning' (click)="suivreEnvoiClick()"><span class="fa fa-envelope-open"></span></button>
            <button title="Payer" class="btn payeeLegend" [disabled]='isSpinning' (click)="payerClick()"><span class="fa fa-money"></span></button>
            <button title="Annuler" class="btn btn-danger" [disabled]='isSpinning' (click)="annulerClick()"><span class="fa fa-ban"></span></button>
        </div>
        <div class="col-md-2">
            <span *ngIf='isSpinning' class="k-i-loading k-icon" style="font-size: 32px;"></span>
        </div>
        <div class="col-md-7">
            <span style="padding-right:7px;">Légende:</span>
            <span class="enPreparationLegend" style="padding:7px;">En préparation</span>
            <span class="numeroteeLegend" style="padding:7px;">Numérotée</span>
            <span class="envoyeeLegend" style="padding:7px;">Envoyée</span>
            <span class="aPayerLegend" style="padding:7px;">A payer</span>
            <span class="payeeLegend" style="padding:7px;">Payée</span>
            <span class="annuleeLegend" style="padding:7px;">Annulée</span>
        </div>
    </div>
    </ng-template>
    <kendo-grid-checkbox-column showSelectAll="true" [class]="{'codeColumn': true}" width="60">
    </kendo-grid-checkbox-column>
    <kendo-grid-column field="numero" title="Numéro">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <a href='{{dataItem.documenturl}}' style="text-decoration: underline; color: blue;" target="_blank">{{dataItem.numero}}</a>
          </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="date" title="Date" type="date" format="{0:dd/MM/yy}"></kendo-grid-column>
    <kendo-grid-column field="datePaiement" title="Date paiement" type="date" format="{0:dd/MM/yy}"></kendo-grid-column>
    <kendo-grid-column field="typeAffaireEtTypeBien" title="Type Affaire / Bien"></kendo-grid-column>
    <kendo-grid-column field="nomClients" title="Nom Clients" width="250"></kendo-grid-column>
    <kendo-grid-column field="transactionCodeNum" title="Transaction">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <a href='{{dataItem.transactionUrl}}' style="text-decoration: underline; color: blue;" target="_blank">{{dataItem.transactionCodeNum}}</a>
          </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="totalHt" title="HT" format="{0:c}" [style]="{'text-align': 'right'}">
            <ng-template
                kendoGridFooterTemplate
                let-column="column">{{totalHt | kendoNumber:'c' }}</ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="totalTva" title="TVA" format="{0:c}" [style]="{'text-align': 'right'}">
            <ng-template
                kendoGridFooterTemplate
                let-column="column">{{totalTva | kendoNumber:'c' }}</ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="totalTtc" title="TTC" format="{0:c}" [style]="{'text-align': 'right'}">
            <ng-template
                kendoGridFooterTemplate
                let-column="column">{{totalTtc | kendoNumber:'c' }}</ng-template>
    </kendo-grid-column>
    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="100"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-info></kendo-pager-info>
    </ng-template>
</kendo-grid>


<kendo-window title="Suivi envoi" *ngIf="showPopupSuivi" (close)="hidePopupSuivi()" [minWidth]="250" [width]="450">
    <ng-container *ngFor="let infoSuivi of infosSuivi">
        {{infoSuivi.Type}} {{infoSuivi.Name}} {{infoSuivi.Email}}
        <ng-container *ngFor="let event of infoSuivi.Events">
            <br>{{event.Event}} {{event.EventDate}}
        </ng-container>
        <hr>
    </ng-container>
</kendo-window>

<kendo-window title="Réception fonds" *ngIf="showPopupPaiement" (close)="cancelPopupPaiement()" [minWidth]="250" [width]="450">
    <div class="container">
        <div class="row">
            <div class="col-sm">
                Date paiement:
            </div>
            <div class="col-sm">
                <kendo-datepicker [(ngModel)]="datePaiement" name="datePaiement"></kendo-datepicker>
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                Mode de règlement:
            </div>
            <div class="col-sm">
                <input [value]="false" [(ngModel)]="modeReglementCheque" type="radio" name="modeReglementGroup" id="checkVirement"/>
                <label for="checkVirement" style="margin:5px">Virement</label>
                <input [value]="true" [(ngModel)]="modeReglementCheque" type="radio" name="modeReglementGroup" id="checkCheque" />
                <label for="checkCheque" style="margin:5px">Chèque</label>
            </div>
        </div>
        <div class="row">
            <div class="col" style="text-align:right">
                <button kendoButton (click)="closePopupPaiement()" primary="true">Fonds reçus</button>
            </div>
        </div>
    </div>
</kendo-window>