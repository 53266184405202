import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BuInterceptorService } from '../../../services/buInterceptor.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  
  private titleService: Title;

  public get Bu(): string {
    return BuInterceptorService.Bu;
  }

  public get NotDigitRE(): boolean {
    return (BuInterceptorService.Bu != "DigitRE");
  }
  
  constructor(private route: ActivatedRoute, private _titleService: Title) {
    this.titleService = _titleService;
  }

  ngOnInit() {
      this.route.data.subscribe(d => {
        BuInterceptorService.Bu = d.bu;
        this.titleService.setTitle("Facturation " + d.bu);
      });
    }
}
