<div class="row SpanMargin">
  <form class="k-form row" #form="ngForm">
    <div class="col-md-2">
      <label>
        <span>Année:</span>
        <kendo-numerictextbox
          [(ngModel)]="searchModel.Annee"
          name="annee"
          [min]="2022"
          [format]="'#####'"
        >
        </kendo-numerictextbox>
      </label>
    </div>
    <div class="col-md-2">
      <label>
        <span>Mois:</span>
        <kendo-numerictextbox
          [(ngModel)]="searchModel.Mois"
          name="mois"
          [min]="1"
          [max]="12"
          [format]="'n0'"
        >
        </kendo-numerictextbox>
      </label>
    </div>
    <div class="col-md-2">
      <label>
        <span>Solde inferieur à:</span>
        <kendo-numerictextbox
          [(ngModel)]="searchModel.SoldeMax"
          name="soldeMax"
          [max]="0"
        >
        </kendo-numerictextbox>
      </label>
    </div>
    <div class="col-md-2">
      <label>
        <span>Code tiers:</span>
        <kendo-textbox
          [(ngModel)]="searchModel.CodeTiers"
          name="codeTiers"
        ></kendo-textbox>
      </label>
    </div>
    <div class="col-md-2">
      <label>
        <span>Statut conseiller:</span>
      </label>
      <br />
  <input type="radio" name="actif" value="Tous" [(ngModel)]="Actif"> Tous&nbsp;
  <input type="radio" name="actif" value="Actif" [(ngModel)]="Actif"> Actif&nbsp;
  <input type="radio" name="actif" value="Inactif" [(ngModel)]="Actif"> Inactif
    </div>
    <div class="col-md-2">
      <div class="divSearch">
        <div class="divButtonContent">
          <button
            kendoButton
            class="buttonSearch"
            (click)="onSearchClick()"
            [primary]="true"
          >
            Rechercher
          </button>
          <button kendoButton (click)="onClearClick()">Effacer</button>
          <span *ngIf="isSpinningSearch" class="k-i-loading k-icon" style="font-size: 32px"></span>
          <span class="SpanMargin" [ngStyle]="{ color: getSoldesInfoColor }">{{ getSoldesInfoMessage }}</span>
          
        </div>
      </div>
    </div>
  </form>
</div>


<div class="row SpanMargin">
  <kendo-grid
    #grid
    [data]="gridView"
    [pageSize]="gridState.take"
    [skip]="gridState.skip"
    [sort]="gridState.sort"
    [pageable]="true"
    [sortable]="true"
    (sortChange)="sortChange($event)"
    (dataStateChange)="onStateChange($event)"
    [resizable]="true"
  >
    <kendo-grid-column
      field="CodeTiers"
      title="Code tiers"
      [editable]="false"
      width="120"
      [style]="{ 'text-align': 'right' }"
    ><ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div  *ngIf="dataItem.Url !='' && dataItem.Url !=null">
        <a href='{{dataItem.Url}}' style="text-decoration: underline; color: blue;" target="_blank">{{dataItem.CodeTiers}}</a>
    </div>
    <div  *ngIf="dataItem.Url=='' || dataItem.Url ==null">
        <span>{{dataItem.CodeTiers}}</span>
    </div>
    </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="Prenom"
      title="Prenom"
      [editable]="false"
      width="120"
      [style]="{ 'text-align': 'right' }"
    ></kendo-grid-column>
    <kendo-grid-column
      field="Nom"
      title="Nom"
      [editable]="false"
      width="120"
      [style]="{ 'text-align': 'right' }"
    ></kendo-grid-column>
    <kendo-grid-column
      field="DateEntree"
      title="Date entrée"
      format="{0:dd-MM-yyyy}"
      [editable]="false"
      width="100"
    ></kendo-grid-column>
    <kendo-grid-column
      field="Solde"
      title="Solde"
      [editable]="false"
      width="80"
    ></kendo-grid-column>
    <kendo-grid-column
    field="DateSolde"
    title="Date solde"
    format="{0:MMM-yyyy}"
    [editable]="false"
    width="100"
  ></kendo-grid-column>
    

    <kendo-grid-column
      field="Adresse"
      title="Adresse"
      [editable]="false"
      width="150"
    ></kendo-grid-column>
    <kendo-grid-column
    field="NbCompromis"
    title="Nb Compromis"
    width="50"
  ></kendo-grid-column>
  <kendo-grid-column
  field="MontantCompromis"
  title="Montant Compromis"
  width="100"
></kendo-grid-column>
<kendo-grid-column
field="SoldePlusMontantCompromis"
title="Solde Montant Compromis"
width="100"
></kendo-grid-column>
    <kendo-grid-column
    field="MontantPack"
    title="Montant Pack"
    width="100"
  ></kendo-grid-column>
    <kendo-grid-column
    field="NbMandats3Mois"
    title="Nb Mandats 3Mois"
    width="100"
  ></kendo-grid-column>
  <kendo-grid-column
    field="NbMandats6Mois"
    title="Nb Mandats 6Mois"
    width="100"
  ></kendo-grid-column>
    <kendo-grid-column
      field="EmailPerso"
      title="Email perso"
      width="100"
    ></kendo-grid-column>
    <kendo-grid-column
      field="EmailPro"
      title="Email pro"
      width="160"
    ></kendo-grid-column>
    <kendo-grid-column
      field="TelephonePro"
      title="Telephone pro"
      width="130"
    ></kendo-grid-column>
    <kendo-grid-column
      field="Bareme"
      title="Barème"
      width="90"
    ></kendo-grid-column>
    <kendo-grid-column
      field="Actif"
      title="Actif"
      [editor]="'boolean'"
      width="70"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <input
          type="checkbox"
          id="Actif_{{ rowIndex }}"
          class="k-checkbox"
          [checked]="dataItem.Actif"
          disabled
        />
        <label class="k-checkbox-label" for="Actif_{{ rowIndex }}"></label>
      </ng-template>
    </kendo-grid-column>
    <ng-template kendoGridDetailTemplate let-dataItem>
       <app-evenements [currentSoldeConseillerId]="dataItem.Id"></app-evenements>
    </ng-template>
  </kendo-grid>
</div>