import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { ISoldeDto } from '../dtos/solde.dto';
import { Helper } from '../utils/helper';
import { Injectable } from "@angular/core";
import { BuInterceptorService } from "./buInterceptor.service";
import { ISoldeSearchModel } from '../models.interfaces/soldeSearch.model.interface';
import { MotifSuspensionEnum } from '../enums/motifSuspension.enum';
import { ISuspensionDto } from '../dtos/suspension.dto';

@Injectable()
export class SoldesService {

    constructor(private http: HttpClient) {
    }

    private getApiUrl(): string {
        return `${environment.apiBaseUrl}/Soldes/${this.bu()}`
    }

    private bu(): string {
        return BuInterceptorService.Bu;
    }
   
    public importSoldes(file: File): Observable<any> {
        const payload = new FormData();
        payload.append('file', file);
        return this.http
            .post<any>(`${this.getApiUrl()}/import`, payload);
    }

    public ajouterSolde(codeTiers: string, intitule: string, solde: number): Observable<any> {
        return this.http.post<any>(`${this.getApiUrl()}/ajouter/codeTiers/${codeTiers}/intitule/${intitule}/solde/${solde}`, null);
    }
  
    public getSoldes(searchModel: ISoldeSearchModel): Observable<ISoldeDto[]> {
        return this
            .http
            .post<any>(`${this.getApiUrl()}`,
            {
                Annee: searchModel.Annee,
                Mois: searchModel.Mois,
                CodeTiers: searchModel.CodeTiers,
                SoldeMax: searchModel.SoldeMax,
                Actif: searchModel.Actif
            })
            .map(res => Helper.mapDtoToModels(res.Result, this.mapSolde));
    }

    private mapSolde(dto: ISoldeDto): ISoldeDto {
        return <ISoldeDto>{
            Id : dto.Id,
            CodeTiers : dto.CodeTiers,
            TremplinId : dto.TremplinId,
            Nom : dto.Nom,
            Prenom : dto.Prenom,
            DateEntree : dto.DateEntree !=null ? new Date(dto.DateEntree + 'Z'): null,
            Solde : dto.Solde,
            DateSolde : new Date(dto.DateSolde + 'Z'),
            Adresse : dto.Adresse,
            NbCompromis : dto.NbCompromis,
            MontantCompromis : dto.MontantCompromis,
            SoldePlusMontantCompromis : dto.SoldePlusMontantCompromis,
            MontantPack : dto.MontantPack,
            NbMandats3Mois : dto.NbMandats3Mois,
            NbMandats6Mois : dto.NbMandats6Mois,
            EmailPerso: dto.EmailPerso,
            EmailPro : dto.EmailPro,
            TelephonePro : dto.TelephonePro,
            Bareme : dto.Bareme,
            Actif : dto.Actif
        };
    }


    public conseillersASuspendreImpaye(): Observable<string[]> {
        return this.http.get<string[]>(`${this.getApiUrl()}/conseillersASuspendreImpaye`);
    }

    public suspendreConseiller(codeTiers: string, dateDebut: Date, motif: MotifSuspensionEnum): Observable<any> {
        return this.http.post<any>(`${this.getApiUrl()}/suspendre/codeTiers/${codeTiers}/dateDebut/${Helper.urlFormatDate(dateDebut)}/motif/${motif}`, null);
    }

    public conseillersSuspendusPourImpaye(): Observable<string[]> {
        return this.http.get<string[]>(`${this.getApiUrl()}/conseillersSuspendusPourImpaye`);
    }
    
    public conseillersSuspendusImpayePlus60Jours(): Observable<ISuspensionDto[]> {
        return this.http.get<ISuspensionDto[]>(`${this.getApiUrl()}/conseillersSuspendusPourImpayePlus60Jours`)
                        .map(res => Helper.mapDtoToModels(res, this.mapSuspension));
    }

    private mapSuspension(dto: ISuspensionDto): ISuspensionDto {
        return <ISuspensionDto>{
            CodeTiers : dto.CodeTiers,
            SuspenduDepuis : new Date(dto.SuspenduDepuis + 'Z')
        };
    }
        
    public relanceSuspensionConseillerImpaye(codeTiers: string): Observable<any> {
        return this.http.post<any>(`${this.getApiUrl()}/relanceSuspensionImpaye/codeTiers/${codeTiers}`, null);
    }

    public genererExportImpaye(): Observable<any> {
        return this.http.post<any>(`${this.getApiUrl()}/generer/impaye`, null);
    }

    public genererExportImpayeInactif(): Observable<any> {
        return this.http.post<any>(`${this.getApiUrl()}/generer/impayeInactif`, null);
    }

    public genererExportRecouvrement(): Observable<any> {
        return this.http.post<any>(`${this.getApiUrl()}/generer/recouvrement`, null);
    }

}