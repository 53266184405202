import { FactureStatutEnum } from '../enums/factureStatut.enum';

export interface IFactureStatut {
    statut: FactureStatutEnum;
    statutString: string;
}

export interface IFacturestatuts {
    all: IFactureStatut;
    enPreparation: IFactureStatut;
    numerotee: IFactureStatut;
    envoyee: IFactureStatut;
    aPayer: IFactureStatut;
    payee: IFactureStatut;
    annulee: IFactureStatut;
    payeeAvoir: IFactureStatut;
}

export const FactureStatuts = <IFacturestatuts> {
    all: {
        statut: FactureStatutEnum.all,
        statutString: 'Toutes'
    },
    enPreparation: {
        statut: FactureStatutEnum.enPreparation,
        statutString: 'En préparation'
    },
    numerotee: {
        statut: FactureStatutEnum.numerotee,
        statutString: 'Numérotée'
    },
    envoyee: {
        statut: FactureStatutEnum.envoyee,
        statutString: 'Envoyée'
    },
    aPayer: {
        statut: FactureStatutEnum.aPayer,
        statutString: 'A payer'
    },
    payee: {
        statut: FactureStatutEnum.payee,
        statutString: 'Payée'
    },
    annulee: {
        statut: FactureStatutEnum.annulee,
        statutString: 'Annulée'
    },
    payeeAvoir: {
        statut: FactureStatutEnum.payeeAvoir,
        statutString: 'Payée / Avoir'
    }
};

export const FactureStatutsArray = [FactureStatuts.all, FactureStatuts.enPreparation, FactureStatuts.numerotee, FactureStatuts.envoyee, FactureStatuts.aPayer, FactureStatuts.payee, FactureStatuts.annulee, FactureStatuts.payeeAvoir];

