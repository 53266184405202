import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { Injectable } from "@angular/core";
import { BuInterceptorService } from "./buInterceptor.service";
import { IDictionary } from "../models/dictionary.model";
import { Helper } from "../utils/helper";

@Injectable()
export class ProvisionCommissionService {

    constructor(private http: HttpClient) {
    }

    private getApiUrl(): string {
        return `${environment.apiBaseUrl}/ProvisionCommission/${this.bu()}`
    }
    
    private bu(): string {
        return BuInterceptorService.Bu;
    }
    
    public genererProvision(debut: Date, fin: Date): Observable<IDictionary<string>> {
        return this
            .http
            .post<IDictionary<string>>(`${this.getApiUrl()}/${Helper.urlFormatDate(debut)}/${Helper.urlFormatDate(fin)}`, null);    
    }

}