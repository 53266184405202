import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FactureStatutsArray } from '../../../models.interfaces/factureStatut.model.interface';
import { EmptyFactureCommissionSearchModel } from 'src/app/models/emptyFactureCommissionSearch.model';
import { IFactureCommissionSearchModel } from 'src/app/models.interfaces/factureCommissionSearch.model.interface';

@Component({
  selector: 'app-factures-commission-search',
  templateUrl: './factures-commission-search.component.html',
  styleUrls: ['./factures-commission-search.component.scss']
})
export class FacturesCommissionSearchComponent implements OnInit {

  public statuts = FactureStatutsArray;
  public searchModel: IFactureCommissionSearchModel = new EmptyFactureCommissionSearchModel();
  @Output() public searchClicked: EventEmitter<IFactureCommissionSearchModel> = new EventEmitter<IFactureCommissionSearchModel>();

  constructor() {
  }

  ngOnInit() {
  }

  public onSearchClick() {
    this.searchClicked.emit(this.searchModel);
  }

  public onClearClick() {
    this.searchModel = new EmptyFactureCommissionSearchModel();
  }
}
