import { Injectable } from "@angular/core";
import { HttpRequest, HttpInterceptor, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse, HttpEvent } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { Observable, of, throwError, Subject } from "rxjs";
import { ServeurErreur } from "../models/serveurErreur.model";
import { ServeurErreurService } from "./serveur-erreur.service";

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor  {
    
    constructor(private serveurErreurService: ServeurErreurService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        return next.handle(req).pipe(catchError((err, caught) => {          
            if (err instanceof HttpErrorResponse) {
              if (err.status === 500) {
                if (err.error instanceof Blob) {
                  let reader = new FileReader();
                  reader.onload = (e: Event) => {
                    var errParsed = JSON.parse((<any>e.target).result);
                    this.serveurErreurService.erreurSurvenue({
                      requestUrl: req.url,
                      erreurId: errParsed.ErreurId,
                      exceptionMessage: errParsed.ExceptionMessage
                    });
                  };
                  reader.readAsText(err.error);
                } else {
                  this.serveurErreurService.erreurSurvenue({
                    requestUrl: req.url,
                    erreurId: err.error.ErreurId,
                    exceptionMessage: err.error.ExceptionMessage
                  });
                }
              }
            }
            return throwError(err);
          }) as any);
    }

}