<form class="k-form">
    <div class="row align-bottom">
        <label class="k-form-field col-md-2">
            <span>Conseiller:</span>
            <app-conseiller-selector
                [(conseiller)]='selectedConseiller'
                name="conseiller"
                [disabled]="planning.isDirty">
            </app-conseiller-selector>
        </label>
        <label class="k-form-field col-md-2">
            <span>Année:</span>
            <kendo-numerictextbox
                [(value)]="selectedAnnee"
                [format]="'#'"
                [min]="2017"
                [step]=1
                [disabled]="planning.isDirty">
            </kendo-numerictextbox>
        </label>
        <label class="k-form-field col-md-8" *ngIf="errorMessage">
            <span style="color:red;">Erreur:</span>
            <p style="color:red;">{{errorMessage}}</p>
        </label>
    </div>
    <div *ngIf="isPlanningVisible">
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-4 d-flex justify-content-center">{{selectedAnnee + 0}}</div>
            <div class="col-md-1"></div>
            <div class="col-md-4 d-flex justify-content-center">{{selectedAnnee + 1}}</div>
        </div>
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-4 d-flex justify-content-center">
                <div class="row">
                    <div *ngFor="let m of ['Jan','Fev','Mar','Avr','Mai','Jun','Jui','Aou','Sep','Oct','Nov','Dec']" class="col-md-1">
                        {{m}}
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-4 d-flex justify-content-center">
                <div class="row">
                    <div *ngFor="let m of ['Jan','Fev','Mar','Avr','Mai','Jun','Jui','Aou','Sep','Oct','Nov','Dec']" class="col-md-1">
                        {{m}}
                    </div>
                </div>
            </div>
        </div>
        <div *ngFor="let item of planning.items; let i = index">
            <div class="row align-items-center">
                <div class="col-md-2">
                    <div class="row">
                        <div class="col-md-3">
                            <span *ngIf="item.isDirty" class="fa fa-floppy-o" style="color: red;"></span>
                            <span *ngIf="!item.isDirty" class="fa fa-floppy-o" style="color: green;"></span>
                        </div>
                        <div *ngIf="item.article.nom; else noArticle" class="col-md-9">
                            {{item.article.nom}}
                        </div>
                        <ng-template #noArticle class="col-md-9">
                            <app-article-selector
                                [(article)]=item.article
                                name="article"
                                [isPlannifiablesOnly]="true"
                                [articlesToExclude]="planning.articles"
                            ></app-article-selector>
                        </ng-template>
                    </div>
                </div>
                <div class="col-md-4 d-flex justify-content-center">
                    <div class="row">
                        <div *ngFor="let m of [1,2,3,4,5,6,7,8,9,10,11,12]" class="col-md-1">
                            <input type="checkbox" id="{{ i + '_0' + m }}" class="k-checkbox"
                                (change)="onOccurenChange(i, selectedAnnee, m)"
                                [checked]="item.containsMonth(selectedAnnee + 0, m)" />
                            <label class="k-checkbox-label"  for="{{ i + '_0' + m }}"></label>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-4 d-flex justify-content-center">
                    <div class="row">
                        <div *ngFor="let m of [1,2,3,4,5,6,7,8,9,10,11,12]" class="col-md-1">
                            <input type="checkbox" id="{{ i + '_1' + m }}" class="k-checkbox" 
                            (change)="onOccurenChange(i, selectedAnnee + 1, m)"
                            [checked]="item.containsMonth(selectedAnnee + 1, m)" />
                            <label class="k-checkbox-label"  for="{{ i + '_1' + m }}"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-end">
            <button kendoButton (click)="addPlanningItemClick()" [iconClass]="'fa fa-plus'"></button>
            <button kendoButton (click)="onSaveClick()" [disabled]="!planning.canBeSaved" [primary]="true">Enregistrer</button>
            <button kendoButton (click)="onCancelClick()" [disabled]="!planning.isDirty" >Annuler</button>
        </div>
    </div>
</form>