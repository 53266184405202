import { IArticle } from '../models.interfaces/article.model.interface';

export class EmptyArticle implements IArticle {
    nom = '';
    reference = '';
    ht: number;
    isPlannifiable: boolean;
    compteDeProduitMetropole: string;
    compteDeProduitDomTom: string;
    compteDeProduitGuyaneMayote: string;   
    compteDeProduitSaintMartin: string;
    code:string;    
    id: string;
    nePasAppliquerTva: boolean;
}
