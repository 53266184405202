import { Component, OnInit, ViewChild } from "@angular/core";
import { ApiService } from "../../../services/api.service";
import { LoggerService } from "../../../services/logger.service";
import { IntlService } from "@progress/kendo-angular-intl";

import { FactureHonorairesService } from "src/app/services/factureHonoraires.service";
import { FactureCommissionService } from "src/app/services/factureCommission.service";
import { ProvisionCommissionService } from "src/app/services/provisionCommission.service";
import { FacturePackService } from "src/app/services/facturePack.service";
import { MouvementsBancairesService } from "src/app/services/mouvementsBancaires.service";
import { FactureHonorairesGenerationResult } from "src/app/models/factureHonorairesGenerationResult.model";
import { OperationsService } from "src/app/services/operations.service";
import { ReglesBancaireService } from "src/app/services/reglesBancaire.service";

import { IArticle } from "../../../models.interfaces/article.model.interface";
import { catchError, timeout } from "rxjs/operators";
import { throwError } from "rxjs";
import { ClotureComptesService } from "src/app/services/clotureComptes.service";
import { SoldesService } from "src/app/services/soldes.service";
import { MotifSuspensionEnum } from "src/app/enums/motifSuspension.enum";
import { ISuspensionDto } from 'src/app//dtos/suspension.dto';

@Component({
  selector: "app-bulks",
  templateUrl: "./bulks.component.html",
  styleUrls: ["./bulks.component.scss"],
  providers: [
    OperationsService,
    FactureHonorairesService,
    FacturePackService,
    FactureCommissionService,
    ProvisionCommissionService,
    MouvementsBancairesService,
    ReglesBancaireService,
    ClotureComptesService,
    SoldesService,
    ApiService,
    LoggerService,
  ],
})
export class BulksComponent implements OnInit {
  public isGenererHonorairesSpinning: boolean;
  public genererHonorairesInfoMessage: string;
  public genererHonorairesInfoColor: string;
  public genererHonorairesResults: Array<FactureHonorairesGenerationResult>;

  public isValiderFacturesCommissionSpinning: boolean;
  public validerFacturesCommissionInfoMessage: string;
  public validerFacturesCommissionInfoColor: string;

  public isPayerFacturesCommissionSpinning: boolean;
  public payerFacturesCommissionInfoMessage: string;
  public payerFacturesCommissionInfoColor: string;
  
  public isExporterFacturesCommissionSpinning: boolean;
  public exporterFacturesCommissionInfoMessage: string;
  public exporterFacturesCommissionInfoColor: string;

  public isNumeroterHonorairesSpinning: boolean;
  public numeroterHonorairesInfoMessage: string;
  public numeroterHonorairesInfoColor: string;

  public isEnvoyerHonorairesSpinning: boolean;
  public envoyerHonorairesInfoMessage: string;
  public envoyerHonorairesInfoColor: string;

  public fileName: string;

  public isGenererPackSpinning: boolean;
  public genererPackInfoColor: string;
  public genererPackInfoMessage: string;

  public isNumeroterPackSpinning: boolean;
  public numeroterPackInfoMessage: string;

  public isEnvoyerPackSpinning: boolean;
  public envoyerPackInfoMessage: string;

  public isExportPackSpinning: boolean;
  public exporterInfoMessage: string;
  public exporterSageInfoMessage: string;
  public exporterInfoColor: string;

  public isActualiserReglesSpinning: boolean;
  public actualiserReglesInfoMessage: string;

  public isGenererProvisionSpinning: boolean;
  public genererProvisionInfoMessage: string;
  public genererProvisionInfoColor: string;
  public dateDebutProvision: Date = new Date();
  public dateFinProvision: Date = new Date();

  public asOfDate: Date = new Date();
  private _isCancelled: boolean;
  private _codesToIgnore: string[] = [];

  public erreur: string;
  public genererArticleInfoMessage: string;
  public genererArticleInfoColor: string;
  public isSpinning: boolean;
  public fileList: FileList;
  public codeArticle: string;

  public dateCloture?: Date;
  public isSauvegarderDateClotureSpinning: boolean;

  public fileSoldesList: FileList;
  public importSoldesInfoMessage: string;
  public importSoldesInfoColor: string;
  public isImportSoldesSpinning: boolean;

  public suspendreImpayesInfoMessage: string;
  public suspendreImpayesInfoColor: string;
  public isSuspendreImpayesSpinning: boolean;

  public relanceImpayesInfoMessage: string;
  public relanceImpayesInfoColor: string;
  public isRelanceImpayesSpinning: boolean;

  public exportImpayesInfoMessage: string;
  public exportImpayesInfoColor: string;
  public isExportImpayesSpinning: boolean;

  public miseEnRecouvrementInfoMessage: string;
  public miseEnRecouvrementInfoColor: string;
  public isMiseEnRecouvrementSpinning: boolean;

  public exportRecouvrementInfoMessage: string;
  public exportRecouvrementInfoColor: string;
  public isExportRecouvrementSpinning: boolean;

  public buExportDAS2: string = "None";
  public fileDAS2List: FileList;
  public exportDAS2InfoMessage: string;
  public exportDAS2InfoColor: string;
  public isExportDAS2Spinning: boolean;


  @ViewChild("fileInput", { static: true }) fileInput;

  constructor(
    private _apiService: ApiService,
    private intl: IntlService,
    private _factureHonorairesService: FactureHonorairesService,
    private _mouvementsBancairesService: MouvementsBancairesService,
    private _operationsService: OperationsService,
    private _facturePackService: FacturePackService,
    private _factureCommissionService: FactureCommissionService,
    private _provisionCommissionService: ProvisionCommissionService,
    private _reglesBancaireService: ReglesBancaireService,
    private _clotureComptesService: ClotureComptesService,
    private _soldesService: SoldesService,
  ) {}

  ngOnInit() {

    this._clotureComptesService.get().subscribe((res) => {
      if (res) {
        if (res.dateCloture) {
          this.dateCloture = new Date(res.dateCloture);
        }
      }
    });

  }

  private _article: IArticle;
  public get article(): IArticle {
    return this._article;
  }
  public set article(value: IArticle) {
    this._article = value;
  }

  public validerFacturesCommission() {

    this.isValiderFacturesCommissionSpinning = true;
    this.validerFacturesCommissionInfoMessage = "";
    this.setValiderFacturesCommissionInfoMessage(
      "Récupération factures commission en attente de validation",
      "black"
    );

    this._factureCommissionService
      .getFacturesPourValidation()
      .subscribe(
        (factureIds) => {
          if (factureIds.length > 0) {
            this.validerFacture(factureIds, 0);
          } else {
            this.setValiderFacturesCommissionInfoMessage(
              "Aucun facture commission en attente de validation",
              "red"
            );
            this.isValiderFacturesCommissionSpinning = false;
          }
        },
        (error) => {
          this.setValiderFacturesCommissionInfoMessage(
            "Errur lors de la récupération des factures commission à valider",
            "red"
          );
          this.isValiderFacturesCommissionSpinning = false;
        }
      );

  }

  
  private validerFacture(factureIds: string[], index: number) {
    if (index >= factureIds.length) {
      this.setValiderFacturesCommissionInfoMessage(
        `Validation terminé.`, 
        "green");
      this.isValiderFacturesCommissionSpinning = false;
      return;
    }

    this.setValiderFacturesCommissionInfoMessage(
      `Validation facture (${index + 1}/${factureIds.length}).`,
      "black"
    );

    this._factureCommissionService
      .valider(factureIds[index])
      .subscribe((result) => {
        if (result.HasErreur) {
          this.setValiderFacturesCommissionInfoMessage(
            `Erreur lors de la validation facture ID ${factureIds[index]}.  ${result.ErreurMessage}`,
            "red"
          );
        } else {
          this.validerFacture(factureIds, index + 1);
        }
      }),
      (error) => {
        this.setValiderFacturesCommissionInfoMessage(
          `Erreur lors de la validation facture ID ${factureIds[index]}.`,
          "red"
        );
      };
  }

  private setValiderFacturesCommissionInfoMessage(msg: string, color: string) {
    this.validerFacturesCommissionInfoMessage = msg;
    this.validerFacturesCommissionInfoColor = color;
  }

  /////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////
  
  public payerFacturesCommission() {

    this.isPayerFacturesCommissionSpinning = true;
    this.payerFacturesCommissionInfoMessage = "";
    this.setPayerFacturesCommissionInfoMessage(
      "Récupération factures commission en attente de paiement",
      "black"
    );

    this._factureCommissionService
      .getFacturesPourPaiement()
      .subscribe(
        (factureIds) => {
          if (factureIds.length > 0) {
            this.payerFacture(factureIds, 0);
          } else {
            this.setPayerFacturesCommissionInfoMessage(
              "Aucun facture commission en attente de paiement",
              "red"
            );
            this.isPayerFacturesCommissionSpinning = false;
          }
        },
        (error) => {
          this.setPayerFacturesCommissionInfoMessage(
            "Errur lors de la récupération des factures commission à payer",
            "red"
          );
          this.isPayerFacturesCommissionSpinning = false;
        }
      );

  }

  
  private payerFacture(factureIds: string[], index: number) {
    if (index >= factureIds.length) {
      this.setPayerFacturesCommissionInfoMessage(
        `Paiement terminé.`, 
        "green");
      this.isPayerFacturesCommissionSpinning = false;
      return;
    }

    this.setPayerFacturesCommissionInfoMessage(
      `Paiement facture (${index + 1}/${factureIds.length}).`,
      "black"
    );

    this._factureCommissionService
      .payer(factureIds[index])
      .subscribe((result) => {
        if (result.HasErreur) {
          this.setPayerFacturesCommissionInfoMessage(
            `Erreur lors du paiement facture ID ${factureIds[index]}.  ${result.ErreurMessage}`,
            "red"
          );
        } else {
          this.payerFacture(factureIds, index + 1);
        }
      }),
      (error) => {
        this.setPayerFacturesCommissionInfoMessage(
          `Erreur lors du paiement facture ID ${factureIds[index]}.`,
          "red"
        );
      };
  }

  private setPayerFacturesCommissionInfoMessage(msg: string, color: string) {
    this.payerFacturesCommissionInfoMessage = msg;
    this.payerFacturesCommissionInfoColor = color;
  }

  public exporterFacturesCommissionClick() {
    this.isExporterFacturesCommissionSpinning = true;
    this.exporterFacturesCommissionInfoColor = "";
    this.setExporterFacturesCommissionInfoMessage(
      "Export factures commission en cours",
      "black"
    );

    this._factureCommissionService.exporter().subscribe(
      (res) => {
        this.isExporterFacturesCommissionSpinning = false;
        let result = JSON.parse(JSON.stringify(res));
        this.setExporterFacturesCommissionInfoMessage(
          "Export factures commission ok, , " +
            result.fileName +
            " exporté.",
          "green"
        );
      },
      (error) => {
        this.setExporterFacturesCommissionInfoMessage(
          "Erreur lors de l'export des factures commission",
          "red"
        );
        this.isExporterFacturesCommissionSpinning = false;
      }
    );
  }

  private setExporterFacturesCommissionInfoMessage(msg: string, color: string) {
    this.exporterFacturesCommissionInfoMessage = msg;
    this.exporterFacturesCommissionInfoColor = color;
  }

  public genererHonorairesClick(event) {
    event.preventDefault();
    this.isGenererHonorairesSpinning = true;
    this.genererHonorairesInfoMessage = null;
    this.genererHonorairesResults =
      new Array<FactureHonorairesGenerationResult>();

    this.setGenererHonorairesInfoMessage(
      "Récupération transactions à facturer",
      "black"
    );

    this._factureHonorairesService
      .getTransactionsForFacturationHonoraires()
      .subscribe(
        (transactionCodeNums) => {
          if (transactionCodeNums.length > 0) {
            this.genererHonoraires(transactionCodeNums, 0);
          } else {
            this.setGenererHonorairesInfoMessage(
              "Aucune transaction en attente facturation",
              "red"
            );
            this.isGenererHonorairesSpinning = false;
          }
        },
        (error) => {
          this.setGenererHonorairesInfoMessage(
            "Errur lors de la récupération des transactions à facturer",
            "red"
          );
          this.isGenererHonorairesSpinning = false;
        }
      );
  }

  private genererHonoraires(transactionCodeNums: number[], index: number) {
    if (index >= transactionCodeNums.length) {
      this.setGenererHonorairesInfoMessage("Génération terminée", "green");
      this.isGenererHonorairesSpinning = false;
      return;
    }

    this.setGenererHonorairesInfoMessage(
      `Génération factures (${index + 1}/${transactionCodeNums.length})`,
      "black"
    );

    this._factureHonorairesService
      .genererFacturesHonoraires(transactionCodeNums[index])
      .subscribe((res) => {
        if (res.erreur) this.genererHonorairesResults.push(res);
        this.genererHonoraires(transactionCodeNums, index + 1);
      }),
      (error) => {
        this.setGenererHonorairesInfoMessage(
          "Erreur lors de la génération des honoraires",
          "red"
        );
        //throw error;
      };
  }

  private setGenererHonorairesInfoMessage(msg: string, color: string) {
    this.genererHonorairesInfoMessage = msg;
    this.genererHonorairesInfoColor = color;
  }

  public numeroterHonorairesAllClick() {
    this.isNumeroterHonorairesSpinning = true;
    this.genererHonorairesInfoMessage = null;

    this.setNumeroterHonorairesInfoMessage(
      "Récupération liste factures en préparation",
      "black"
    );

    this._factureHonorairesService
      .getFacturesHonorairesEnPreparation()
      .subscribe(
        (factureIds) => {
          if (factureIds.length > 0) {
            this.numeroterHonoraires(factureIds, 0);
          } else {
            this.setNumeroterHonorairesInfoMessage(
              "Aucune facture en préparation",
              "red"
            );
            this.isNumeroterHonorairesSpinning = false;
          }
        },
        (error) => {
          this.setNumeroterHonorairesInfoMessage(error.message, "red");
          this.isNumeroterHonorairesSpinning = false;
        }
      );
  }

  private numeroterHonoraires(factureIds: string[], index: number) {
    if (index >= factureIds.length) {
      this.setNumeroterHonorairesInfoMessage("Numérotation terminée", "green");
      this.isNumeroterHonorairesSpinning = false;
      return;
    }

    this.setNumeroterHonorairesInfoMessage(
      `Numérotation factures (${index + 1}/${factureIds.length})`,
      "black"
    );

    this._factureHonorairesService
      .numeroterFactureHonoraires(factureIds[index])
      .subscribe((_) => this.numeroterHonoraires(factureIds, index + 1));
  }

  private setNumeroterHonorairesInfoMessage(msg: string, color: string) {
    this.numeroterHonorairesInfoMessage = msg;
    this.numeroterHonorairesInfoColor = color;
  }

  public envoyerHonorairesAllClick() {
    this.isEnvoyerHonorairesSpinning = true;
    this.genererHonorairesInfoMessage = null;

    this.setEnvoyerHonorairesInfoMessage(
      "Récupération liste factures numérotées",
      "black"
    );

    this._factureHonorairesService.getFacturesHonorairesNumerotees().subscribe(
      (factureIds) => {
        if (factureIds.length > 0) {
          this.envoyerHonoraires(factureIds, 0);
        } else {
          this.setEnvoyerHonorairesInfoMessage(
            "Aucune facture numérotée",
            "red"
          );
          this.isEnvoyerHonorairesSpinning = false;
        }
      },
      (error) => {
        this.setEnvoyerHonorairesInfoMessage(error.message, "red");
        this.isEnvoyerHonorairesSpinning = false;
      }
    );
  }

  private envoyerHonoraires(factureIds: string[], index: number) {
    if (index >= factureIds.length) {
      this.setEnvoyerHonorairesInfoMessage("Envoi terminée", "green");
      this.isEnvoyerHonorairesSpinning = false;
      return;
    }

    this.setEnvoyerHonorairesInfoMessage(
      `Envoi factures (${index + 1}/${factureIds.length})`,
      "black"
    );

    this._factureHonorairesService
      .envoyerFactureHonoraires(factureIds[index])
      .subscribe((_) => this.envoyerHonoraires(factureIds, index + 1));
  }

  private setEnvoyerHonorairesInfoMessage(msg: string, color: string) {
    this.envoyerHonorairesInfoMessage = msg;
    this.envoyerHonorairesInfoColor = color;
  }

  public genererClick(event) {
    event.preventDefault();
    this.isGenererPackSpinning = true;
    this.genererPackInfoMessage = null;
    this._facturePackService
      .genererFactures(this.asOfDate)
      .finally(() => (this.isGenererPackSpinning = false))
      .subscribe(
        (_) => this.setGenererInfoMessage("Génération terminée", "green"),
        (error) => {
          this.setGenererInfoMessage(error.message, "red");
        }
      );
  }

  public async numeroterAllClick() {
    let isError = false;
    for (let i = 0; i < 2100; i = i + 50) {
      this.isNumeroterPackSpinning = true;
      try {
        this.numeroterPackInfoMessage = `Numérotation de ${i} à ${i + 50}`;
        var res = await this.numeroterAsync(50);
      } catch (error) {
        isError = true;
        this.numeroterPackInfoMessage = error.message;
        break;
      } finally {
        this.isNumeroterPackSpinning = false;
      }
    }
    if (!isError) this.numeroterPackInfoMessage = "Numérotation terminé";
  }

  public async envoyerAllClick() {
    let isError = false;
    for (let i = 0; i < 2100; i = i + 50) {
      this.isEnvoyerPackSpinning = true;
      try {
        this.envoyerPackInfoMessage = `Envoi de ${i} à ${i + 50}`;
        var res = await this.envoyerAsync(50);
      } catch (error) {
        isError = true;
        this.envoyerPackInfoMessage = error.message;
        break;
      } finally {
        this.isEnvoyerPackSpinning = false;
      }
    }
    if (!isError) this.envoyerPackInfoMessage = "Envoi terminé";
  }

  public exportAllClick() {
    this.isExportPackSpinning = true;
    this._facturePackService
      .exportAll()
      .finally(() => {
        this.isExportPackSpinning = false;
      })
      .subscribe(
        (res) => {
          this.exporterInfoColor = "green";
          this.exporterSageInfoMessage = "Le fichier s'est correctement généré";
        },
        (err) => {
          console.log(err);
        }
      );
  }

  public actualiserReglesBancairesConseillers() {
    this.isActualiserReglesSpinning = true;
    this._reglesBancaireService
      .actualiserReglesBancairesConseillers()
      .finally(() => {
        this.isActualiserReglesSpinning = false;
      })
      .subscribe(
        (res) => {
          this.actualiserReglesInfoMessage = "Règles bancaires conseillers actualisés";
        },
        (err) => {
          console.log(err);
        }
      );
  }

  private numeroterAsync(take: number): Promise<any> {
    return this._facturePackService.numeroterAll(take).toPromise();
  }

  private envoyerAsync(take: number): Promise<any> {
    return this._facturePackService.sendAllToConseiller(take).toPromise();
  }

  private setGenererInfoMessage(msg: string, color: string) {
    this.genererPackInfoMessage = msg;
    this.genererPackInfoColor = color;
  }

  fileChange(event) {
    this.fileList = event.target.files;
  }
  
  genererOperations() {
    if (this.fileList != null && this.fileList.length == 0) {
      return;
    }
    const file: File = this.fileList[0];
    this.isSpinning = true;
    this._operationsService
      .genererOperations(file, this.article.id)
      .finally(() => {
        this.isSpinning = false;
      })
      .subscribe(
        (data) => {
          this.setGenererArticleInfoMessage("Import avec succèss", "green");
        },
        (err) => {
          this.isSpinning = false;
          this.setGenererArticleInfoMessage(err, "red");
          console.log(err);
        }
      );
  }
  private setGenererArticleInfoMessage(msg: string, color: string) {
    this.genererArticleInfoMessage = msg;
    this.genererArticleInfoColor = color;
  }
  public genererProvisionClick() {
    this.isGenererProvisionSpinning = true;
    this.genererProvisionInfoMessage = null;
    this.setGenererProvisionInfoMessage(
      "Génération des provisions en cours",
      "black"
    );

    this._provisionCommissionService
      .genererProvision(this.dateDebutProvision, this.dateFinProvision)
      .subscribe(
        (res) => {
          this.isGenererProvisionSpinning = false;
          this.setGenererProvisionInfoMessage(
            "Génération des provisions terminée",
            "green"
          );
        },
        (error) => {
          this.setGenererProvisionInfoMessage(
            "Erreur lors de la génération des provisions.",
            "red"
          );
          this.isGenererProvisionSpinning = false;
          //throw error;
        }
      );
  }

  private setGenererProvisionInfoMessage(msg: string, color: string) {
    this.genererProvisionInfoMessage = msg;
    this.genererProvisionInfoColor = color;
  }

  public sauvegarderDateCloture() {
    this.isSauvegarderDateClotureSpinning = true;
    this._clotureComptesService.post(this.dateCloture)
      .subscribe(() => { this.isSauvegarderDateClotureSpinning = false });
  }

  public fileSoldesChange(event) {
    this.fileSoldesList = event.target.files;
  }

  public importSoldes() {
    if (this.fileSoldesList == null || (this.fileSoldesList != null && this.fileSoldesList.length == 0)) {
      this.importSoldesInfoMessage = "Aucun fichier sélectionné";
      this.importSoldesInfoColor = "red";
      return;
    } else {
      this.importSoldesInfoMessage = "Import soldes";
      this.importSoldesInfoColor = "black";
    }

    var reader = new FileReader();
    reader.addEventListener("load", () => {
      var fileContents = reader.result as string;
      if (fileContents) {
        var lines = fileContents.split(/[\r\n]+/g); // tolerate both Windows and Unix linebreaks
        this.ajouterSolde(lines, 1);
      }    
    }, false);
    reader.readAsText(this.fileSoldesList[0], 'windows-1252');
  }

  private ajouterSolde(lines: string[], index: number) {
    if (index + 1 > lines.length) {
      this.importSoldesInfoMessage = "Soldes importés";
      this.importSoldesInfoColor = "green";
      this.isImportSoldesSpinning = false;
    } else {
      var split = lines[index].split(';');
      var codeTiers = split[0];
      var codesExclure = ["COMPTE", "309", "C00000004", "TOTAL", "EGESTION", "CAPI MARKET"];
      if (codeTiers.trim().length == 0 || codesExclure.includes(codeTiers.toUpperCase())) {
        this.ajouterSolde(lines, index + 1);
      } else {
        this.importSoldesInfoMessage = `Import solde ${codeTiers} (${index + 1}/${lines.length})`;
        var intitule = split[1];
        var solde = Number(split[2].replace(".","").replace(",","."));
        this._soldesService.ajouterSolde(codeTiers, intitule, solde).subscribe(() => {
          this.ajouterSolde(lines, index + 1);
        });
      }      
    }
  }

  public suspendreImpayes() {
    this.suspendreImpayesInfoMessage = "Recherche conseillers à suspendre";
    this.suspendreImpayesInfoColor = "black";
    this.isSuspendreImpayesSpinning = true;
    this._soldesService.conseillersASuspendreImpaye().subscribe((codesTiers) => {
      if (codesTiers.length == 0) {
        this.suspendreImpayesInfoMessage = "Aucun conseiller à suspendre.";
        this.suspendreImpayesInfoColor = "red";
        this.isSuspendreImpayesSpinning = false;
      } else {
        this.suspendreConseillerImpaye(codesTiers, 0);
      }
    },
    (err) => {
      this.suspendreImpayesInfoMessage = "Erreur recherche conseillers à suspendre";
      this.suspendreImpayesInfoColor = "red";
      this.isSuspendreImpayesSpinning = false;
    });
  }

  private suspendreConseillerImpaye(codesTiers: string[], index: number) {
    if (index + 1 > codesTiers.length) {
      this.suspendreImpayesInfoMessage = "Suspension terminée.";
      this.suspendreImpayesInfoColor = "green";
      this.isSuspendreImpayesSpinning = false;
    } else {
      this.suspendreImpayesInfoMessage = `"Suspension ${codesTiers[index]} (${index + 1}/${codesTiers.length})"`;
      this._soldesService.suspendreConseiller(codesTiers[index], new Date(), MotifSuspensionEnum.impaye).subscribe(() => {
        this.suspendreConseillerImpaye(codesTiers, index + 1);
      },
      (err) => {
        this.suspendreImpayesInfoMessage = `"Erreur suspension conseiller ${codesTiers[index]}"`;
        this.suspendreImpayesInfoColor = "red";
        this.isSuspendreImpayesSpinning = false;
      });
    }
  }
  
  public exportImpayes() {
    this.isExportImpayesSpinning = true;
    this._soldesService
      .genererExportImpaye()
      .finally(() => {
        this.isExportImpayesSpinning = false;
      })
      .subscribe(
        (res) => {
          if (res.Result) {
            this.exportImpayesInfoColor = "green";
            this.exportImpayesInfoMessage = `"Export ${res.Result} généré"`;
          } else {
            this.exportImpayesInfoColor = "red";
            this.exportImpayesInfoMessage = "Aucun conseiller suspendu impayé ce mois";  
          }
          
        },
        (err) => {
          this.exportImpayesInfoColor = "red";
          this.exportImpayesInfoMessage = "Erreur export impayés";
        }
      );
  }

  
  public exportImpayesInactif() {
    this.isExportImpayesSpinning = true;
    this._soldesService
      .genererExportImpayeInactif()
      .finally(() => {
        this.isExportImpayesSpinning = false;
      })
      .subscribe(
        (res) => {
          if (res.Result) {
            this.exportImpayesInfoColor = "green";
            this.exportImpayesInfoMessage = `"Export ${res.Result} généré"`;
          } else {
            this.exportImpayesInfoColor = "red";
            this.exportImpayesInfoMessage = "Aucun conseiller impayé inactif ce mois";  
          }
          
        },
        (err) => {
          this.exportImpayesInfoColor = "red";
          this.exportImpayesInfoMessage = "Erreur export impayés";
        }
      );
  }

  public relanceImpayes() {
    this.relanceImpayesInfoMessage = "Recherche conseillers suspendus...";
    this.relanceImpayesInfoColor = "black";
    this.isRelanceImpayesSpinning = true;
    this._soldesService.conseillersSuspendusPourImpaye().subscribe((codesTiers) => {
      if (codesTiers.length == 0) {
        this.relanceImpayesInfoMessage = "Aucun conseiller suspendu.";
        this.relanceImpayesInfoColor = "red";
        this.isRelanceImpayesSpinning = false;
      } else {
        this.relanceSuspensionConseiller(codesTiers, 0);
      }
    },
    (err) => {
      this.relanceImpayesInfoMessage = "Erreur recherche conseillers suspendus";
      this.relanceImpayesInfoColor = "red";
      this.isRelanceImpayesSpinning = false;
    });
  }

  private relanceSuspensionConseiller(codesTiers: string[], index: number) {
    if (index + 1 > codesTiers.length) {
      this.relanceImpayesInfoMessage = "Relance terminée.";
      this.relanceImpayesInfoColor = "green";
      this.isRelanceImpayesSpinning = false;
    } else {
      this.relanceImpayesInfoMessage = `"Relance ${codesTiers[index]} (${index + 1}/${codesTiers.length})"`;
      this._soldesService.relanceSuspensionConseillerImpaye(codesTiers[index]).subscribe(() => {
        this.relanceSuspensionConseiller(codesTiers, index + 1);
      },
      (err) => {
        this.relanceImpayesInfoMessage = `"Erreur relance conseiller ${codesTiers[index]}"`;
        this.relanceImpayesInfoColor = "red";
        this.isRelanceImpayesSpinning = false;
      });
    }
  }

  public miseEnRecouvrement() {
    this.miseEnRecouvrementInfoMessage = "Recherche conseillers suspendus impayé > 60j";
    this.miseEnRecouvrementInfoColor = "black";
    this.isMiseEnRecouvrementSpinning = true;
    this._soldesService.conseillersSuspendusImpayePlus60Jours().subscribe((conseillers) => {
      if (conseillers.length == 0) {
        this.miseEnRecouvrementInfoMessage = "Aucun conseiller suspendu impayé > 60j.";
        this.miseEnRecouvrementInfoColor = "red";
        this.isMiseEnRecouvrementSpinning = false;
      } else {
        this.suspendreConseillerRecouvrement(conseillers, 0);
      }
    },
    (err) => {
      this.miseEnRecouvrementInfoMessage = "Erreur recherche conseillers suspendus";
      this.miseEnRecouvrementInfoColor = "red";
      this.isMiseEnRecouvrementSpinning = false;
    });
  }

  private suspendreConseillerRecouvrement(conseillers: ISuspensionDto[], index: number) {
    if (index + 1 > conseillers.length) {
      this.miseEnRecouvrementInfoMessage = "Mise en recouvrement terminée.";
      this.miseEnRecouvrementInfoColor = "green";
      this.isMiseEnRecouvrementSpinning = false;
    } else {
      this.miseEnRecouvrementInfoMessage = `"Mise en recouvrement ${conseillers[index].CodeTiers} (${index + 1}/${conseillers.length})"`;
      this._soldesService.suspendreConseiller(conseillers[index].CodeTiers, conseillers[index].SuspenduDepuis, MotifSuspensionEnum.recouvrement).subscribe(() => {
        this.suspendreConseillerRecouvrement(conseillers, index + 1);
      },
      (err) => {
        this.miseEnRecouvrementInfoMessage = `"Erreur mise en recouvrement conseiller ${conseillers[index].CodeTiers}"`;
        this.miseEnRecouvrementInfoColor = "red";
        this.isMiseEnRecouvrementSpinning = false;
      });
    }
  }

  public exportRecouvrement() {
    this.isExportRecouvrementSpinning = true;
    this._soldesService
      .genererExportRecouvrement()
      .finally(() => {
        this.isExportRecouvrementSpinning = false;
      })
      .subscribe(
        (res) => {
          this.exportRecouvrementInfoColor = "green";
          this.exportRecouvrementInfoMessage = "Le fichier s'est correctement généré";
        },
        (err) => {
          this.exportRecouvrementInfoColor = "red";
          this.exportRecouvrementInfoMessage = "Erreur export recouvrement";
        }
      );
  }

  public fileDAS2Change(event) {
    this.fileDAS2List = event.target.files;
  }

  public exportDAS2() {
    if (this.buExportDAS2 == "None") {
      this.exportDAS2InfoMessage = "Aucun BU sélectionné";
      this.exportDAS2InfoColor = "red";
      return;
    }
    if (this.fileDAS2List == null || (this.fileDAS2List != null && this.fileDAS2List.length == 0)) {
      this.exportDAS2InfoMessage = "Aucun fichier sélectionné";
      this.exportDAS2InfoColor = "red";
      return;
    } else {
      this.exportDAS2InfoMessage = "Export DAS2 en cours...";
      this.exportDAS2InfoColor = "black";
    }
    const file: File = this.fileDAS2List[0];
    this.isExportDAS2Spinning = true;
    this._operationsService
      .exportdas2(this.buExportDAS2, file)
      .finally(() => {
        this.isExportDAS2Spinning = false;
      })
      .subscribe(
        (data) => {
          this.exportDAS2InfoMessage = "Export DAS2 terminé";
          this.exportDAS2InfoColor = "green";
        },
        (err) => {
          this.isExportDAS2Spinning = false;
          this.exportDAS2InfoMessage = err;
          this.exportDAS2InfoColor = "red";
        }
      );

  }

}

interface FileReaderEventTarget extends EventTarget {
  result: string;
}

interface FileReaderEvent extends ProgressEvent {
  target: FileReaderEventTarget;
  getMessage(): string;
}
