export const environment = {
  version: '',
  name: 'preproduction',
  production: false,
  apiBaseUrl: 'https://app-tremplin-facturation-stag-01.azurewebsites.net/api',
  fileManagerUrl: 'https://filemanager-preprod.digitregroup.io',
  tremplinUrl: { 
    Capi:'https://preprod.capi-iboost.fr', 
    Oh: 'https://preprod.e-theone.net' 
  },
  azureAdClientID: "dd546929-d000-42b3-8718-4d4045bafe7c",
  azureAdAuthority: "https://login.microsoftonline.com/7ab7bec6-e60d-43b1-88e0-6acf5e8c015e",  
  azureAdRedirect: "https://facturation-preprod.digitregroup.io"
};
