import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Observable } from 'rxjs/Observable';
import { EditService } from '../../../services/edit.service';
import { IRegleDto } from '../../../dtos/regle.dto';
import { BaseCrudComponent, IColumn } from '../base-crud/base-crud.component';

@Component({
  selector: 'app-regles',
  templateUrl: '../base-crud/base-crud.component.html',
  styleUrls: ['../base-crud/base-crud.component.scss', './regles.component.scss'],
  providers: [EditService]
})
export class ReglesComponent extends BaseCrudComponent<IRegleDto> {

    constructor(formBuilder: FormBuilder, editService: EditService<IRegleDto>,) {
        super(
            formBuilder,
            editService,
            (dataItem: IRegleDto) => formBuilder.group({
                'Id': dataItem.Id,
                'LibellePart': dataItem.LibellePart,
                'TypeCompte': dataItem.TypeCompte,
                'CompteGeneral': dataItem.CompteGeneral,
                'Tiers': dataItem.Tiers,
                'SectionAnalytique': dataItem.SectionAnalytique
            }),
            () => formBuilder.group(<IRegleDto> {
                Id: '',
                LibellePart: '',
                TypeCompte: '',
                CompteGeneral: '',
                Tiers: '',
                SectionAnalytique: ''
            }),
            [
                <IColumn> { field: "LibellePart", title: "Libellé", editor: "text", editable: true},
                <IColumn> { field: "TypeCompte", width:"120", title: "Type compte", editor: "text", editable: true},
                <IColumn> { field: "CompteGeneral", width:"120", title: "Compte general", editor: "text", editable: true},
                <IColumn> { field: "Tiers", title: "Tiers", editor: "text", editable: true},
                <IColumn> { field: "SectionAnalytique", title: "Section analytique", editor: "text", editable: true}
            ],
            true,
            "ReglesBancaire"
        );
    }
}
