import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as StackTrace from 'stacktrace-js';
import { ClientErreurService } from './client-erreur.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) { }
    handleError(error) {
        const clientErreurService = this.injector.get(ClientErreurService);
/*         if (error instanceof HttpErrorResponse) {
                clientErreurService.erreurSurvenue({
                    message: error.message,
                    stackTrace: null
                });
        }
        else */
        StackTrace.fromError(error).then(stackframes => {
            // const stackString = stackframes
            //   .splice(0, 20)
            //   .map(function(sf) {
            //     return sf.toString();
            //   }).join('<br/>'); 
            clientErreurService.erreurSurvenue({
                message: error.message,
                stackTrace: stackframes
            });
        });
        //throw error;
    }
} 