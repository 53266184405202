import { Component, OnInit, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/Observable';
import { RowClassArgs, PageChangeEvent, GridDataResult, GridComponent, DataStateChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { FacturePackService } from '../../../services/facturePack.service';
import { IFacturePackSearchModel } from '../../../models.interfaces/facturePackSearch.model.interface';
import { EmptyFacturePackSearchModel } from '../../../models/emptyFacturePackSearch.model';
import { FactureStatutEnum } from '../../../enums/factureStatut.enum';
import { IFacture } from '../../../models.interfaces/facture.model.interface';
import { environment } from '../../../../environments/environment';
import { IExportDto } from '../../../dtos/export.dto';
import { Helper } from '../../../utils/helper';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';

import { AlertService} from '../../../services/alert.service';
@Component({
  selector: 'app-factures-pack-grid',
  templateUrl: './factures-pack-grid.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./factures-pack-grid.component.scss'],
  providers: [FacturePackService]
})
export class FacturesPackGridComponent implements OnInit {

  public searchModel: IFacturePackSearchModel = new EmptyFacturePackSearchModel();
  public factures: Observable<GridDataResult>;
  private errors: Observable<string>;
  private totalHt: number;
  private totalTva: number;
  private totalTtc: number;
  private selectedFactures: IFacture[] = [];
  public selectedFactureIds: string[] = [];
  public pageSize = 100;
  public skip: number;
  public infoMessage: string;
  public infoMessageSuccess: string;
  private isSpinning: boolean;

  @ViewChild(GridComponent, { static: true }) private grid: GridComponent;

  public selectableSettings: SelectableSettings = {
    checkboxOnly: true,
    mode: 'multiple'
  };

  public sort: SortDescriptor[] = [{
    field: 'numero',
    dir: 'desc'
  }];

  constructor(private _facturePackService: FacturePackService, private alertService: AlertService) {
    this.factures = _facturePackService.results;
    this.errors = _facturePackService.errorMessages;
  }

  public refresh() {
    this.isSpinning = true;
    this.infoMessage = null;
    this._facturePackService.query(this.searchModel, { skip: this.skip, take: this.pageSize });
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refresh();
  }

  public ngOnInit(): void {
    this
      .factures
      .subscribe(
        f => {
          if (!f) return;
          this.setFacturesAndSum(f.data);
          f.data = orderBy(f.data, this.sort);
        },
        error => this.infoMessage = error.message
      );
    this
      .errors
      .subscribe(x => this.infoMessage = x);
    Observable
      .merge(this.factures, this.errors)
      .do(() => this.isSpinning = false)
      .subscribe();
    //this.refresh();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.grid
        .dataStateChange
        .do(({ skip, take }: DataStateChangeEvent) => {
          this.skip = skip;
          this.pageSize = take;
        })
        .subscribe(x => this.refresh());      
    });
  }

  public applySearchModel(searchModel: IFacturePackSearchModel) {
    this.searchModel = searchModel;
    this.refresh();
  }

  public rowCallback(context: RowClassArgs) {
    switch (context.dataItem.statut as FactureStatutEnum) {
      default:
        return {};
      case FactureStatutEnum.enPreparation:
        return { enPreparation: true };
      case FactureStatutEnum.numerotee:
        return { numerotee: true };
      case FactureStatutEnum.envoyee:
        return { envoyee: true };
      case FactureStatutEnum.aPayer:
        return { aPayer: true };
      case FactureStatutEnum.payee:
        return { payee: true };
      case FactureStatutEnum.annulee:
        return { annulee: true };
    }
  }

  public onSelectionChange(event: SelectionEvent) {
    if (event.selectedRows.length === 0) this.clearSelection();
    else event.selectedRows.forEach(r => this.selectedFactures.push(r.dataItem));
  }

  public numeroterClick() {
    this.isSpinning = true;
    this.infoMessage = null;
    this
      ._facturePackService
      .postFactureIdsToNumeroter(this.selectedFactures.map(f => f.id))
      .finally(() => this.isSpinning = false)
      .subscribe(
        res => {
          this.clearSelection();          
          this.refresh();
        },
        error => this.infoMessage = error.message);
  }

  public goBackwardClick() {
    this.isSpinning = true;
    this.infoMessage = null;
    this
      ._facturePackService
      .postFactureIdsToGoBackward(this.selectedFactures.map(f => f.id))
      .finally(() => this.isSpinning = false)
      .subscribe(
        res => {
          this.selectedFactures.forEach(f => {
            f.statut = FactureStatutEnum.enPreparation;
          });
          this.clearSelection();
        },
        error => this.infoMessage = error.message);
  }

  public regenererClick() {
    if (this.selectedFactures.length > 1) {
      this.infoMessage = "On ne peut regénérer qu'une facture à la fois";
      return;
    }
    this.isSpinning = true;
    this.infoMessage = null;
    this
      ._facturePackService
      .reGenererFactures(this.selectedFactures[0].id)
      .finally(() => this.isSpinning = false)
      .subscribe(
        res => this.clearSelection(),
        error => this.infoMessage = error.message);
  }

  public sendToConseillerClick() {
    this.isSpinning = true;
    this.infoMessage = null;
    this
      ._facturePackService
      .postFactureIdsToSendToConseiller(this.selectedFactures.map(f => f.id))
      .finally(() => this.isSpinning = false)
      .subscribe(
        res => {
          this.selectedFactures.forEach(f => {
            f.statut = FactureStatutEnum.envoyee;
          });
          this.clearSelection();
        },
        error => this.infoMessage = error.message);
  }

  public exportToComptaClick() {
    this.isSpinning = true;
    this.infoMessageSuccess = null;
    this
      ._facturePackService
      .postFactureIdsToExportToConseiller(this.selectedFactures.map(f => f.id))
      .finally(() => {
        this.isSpinning = false;
      })
      .subscribe(
        res => {
          this.infoMessageSuccess = 'Le fichier s\'est correctement généré';
        },
        err => {
          console.log(err);
        }
      );
  }

  public annulerClick() {
    this.isSpinning = true;
    this.infoMessage = null;
    this
      ._facturePackService
      .annuler(this.selectedFactures.map(f => f.id))
      .finally(() => this.isSpinning = false)
      .map((resu) => {
        let result = JSON.parse(JSON.stringify(resu))
        if (result && result.ErrorMessage)  {
          this.displayError(result.ErrorMessage);       
          this.isSpinning = false;
        }
      })
      .subscribe(
        res => {
          if(this.isSpinning){
            this.selectedFactures.forEach(f => {
              f.statut = FactureStatutEnum.annulee;
            });
            this.clearSelection();
          }
        },
        error => {
            console.log(error);
          this.infoMessage = error.message;
        });
  }

  public refreshClick() {
    this.refresh();
  }

private displayError(message: string) {
    this.alertService.error(message,
      { autoClose: false }
    );
}

  private setFacturesAndSum(factures: IFacture[]) {
    let totHt = 0;
    let totTva = 0;
    let totTtc = 0;
    factures.forEach(f => {
      totHt += f.totalHt;
      totTva += f.totalTva;
      totTtc += f.totalTtc;
    });
    this.totalHt = totHt;
    this.totalTva = totTva;
    this.totalTtc = totTtc;
  }

  private clearSelection() {
    this.selectedFactures = [];
    this.selectedFactureIds = [];
  }

}

