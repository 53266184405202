import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import {Subject} from 'rxjs/Subject';
import { IPackage } from '../../../models.interfaces/package.model.interface';
import { PackagesService } from 'src/app/services/packages.service';

@Component({
  selector: 'app-packages-selector',
  templateUrl: './packages-selector.component.html',
  styleUrls: ['./packages-selector.component.scss'],
  providers: [PackagesService]
})
export class PackagesSelectorComponent implements OnInit {

  public packages: IPackage[] = [];
  public searchPackageTerm = new Subject<string>();
  private _selectedPackages: IPackage[];
  @Input() public get selectedPackages(): IPackage[] {
    return this._selectedPackages;
  }
  public set selectedPackages(selectedPackages: IPackage[]) {
    if (selectedPackages.length === 0)
    {
      this.multiSelect.reset();
      this.packages = [];
    }
    this._selectedPackages = selectedPackages;
    this.selectedPackagesChange.emit(this._selectedPackages);
  }
  @Output() public selectedPackagesChange: EventEmitter<IPackage[]> = new EventEmitter<IPackage[]>();
  @ViewChild(MultiSelectComponent, {static: true}) multiSelect: MultiSelectComponent;


  constructor(private _packagesService: PackagesService) { }

  ngOnInit() {
    this
    .searchPackageTerm
    .distinctUntilChanged()
    .debounceTime(400)
    .subscribe(term => {
                        this.packages = [];
                        if (term === '') return;
                        this
                          ._packagesService
                          .getPackages(term)
                          .subscribe(packages => this.packages = packages);
                    });
  }

  public handleFilter(text: string) {
    this.searchPackageTerm.next(text);
  }

  public onValueChange(selectedPackages: IPackage[]) {
    this.selectedPackages = selectedPackages;
  }

}
