import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { Injectable } from "@angular/core";
import { BuInterceptorService } from "./buInterceptor.service";
import { IDictionary } from "../models/dictionary.model";

@Injectable()
export class OperationsService {

    constructor(private http: HttpClient) {
    }

    private getApiUrl(): string {
        return `${environment.apiBaseUrl}/Operations/${this.bu()}`
    }

    private bu(): string {
        return BuInterceptorService.Bu;
    }

    public genererOperations(file: File, articleId:string): Observable<any> {
        const payload = new FormData();
        payload.append('file', file);
        return this.http
            .post<any>(`${this.getApiUrl()}/generer/idarticle/${articleId}`, payload);
    }

    public exportdas2(bu: string, file: File) {
        const payload = new FormData();
        payload.append('file', file);
        return this.http
            .post<any>(`${environment.apiBaseUrl}/Operations/${bu}/exportdas2`, payload);
    }

}
