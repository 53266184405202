import {
  Component,
  ViewChild,
  Output,
  OnInit,
  EventEmitter,
} from "@angular/core";
import { SoldesService } from "src/app/services/soldes.service";
import { ISoldeDto } from "src/app/dtos/solde.dto";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { SortDescriptor, orderBy, State } from "@progress/kendo-data-query";
import {
  RowClassArgs,
  PageChangeEvent,
  GridDataResult,
  GridComponent,
  DataStateChangeEvent,
  SelectionEvent,
} from "@progress/kendo-angular-grid";
import { environment } from "src/environments/environment";
import { ISoldeSearchModel } from "src/app/models.interfaces/soldeSearch.model.interface";
import { EmptySoldeSearchModel } from "src/app/models/emptySoldeSearchModel.model";
import { BuInterceptorService } from '../../../services/buInterceptor.service';

@Component({
  selector: "app-soldes",
  templateUrl: "./soldes.component.html",
  providers: [SoldesService],
  styleUrls: ["./soldes.component.scss"],
})
export class SoldesComponent implements OnInit {
  public gridView: GridDataResult;
  public getSoldesInfoMessage: string;
  public getSoldesInfoColor: string;
  public isSpinningSearch: boolean;
  public soldes: ISoldeDto[] = [];
  public searchModel: ISoldeSearchModel = new EmptySoldeSearchModel();
  
  @ViewChild(GridComponent, { static: true }) private grid: GridComponent;
  public pageSize = 100;
  public skip: number;
  public Actif: string;

  constructor(
    private _soldesService: SoldesService,
    private formBuilder: FormBuilder
  ) {}

  public get Bu(): string {
    return BuInterceptorService.Bu;
  }

  public sortGrid: SortDescriptor[] = [
    {
      field: "codeTiers",
      dir: "asc",
    },
  ];

  public gridState: State = {
    sort: this.sortGrid,
    skip: 0,
    take: 12,
  };

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.grid.dataStateChange
        .do(({ skip, take }: DataStateChangeEvent) => {
          this.skip = skip;
          this.pageSize = take;
        })
        .subscribe((x) => this.refresh());
    });
  }
  public onStateChange(state: State) {
    this.gridState = state;
    this.refresh();
  }

  ngOnInit() {
    this.Actif = "Tous";
    this.searchModel.Annee = (new Date()).getFullYear();
    this.searchModel.Mois = (new Date()).getMonth() + 1;
  }

  getSoldes() {

    if (this.searchModel.CodeTiers == null || this.searchModel.CodeTiers.trim().length == 0) {
      if (this.searchModel.Annee == null || this.searchModel.Mois == null) {
        this.setSoldesInfoMessage("Année + mois / code tiers obligatoire", "red");
        return;
      }
    }

    if (this.Actif == "Actif") {
      this.searchModel.Actif = true;
    } else if (this.Actif == "Inactif") {
      this.searchModel.Actif = false;
    } else {
      this.searchModel.Actif = null;
    }
    this.isSpinningSearch = true;
    this.setSoldesInfoMessage(
      "Lecture en cours...",
      "green"
    );
    this._soldesService
      .getSoldes(this.searchModel)
      .finally(() => {
        this.isSpinningSearch = false;
      })
      .subscribe(
        (data) => {
          data.forEach(solde => {
            solde.Url = `${environment.tremplinUrl[this.Bu]}/ProfilIdentite/Edit/${solde.TremplinId}`;
          });
          this.soldes = data;
          this.setSoldesInfoMessage("", "green");
          this.refresh();
        },
        (err) => {
          this.isSpinningSearch = false;
          this.setSoldesInfoMessage("Erreur lecture soldes", "red");
        }
      );
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sortGrid = sort;
    this.refresh();
  }

  private refresh(): void {
    this.gridView = {
      data: orderBy(this.soldes, this.sortGrid).slice(
        this.gridState.skip,
        this.gridState.skip + this.gridState.take
      ),
      total: this.soldes.length,
    };
  }

  public changeActif(actif: boolean) {
    this.searchModel.Actif = !actif;
  }

  public onSearchClick() {
    this.getSoldes();
    this.refresh();
  }

  public onClearClick() {
    this.searchModel = new EmptySoldeSearchModel();
  }

  private setSoldesInfoMessage(msg: string, color: string) {
    this.getSoldesInfoMessage = msg;
    this.getSoldesInfoColor = color;
  }

}
