import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { environment } from "src/environments/environment";
import { IConseillerDto } from "../dtos/conseiller.dto";
import { IConseiller } from "../models.interfaces/conseiller.model.interface";
import { Helper } from "../utils/helper";
import { BuInterceptorService } from "./buInterceptor.service";

@Injectable()
export class ConseillersService {

    constructor(private http: HttpClient) {}

    private getApiUrl(): string {
        return `${environment.apiBaseUrl}/Conseillers/${this.bu()}`
    }

    private bu(): string {
        return BuInterceptorService.Bu;
    }

    public getConseillers(searchText: string): Observable<IConseiller[]> {
        return this
            .http
            .get<IConseillerDto[]>(`${this.getApiUrl()}/${searchText}`)
            .map(dtos => Helper.mapDtoToModels(dtos, this.mapConseiller));
    }
    
    private mapConseiller(dto: IConseillerDto): IConseiller {
        return <IConseiller>{
            codeClient: dto.CodeClient,
            codeFournisseur: dto.CodeFournisseur,
            fullName: `${dto.Prenom} ${dto.Nom} ${dto.CodeClient}`,
            zoneTvaId: dto.ZoneTva
        };
    }

}
