<form class="k-form row" #form="ngForm">
    <div class="col-md-2">
        <label class="k-form-field ">
            <span>Numéro de facture:</span>
            <kendo-numerictextbox
                [(ngModel)]="searchModel.numero" name="numero" [min]="0" [format]="'n0'">
            </kendo-numerictextbox>
        </label>
        <label class="k-form-field ">
            <span>Numéro de transaction:</span>
            <kendo-numerictextbox
                [(ngModel)]="searchModel.transactionCodeNum" name="transactionCodeNum" [min]="0" [format]="'n0'">
            </kendo-numerictextbox>
        </label>
    </div>
    <div class="col-md-2">
        <label class="k-form-field">
            <span>Montant Min:</span>
            <kendo-numerictextbox
                [(ngModel)]="searchModel.min" name="min" [min]="0">
            </kendo-numerictextbox>
        </label>
        <label class="k-form-field">
            <span>Montant Max:</span>
            <kendo-numerictextbox
                [(ngModel)]="searchModel.max"  name="min" [min]="0">
            </kendo-numerictextbox>
        </label>
    </div>
    <div class="col-md-2">
        <label class="k-form-field">
            <span>Du:</span>
            <kendo-datepicker
                [(ngModel)]="searchModel.du" name="du">
            </kendo-datepicker>
        </label>
        <label class="k-form-field">
            <span>Au:</span>
            <kendo-datepicker
                [(ngModel)]="searchModel.au"  name="au">
            </kendo-datepicker>
        </label>
    </div>
    <div class="col-md-2">
        <label class="k-form-field">
            <span>Statut:</span>
            <kendo-dropdownlist 
                textField="statutString"
                valueField="statut"
                [(ngModel)]="searchModel.statut"
                [data]="statuts"
                name="statut"></kendo-dropdownlist>
        </label>
        <label class="k-form-field">
            <span>Nom:</span>
            <kendo-textbox [(ngModel)]="searchModel.nom" name="nom"></kendo-textbox>
        </label>
    </div>
    <div class="col-md-4">
        <div class="divSearch">
            <div class="divButtonContent">
                <button kendoButton class="buttonSearch" (click)="onSearchClick()" [primary]="true">Rechercher</button>
                <button kendoButton (click)="onClearClick()">Effacer</button>
            </div>
        </div>
    </div>
</form>
