import { IFacturePackSearchModel } from '../models.interfaces/facturePackSearch.model.interface';
import { FactureStatuts } from '../models.interfaces/factureStatut.model.interface';
import { EmptyConseiller } from './emptyConseiller.model';

export class EmptyFacturePackSearchModel implements IFacturePackSearchModel {
    numero = '';
    statut = FactureStatuts.all;
    codeImputationClient = '';
    du = null;
    au = null;
    min = null;
    max = null;
    packages = [];
}
