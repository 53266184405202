import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { environment } from "src/environments/environment";
import { Helper } from "../utils/helper";
import { BuInterceptorService } from "./buInterceptor.service";

@Injectable()
export class ClotureComptesService {

    constructor(private http: HttpClient) {}

    private getApiUrl(): string {
        return `${environment.apiBaseUrl}/ClotureComptes/${this.bu()}`
    }

    private bu(): string {
        return BuInterceptorService.Bu;
    }

    public get(): Observable<any> {
      return this.http
          .get(`${this.getApiUrl()}`);
    }

    public post(dateCloture: Date): Observable<any> {
        return this.http
            .post(`${this.getApiUrl()}/${Helper.urlFormatDate(dateCloture)}`, null);
    }

}