import { IFactureHonorairesSearchModel } from '../models.interfaces/factureHonorairesSearch.model.interface';
import { FactureStatuts } from '../models.interfaces/factureStatut.model.interface';

export class EmptyFactureHonorairesSearchModel implements IFactureHonorairesSearchModel {
    numero = null;
    statut = FactureStatuts.all;
    du = null;
    au = null;
    min = null;
    max = null;
    transactionCodeNum = null;
    nom = null;
}
