import { BaseFactureDetailModel } from './baseFactureDetail.model';
import { EmptyConseiller } from './emptyConseiller.model';
import { EmptyArticle } from './emptyArticle.model';
import { EmptyPackage } from './package.model';
import { TypeDeDocEnum } from '../enums/typeDeDoc.enum';
import { TypeDeProduitEnum } from '../enums/typeDeProduit.enum';

export class EmptyFactureDetailModel extends BaseFactureDetailModel {
        

constructor() {
        super();
        this.conseiller = new EmptyConseiller();
        this.article = new EmptyArticle();
        this.pack = new EmptyPackage();
        this.typeDeDoc = TypeDeDocEnum.facture;
        this.typeDeProduit = TypeDeProduitEnum.article;
        this.du = new Date();
        this.au = new Date();     
        this.le = new Date();
        this.compte = '';
        this.designation = '';
        this.reference = '';
        this.puHt = null;
        this.quantite = null;
}

}
