import { Component, OnInit,  Input, Output, EventEmitter , ViewChild } from '@angular/core';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import 'rxjs/add/operator/debounceTime';
import {Subject} from 'rxjs/Subject';
import { IConseiller } from '../../../models.interfaces/conseiller.model.interface';
import { EmptyConseiller } from '../../../models/emptyConseiller.model';
import { ConseillersService } from 'src/app/services/conseillers.service';

@Component({
  selector: 'app-conseiller-selector',
  templateUrl: './conseiller-selector.component.html',
  styleUrls: ['./conseiller-selector.component.scss'],
  providers: [ConseillersService]
})
export class ConseillerSelectorComponent implements OnInit {

  public conseillers: IConseiller[] = [];
  public searchConseillerTerm = new Subject<string>();
  private _conseiller: IConseiller;
  @Input() public get conseiller(): IConseiller {
    return this._conseiller;
  }
  public set conseiller(conseiller: IConseiller) {
    if (conseiller instanceof EmptyConseiller)
    {
      this.autocomplete.reset();
      this.conseillers = [];
    }
    this._conseiller = conseiller;
    this.conseillerChange.emit(this._conseiller);
  }
  @Output() public conseillerChange: EventEmitter<IConseiller> = new EventEmitter<IConseiller>();
  @ViewChild(AutoCompleteComponent, {static: true}) autocomplete: AutoCompleteComponent;
  @Input() public disabled: boolean;

  constructor(private _conseillersService: ConseillersService) {
  }

  ngOnInit() {
    this
      .searchConseillerTerm
      .distinctUntilChanged()
      .debounceTime(400)
      .subscribe(term => {
                          this.conseillers = [];
                          this
                            ._conseillersService
                            .getConseillers(term)
                            .subscribe(conseillers => this.conseillers = conseillers);
                      });
  }

  public handleFilter(text: string) {
    this.conseiller = new EmptyConseiller();
    if (!text) return;
    this.searchConseillerTerm.next(text);
  }

  public onValueChange(value: string) {
    const selectedConseiller = this.conseillers.find(c => c.fullName === value);
    this.conseiller = selectedConseiller;
  }

}
