import { INewFactureDetailModel } from '../models.interfaces/newFactureDetail.model.interface';
import { TypeDeProduitEnum } from '../enums/typeDeProduit.enum';
import { TypeDeDocEnum } from '../enums/typeDeDoc.enum';
import { IArticle } from '../models.interfaces/article.model.interface';
import { IPackage } from '../models.interfaces/package.model.interface';
import { IConseiller } from '../models.interfaces/conseiller.model.interface';

export abstract class BaseFactureDetailModel implements INewFactureDetailModel {
        
    typeDeProduit: TypeDeProduitEnum;
    typeDeDoc: TypeDeDocEnum;
    du: Date;
    au: Date;
    le: Date;
    compte: string;
    designation: string;
    reference: string;
    totalHt: number;   

    private _puHt: number;
    public get puHt(): number {
            return this._puHt;
    }
    public set puHt(value: number) {
            this._puHt = value;
            this.totalHt = this.quantite * value;
            this.totalHt = BaseFactureDetailModel.computeTotalHt(this.quantite, value);
    }
    private _quantite: number;
    public get quantite(): number {
            return this._quantite;
    }
    public set quantite(value: number) {
            this._quantite = value;
            this.totalHt = BaseFactureDetailModel.computeTotalHt(value, this.puHt);
    }
    private _article: IArticle;
    public get article(): IArticle {
            return this._article;
    }
    public set article(value: IArticle) {
            this._article = value;
            this.setFields(this.article.nom, this.article.reference, this.article.ht, 1);
            this.setCompte();
    }
    private _pack: IPackage;
    public get pack(): IPackage {
            return this._pack;
    }
    public set pack(value: IPackage) {
            this._pack = value;
            this.setFields(this.pack.nom, this.pack.nom, this.pack.ht, this.pack.quantite);
            this.compte = '70800000';
    }
    private _conseiller: IConseiller;
    public get conseiller(): IConseiller {
            return this._conseiller;
    }
    public set conseiller(value: IConseiller) {
            this._conseiller = value;
            this.setCompte();
    }

    private setFields(nom: string, reference: string, ht: number, quantite: number) {
            this.designation = nom;
            this.reference = reference;
            this.puHt = ht;
            this.quantite = quantite;
    }

    private setCompte() {
                if (this.article != null) {
                        if (this.conseiller != null && this.conseiller.codeClient != null) {   
                                if (this.conseiller.zoneTvaId == 1) {
                                        this.compte = this.article.compteDeProduitMetropole;
                                } else if (this.conseiller.zoneTvaId == 2) {
                                        this.compte = this.article.compteDeProduitDomTom;
                                } else if (this.conseiller.zoneTvaId == 3) {
                                        this.compte = this.article.compteDeProduitGuyaneMayote;
                                } else if (this.conseiller.zoneTvaId == 4) {
                                        this.compte = this.article.compteDeProduitSaintMartin;
                                } else this.compte = null;
                        }
                }
        }

    private static computeTotalHt(quantite: number, puHt: number): number {
            if (quantite != null && puHt != null)
                    return quantite * puHt;
            else
                    return 0;
    }
    
    private _NePasAppliquerTva: boolean;
    public get NePasAppliquerTva(): boolean {
            return this._NePasAppliquerTva;
    }
    public set NePasAppliquerTva(value: boolean) {
            this._NePasAppliquerTva = value;
    }
}
