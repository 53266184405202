import { IPlanningItem } from '../models.interfaces/planningItem.model.interface';
import { IYearAndMonth } from '../models.interfaces/yearAndMonth.model.interface';
import { IArticle } from '../models.interfaces/article.model.interface';
import { Subject } from 'rxjs';

export abstract class BasePlanningItem implements IPlanningItem {
    public isDirty: boolean;
    private _occurences: IYearAndMonth[];
    private _article: IArticle;
    public get article(): IArticle {
        return this._article;
    }
    public set article(value: IArticle) {
        this._article = value;
        this.articleChange.next(value);
    }
    public articleChange: Subject<IArticle> = new Subject<IArticle>();
    public get occurences(): IYearAndMonth[] { return this._occurences; }
    constructor(article: IArticle, isDirty: boolean) {
        this.article = article;
        this.isDirty = isDirty;
        this._occurences = [];
    }
    public containsMonth(annee: number, mois: number): boolean {
        return this.occurences.find(o => o.annee === annee && o.mois === mois) != null;
    }
    public updateOccurence(annee: number, mois: number) {
        const occ = this.occurences.find(x => x.annee === annee && x.mois === mois);
        if (occ)
            this.occurences.splice(this.occurences.indexOf(occ), 1);
        else
            this.occurences.push(<IYearAndMonth>{ annee: annee, mois: mois });
        this.isDirty = true;
    }
    public save() {
        this.isDirty = false;
    }
}
