import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, ErrorHandler } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Kendo
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/fr/all';
import { TabContentLoadOnDemandDirective } from './directives/tabContentLoadOnDemand.directive';
import { WindowModule } from '@progress/kendo-angular-dialog';

// Microsoft Authentication Library 
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';


// Custom
import { FacturesPackGridComponent } from './components/factures/factures-pack-grid/factures-pack-grid.component';
import { FacturesPackSearchComponent } from './components/factures/factures-pack-search/factures-pack-search.component';
import { FacturesHonorairesGridComponent } from './components/factures/factures-honoraires-grid/factures-honoraires-grid.component';
import { FacturesHonorairesSearchComponent } from './components/factures/factures-honoraires-search/factures-honoraires-search.component';
import { FacturesCommissionGridComponent } from './components/factures/factures-commission-grid/factures-commission-grid.component';
import { FacturesCommissionSearchComponent } from './components/factures/factures-commission-search/factures-commission-search.component';
import { ConseillerSelectorComponent } from './components/factures/conseiller-selector/conseiller-selector.component';
import { FacturePackDetailsGridComponent } from './components/factures/facture-pack-details-grid/facture-pack-details-grid.component';
import { FactureCommissionDetailsGridComponent } from './components/factures/facture-commission-details-grid/facture-commission-details-grid.component';
import { NewFactureDetailFormComponent } from './components/factures/new-facture-detail-form/new-facture-detail-form.component';
import { PlannedFactureDetailFormComponent } from './components/factures/planned-facture-detail-form/planned-facture-detail-form.component';
import { PackagesSelectorComponent } from './components/factures/packages-selector/packages-selector.component';
import { ArticleSelectorComponent } from './components/factures/article-selector/article-selector.component';
import { PackageSelectorComponent } from './components/factures/package-selector/package-selector.component';
import { ArticlesComponent } from './components/factures/articles/articles.component';
import { ReglesComponent } from './components/factures/regles/regles.component';
// import { EvenementsComponent } from './components/factures/evenements/evenements.component';
import { BuInterceptorService } from './services/buInterceptor.service';
import { BulksComponent } from './components/factures/bulks/bulks.component';
import { MainComponent } from './components/factures/main/main.component';
import { BanqueComponent } from './components/factures/banque/banque.component';
import { SoldesComponent } from './components/factures/soldes/soldes.component';
import { environment } from '../environments/environment';
import { ErrorInterceptorService } from './services/errorInterceptor.service';
import { ServeurErreurService } from './services/serveur-erreur.service';
import { ServeurErreurComponent } from "./components/factures/serveur-erreur/serveur-erreur.component"
import { ClientErreurService } from './services/client-erreur.service';
import { ClientErreurComponent } from './components/factures/client-erreur/client-erreur.component';
import { GlobalErrorHandler } from './services/globalErrorHandler';
import { AlertComponent } from './components/factures/alert/alert.component';
import { EvenementsService } from './services/evenement.service';
import { EvenementDetailComponent } from "./components/factures/EvenementsDetails/evenements.component";
import { PayerTransactionComponent } from './components/factures/payer-transaction/payer-transaction.component';


import { DropDownListModule } from "@progress/kendo-angular-dropdowns";

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureAdClientID,
      redirectUri: environment.azureAdRedirect,
      postLogoutRedirectUri: environment.azureAdRedirect,
      authority: environment.azureAdAuthority
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(environment.apiBaseUrl, ["api://" + environment.azureAdClientID + "/ssofacturationprod"]);
  //protectedResourceMap.set("api://" + environment.azureAdClientID + "/ssofacturationprod", ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

// TODO 
//     consentScopes: [ "user.read", "api://" + environment.azureAdClientID + "/ssofacturationprod"],



export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    loginFailedRoute: '/login-failed'
  };
}


@NgModule({
  declarations: [
    AppComponent,
    TabContentLoadOnDemandDirective,
    FacturesPackGridComponent,
    FacturesPackSearchComponent,
    FacturesHonorairesGridComponent,
    FacturesHonorairesSearchComponent,
    FacturesCommissionGridComponent,
    FacturesCommissionSearchComponent,
    FactureCommissionDetailsGridComponent,
    ConseillerSelectorComponent,
    FacturePackDetailsGridComponent,
    NewFactureDetailFormComponent,
    PlannedFactureDetailFormComponent,
    PackagesSelectorComponent,
    ArticleSelectorComponent,
    PackageSelectorComponent,
    ArticlesComponent,
    ReglesComponent,
    // EvenementsComponent,
    BulksComponent,
    MainComponent,
    BanqueComponent,
    SoldesComponent,
    ServeurErreurComponent,
    ClientErreurComponent,
    AlertComponent,
    EvenementDetailComponent,
    PayerTransactionComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MsalModule,
    RouterModule.forRoot([
      { path: 'capi', component: MainComponent, data: { bu: 'Capi' }, canActivate: [MsalGuard] },
      { path: 'oh', component: MainComponent, data: { bu: 'Oh' }, canActivate: [MsalGuard] },
      { path: 'digit', component: MainComponent, data: { bu: 'Digit' }, canActivate: [MsalGuard] },
      { path: 'digitacademie', component: MainComponent, data: { bu: 'DigitAcademie' }, canActivate: [MsalGuard] },
      { path: 'digitre', component: MainComponent, data: { bu: 'Digit' }, canActivate: [MsalGuard] },
      { path: 'i9', component: MainComponent, data: { bu: 'ImmobilierNeuf' }, canActivate: [MsalGuard] },
      { path: 'reflex', component: MainComponent, data: { bu: 'Reflex' }, canActivate: [MsalGuard] },
      { path: '**', redirectTo: 'capi', pathMatch: 'full' }
    ],
      { enableTracing: false }),
    IntlModule,
    ButtonsModule,
    WindowModule,
    GridModule,
    DropDownsModule,
    LayoutModule,
    InputsModule,
    DateInputsModule,
    NgbModule,
    ReactiveFormsModule,
    DropDownListModule
  ],
  providers: [
    ServeurErreurService,
    ClientErreurService,
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },

    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    EvenementsService,
    { provide: HTTP_INTERCEPTORS, useClass: BuInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: "fr-FR" },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

