import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Rx";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Subject } from "rxjs/Subject";
import { IEnvelope } from "../dtos/envelope.dto";
import { BuInterceptorService } from "./buInterceptor.service";

const CREATE_ACTION = "create";
const UPDATE_ACTION = "update";
const REMOVE_ACTION = "delete";

const itemIndex = (item: any, data: any[]): number => {
  for (let idx = 0; idx < data.length; idx++) {
    if (data[idx].Id === item.Id) {
      return idx;
    }
  }
  return -1;
};

const cloneData = (data: any[]) => data.map((item) => Object.assign({}, item));

@Injectable()
export class EditService<T extends HasId> extends BehaviorSubject<T[]> {
  private apiPath: string;
  public id: string = "";
  public errors: Subject<string> = new Subject<string>();

  constructor(private http: HttpClient) {
    super([]);
  }

  public get Bu(): string {
    return BuInterceptorService.Bu;
  }
  private data: T[] = [];
  private originalData: T[] = [];
  private createdItems: T[] = [];
  private updatedItems: T[] = [];
  private deletedItems: T[] = [];

  public setApiPath(apiPath: string) {
    this.apiPath = apiPath;
  }

  public read() {
    if (this.data.length) {
      return super.next(this.data);
    }

    this.get()
      .do((data) => (this.data = data))
      .do((data) => (this.originalData = cloneData(data)))
      .subscribe(
        (data) => super.next(data),
        (error: Error) => this.errors.next(error.message)
      );
  }

  public create(item: T): void {
    this.createdItems.push(item);
    this.data.unshift(item);

    super.next(this.data);
  }

  public update(item: T): void {
    if (!this.isNew(item)) {
      const index = itemIndex(item, this.updatedItems);
      if (index !== -1) {
        this.updatedItems.splice(index, 1, item);
      } else {
        this.updatedItems.push(item);
      }
    } else {
      const index = itemIndex(item, this.createdItems);
      this.createdItems.splice(index, 1, item);
    }
  }

  public remove(item: T): void {
    let index = itemIndex(item, this.data);
    this.data.splice(index, 1);

    index = itemIndex(item, this.createdItems);
    if (index >= 0) {
      this.createdItems.splice(index, 1);
    } else {
      this.deletedItems.push(item);
    }

    index = itemIndex(item, this.updatedItems);
    if (index >= 0) {
      this.updatedItems.splice(index, 1);
    }

    super.next(this.data);
  }

  public isNew(item: T): boolean {
    return !item.Id;
  }

  public hasChanges(): boolean {
    return Boolean(
      this.deletedItems.length ||
        this.updatedItems.length ||
        this.createdItems.length
    );
  }

  public saveChanges(): void {
    if (!this.hasChanges()) {
      return;
    }

    const completed = [];
    if (this.deletedItems.length) {
      completed.push(this.post(REMOVE_ACTION, this.deletedItems));
    }

    if (this.updatedItems.length) {
      completed.push(this.post(UPDATE_ACTION, this.updatedItems));
    }

    if (this.createdItems.length) {
      completed.push(this.post(CREATE_ACTION, this.createdItems));
    }

    this.reset();

    Observable.forkJoin(...completed).subscribe(() => {
      this.read();
    });
  }

  public cancelChanges(): void {
    this.reset();

    this.data = this.originalData;
    this.originalData = cloneData(this.originalData);
    super.next(this.data);
  }

  public assignValues(target: T, source: T): void {
    Object.assign(target, source);
  }

  private reset() {
    this.data = [];
    this.deletedItems = [];
    this.updatedItems = [];
    this.createdItems = [];
  }

  private get(): Observable<T[]> {
    return this.http
      .get<IEnvelope<T[]>>(
        `${environment.apiBaseUrl}/${this.apiPath}/${this.Bu}/${this.id}`
      )
      .map((x) => {
        if (x && x.ErrorMessage) throw new Error(x.ErrorMessage);
        return x.Result;
      });
  }

  private post(action: string, data: T[]): Observable<any> {
    switch (action) {
      case "update": {
        return this.http
          .put<IEnvelope<string>>(
            `${environment.apiBaseUrl}/${this.apiPath}/${this.Bu}/${action}`,
            data
          )
          .map((x) => {
            if (x && x.ErrorMessage) throw new Error(x.ErrorMessage);
            return x;
          });
        break;
      }
      case "delete": {
        const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
            body: data,
          };
        return this.http
          .delete<IEnvelope<string>>(
            `${environment.apiBaseUrl}/${this.apiPath}/${this.Bu}/${action}`, options)
          .map((x) => {
            if (x && x.ErrorMessage) throw new Error(x.ErrorMessage);
            return x;
          });
        break;
      }
      default: {
        return this.http
          .post<IEnvelope<string>>(
            `${environment.apiBaseUrl}/${this.apiPath}/${this.Bu}/${action}`,
            data
          )
          .map((x) => {
            if (x && x.ErrorMessage) throw new Error(x.ErrorMessage);
            return x;
          });
        break;
      }
    }
  }
}

export class HasId {
  Id: any;
}
