<style>
  .top-buffer { margin-top:10px; }
</style>

<h3>Payer transaction facturée hors-système</h3>

<div class="row top-buffer">
  <div class="col-md-2">
    <label class="k-form-field ">
      <span>Numéro de transaction:</span>
    </label>
  </div>
  <div class="col-md-2">
    <kendo-numerictextbox [(ngModel)]="transactionCode" name="TransactionCode" [min]="0" [format]="'n0'">
    </kendo-numerictextbox>
  </div>
  <div class="col-md-2">
    <div class="divSearch">
      <div class="divButtonContent">
        <button kendoButton class="buttonSearch" (click)="onSearchClick()" [primary]="true" [disabled]="isSearchSpinning">Rechercher</button>
        <span *ngIf='isSearchSpinning' class="k-i-loading k-icon" style="font-size: 32px;"></span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showTransaction">
  <div class="row top-buffer">
    <div class="col-md-2">
      Statut:
    </div>
    <div class="col-md-2">
      {{transaction.statut}}
    </div>
  </div>
  <div class="row top-buffer">
    <div class="col-md-2">
      Type Affaire:
    </div>
    <div class="col-md-2">
      {{transaction.typeAffaire}}
    </div>
  </div>
  <div class="row top-buffer">
    <div class="col-md-2">
      Date RDV:
    </div>
    <div class="col-md-2">
      {{transaction.dateRdvSignatureActe | date:'mediumDate'}}
    </div>
  </div>
  <div class="row top-buffer">
    <div class="col-md-2">
      Date Signature:
    </div>
    <div class="col-md-2">
      {{transaction.dateEffectiveSignature | date:'mediumDate'}}
    </div>
  </div>
  <div class="row top-buffer">
    <div class="col-md-2">
      Honoraires Reçu:
    </div>
    <div class="col-md-2">
      {{transaction.honorairesRecuLe | date:'mediumDate'}}
    </div>
  </div>
  <div class="row top-buffer">
    <div class="col-md-2">
      Honoraires Agence HT:
    </div>
    <div class="col-md-2">
      {{transaction.honoraireAgenceHt | currency:'EUR'}}
    </div>
  </div>
  <div class="row top-buffer">
    <div class="col-md-2">
      Honoraires Agence TTC:
    </div>
    <div class="col-md-2">
      {{transaction.honoraireAgenceTtc | currency:'EUR'}}
    </div>
  </div>
  <div class="row top-buffer">
    <div class="col-md-2">
      Client 1:
    </div>
    <div class="col-md-2">
      {{transaction.client1Nom}}
    </div>
  </div>
  <div class="row top-buffer">
    <div class="col-md-2">
      Client 2:
    </div>
    <div class="col-md-2">
      {{transaction.client2Nom}}
    </div>
  </div>
  <div class="row top-buffer">
    <div class="col-md-2">
      Date paiement:
    </div>
    <div class="col-md-2">
      <kendo-datepicker [(ngModel)]="datePaiement" name="datePaiement"></kendo-datepicker>
    </div>
  </div>
  <div class="row top-buffer">
    <div class="col-md-2">
      Mode de règlement:
    </div>
    <div class="col-md-2">
      <input [value]="false" [(ngModel)]="modeReglementCheque" type="radio" name="modeReglementGroup"
        id="checkVirement" />
      <label for="checkVirement" style="margin:5px">Virement</label>
      <input [value]="true" [(ngModel)]="modeReglementCheque" type="radio" name="modeReglementGroup" id="checkCheque" />
      <label for="checkCheque" style="margin:5px">Chèque</label>
    </div>
    <div class="col-md-4">
      <button kendoButton (click)="onPayerClick()" primary="true" [disabled]="isPayerSpinning">Fonds reçus</button>
      <span *ngIf='isPayerSpinning' class="k-i-loading k-icon" style="font-size: 32px;"></span>
      <span style="margin-left:10px" >{{payerMessage}}</span>
    </div>
  </div>
</div>