import { Component, Inject, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { typesEvenement } from './typesEvenement';
import { TypeEvenement } from './model.typeevenement';
import { IEvenement } from "../../../models.interfaces/evenement.model.interface";
import { AddEvent, GridDataResult, CancelEvent, EditEvent, RemoveEvent, SaveEvent, GridComponent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';

import { map } from 'rxjs/operators';

import { EvenementsService } from '../../../services/evenement.service';

@Component({
    selector: 'app-evenements',
    template: `
        <kendo-grid
        [data]="gridData"
            (edit)="editHandler($event)"
            (cancel)="cancelHandler($event)"
            (save)="saveHandler($event)"
            (remove)="removeHandler($event)"
            (add)="addHandler($event)"
            [height]="410"
        >
            <ng-template kendoGridToolbarTemplate>
                <button kendoGridAddCommand>Ajouter</button>
            </ng-template>
            <kendo-grid-column field="Texte" title="Texte" [width]="120" > </kendo-grid-column>
            <kendo-grid-column field="CreateurEvenement" title="Createur Evenement" [width]="120"> </kendo-grid-column>
            <kendo-grid-column
            field="DateEvenement"
            editor="date"
            title="Date Evenement"
            width="150"
            [format]="'{dd-MM-yyyy}'"
          >
            <ng-template
              kendoGridEditTemplate
              let-dataItem="dataItem"
              let-formGroup="formGroup"
            >
              <kendo-datepicker
                [formControl]="formGroup.controls.DateEvenement"
                [format]="'dd-MM-yyyy'"
              ></kendo-datepicker>
            </ng-template>
          </kendo-grid-column>
            <kendo-grid-column field="TypeId" title="Type" [width]="150">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                    <kendo-dropdownlist
                        [data]="evenements"
                        textField="TypeName"
                        valueField="TypeId"
                        [valuePrimitive]="true"
                        [formControl]="formGroup.get('TypeId')"
                    >
                    </kendo-dropdownlist>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ evenement(dataItem.TypeId)?.TypeName }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-command-column title="" [width]="220">
                <ng-template kendoGridCellTemplate let-isNew="isNew">
                    <button kendoGridEditCommand [primary]="true">Modifier</button>
                    <button kendoGridRemoveCommand>Supprimer</button>
                    <button kendoGridSaveCommand [disabled]="formGroup?.invalid">
                        {{ isNew ? 'Ajouter' : 'Enregistrer' }}
                    </button>
                    <button kendoGridCancelCommand>
                         Annuler
                    </button>
                </ng-template>
            </kendo-grid-command-column>
        </kendo-grid>
    `
})
export class EvenementDetailComponent implements OnInit {
    public gridData: IEvenement[];
    public view: Observable<GridDataResult>;
    public gridState: State = {
        sort: [],
        skip: 0,
        take: 10
    };
    public evenements: TypeEvenement[] = typesEvenement;
    public formGroup: FormGroup;
    private editedRowIndex: number;

    @Input() public currentSoldeConseillerId:string='';
 
    constructor(private service: EvenementsService) {}
    public ngOnInit(): void {
        this.service.currentSoldeConseillerId = this.currentSoldeConseillerId;
        this.getEvenements();
    }

    private getEvenements() {
        this.service.getEvenements(this.currentSoldeConseillerId)
            .subscribe(
                data => {
                    this.gridData = data;
                },
                (err) => {
                    console.log(err);
                }
            );
    }

    public evenement(id: number): TypeEvenement {
        return this.evenements.find((x) => x.TypeId === id);
    }

    public addHandler({ sender }: AddEvent): void {
        this.closeEditor(sender);

        this.formGroup = createFormGroup({
            SoldeConseillerId: this.currentSoldeConseillerId,
            Texte: '',
            CreateurEvenement: "",
            DateEvenement: new Date(),
            TypeId: 1
        });

        sender.addRow(this.formGroup);
    }

    public editHandler({ sender, rowIndex, dataItem }: EditEvent): void {
        this.closeEditor(sender);

        this.formGroup = createFormGroup(dataItem);

        this.editedRowIndex = rowIndex;

        sender.editRow(rowIndex, this.formGroup);
    }

    public cancelHandler({ sender, rowIndex }: CancelEvent): void {
        this.closeEditor(sender, rowIndex);
    }

    public saveHandler({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
        const evenement = formGroup.value;
        debugger;
        this.service.addEvenement(evenement)
        .subscribe(
            data => {
                console.log(data);
        sender.closeRow(rowIndex);
        this.getEvenements();
            },
            (err) => {
                console.log(err);
            }
        );
    }

    public removeHandler({ dataItem }: RemoveEvent): void {
        this.service.deleteEvenement(dataItem.Id)
        .subscribe(
            data => {
            this.getEvenements();
                console.log(data);
            },
            (err) => {
                console.log(err);
            }
        );
    }

    private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
        grid.closeRow(rowIndex);
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
    }
}

const createFormGroup = (dataItem) =>
    new FormGroup({ 
        Id: new FormControl(dataItem.Id),
        SoldeConseillerId: new FormControl(dataItem.SoldeConseillerId),
        Texte: new FormControl(dataItem.Texte),
        CreateurEvenement: new FormControl(dataItem.CreateurEvenement, Validators.required),
        DateEvenement: new FormControl( new Date(dataItem.DateEvenement), Validators.required),
        TypeId: new FormControl(dataItem.TypeId, Validators.required)
    });