import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { environment } from "src/environments/environment";
import { IRegleDto } from "../dtos/regle.dto";
import { IRegle } from "../models.interfaces/regle.model.interface";
import { Helper } from "../utils/helper";
import { BuInterceptorService } from "./buInterceptor.service";

@Injectable()
export class ReglesBancaireService {

    constructor(private http: HttpClient) {}

    private getApiUrl(): string {
        return `${environment.apiBaseUrl}/ReglesBancaire/${this.bu()}`
    }

    private bu(): string {
        return BuInterceptorService.Bu;
    }

    public actualiserReglesBancairesConseillers(): Observable<any> {
        return this.http
            .post(`${this.getApiUrl()}/actualiserConseillers`, null);
    }

}