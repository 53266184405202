import { IPlanning } from '../models.interfaces/planning.model.interface';
import { IPlanningItem } from '../models.interfaces/planningItem.model.interface';
import { EmptyPlanningItem } from './emptyPlanningItem.model';
import { PlanningItem } from './planningItem.model';
import { EmptyArticle } from './emptyArticle.model';

export class Planning implements IPlanning {
    private _initialItems: IPlanningItem[] = [];
    private _items: IPlanningItem[] = [];
    public articles: string[];
    private _isDirty: boolean;
    public set isDirty(value: boolean) { this._isDirty = value; this.canBeSaved = this._isDirty && this._isValid; }
    public get isDirty(): boolean { return this._isDirty; }
    private _isValid = true;  private set isValid(value: boolean) { this._isValid = value; this.canBeSaved = this._isDirty && this._isValid; }
    public canBeSaved: boolean;
    public get items(): IPlanningItem[] {
        return this._items;
    }
    public set items(value: IPlanningItem[]) {
        this._items = Planning.copyValues(value);
        this._initialItems = Planning.copyValues(value);
        this.updateArticles();
    }
    public reset(): void {
        this._items = Planning.copyValues(this._initialItems);
        this.articles = this._items.map(i => i.article.nom);
        this.isDirty = false;
        this.updateArticles();
    }
    public save(): void  {
        this._items.forEach(item => item.save());
        this._initialItems = Planning.copyValues(this._items);
        this.isDirty = false;
    }
    public add(): void  {
        this.isDirty = true;
        const newItem = new EmptyPlanningItem();
        newItem.articleChange.subscribe(() => this.updateArticles());
        this._items.push(newItem);
    }
    public updateOccurence(index: number, annee: number, mois: number) {
        this.items[index].updateOccurence(annee, mois);
        this.isDirty = true;
    }

    private static copyValues(values: IPlanningItem[]): IPlanningItem[] {
        const res: IPlanningItem[] = [];
        values.forEach(item => {
            const copiedItem = new PlanningItem(item.article.nom, item.isDirty);
            item.occurences.forEach(occ => {
                copiedItem.occurences.push(occ);
            });
            res.push(copiedItem);
        });
        return res;
    }

    private updateArticles() {
        this.articles = this.items.map(i => i.article.nom);
        this.isValid = this.items.reduce((acc, i) => acc == acc && !(i.article instanceof EmptyArticle), true );
    }
}
