import { IArticle } from '../models.interfaces/article.model.interface';

export class Article implements IArticle {
    nom: string;
    reference: string;
    ht: number;
    isPlannifiable: boolean;
    nePasAppliquerTva: boolean;
    compteDeProduitMetropole: string;
    compteDeProduitDomTom: string;
    compteDeProduitGuyaneMayote: string;
    compteDeProduitSaintMartin: string;
    code: string;
    id: string;
    constructor(nom: string, reference: string, ht: number, isPlannifiable: boolean, code: string) {
        this.nom = nom;
        this.reference = reference;
        this.ht = ht;
        this.isPlannifiable = isPlannifiable;
        this.code = code;
    }
}
