import { IFactureCommissionSearchModel } from '../models.interfaces/factureCommissionSearch.model.interface';
import { FactureStatuts } from '../models.interfaces/factureStatut.model.interface';
import { EmptyConseiller } from './emptyConseiller.model';

export class EmptyFactureCommissionSearchModel implements IFactureCommissionSearchModel {
    numero = "";
    statut = FactureStatuts.all;
    du = null;
    au = null;
    min = null;
    max = null;
    transactionCodeNum = null;
    codeFournisseur = "";
    anneeSignature = null;
}
