import { Injectable } from "@angular/core";
import { HttpRequest, HttpInterceptor, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse, HttpEvent } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { Observable, of, throwError, Subject } from "rxjs";
import { ServeurErreur } from "../models/serveurErreur.model";

@Injectable()
export class ServeurErreurService {
    public serveurErreur: Subject<ServeurErreur> = new Subject<ServeurErreur>();
    public erreurSurvenue(erreur: ServeurErreur) {
        this.serveurErreur.next(erreur);
    }

} 