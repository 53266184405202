<kendo-grid #grid
    [data]="view | async"
    [pageSize]="gridState.take" [skip]="gridState.skip" [sort]="gridState.sort"
    [pageable]="true" [sortable]="true"
    (dataStateChange)="onStateChange($event)"
    (cellClick)="cellClickHandler($event)"
    (cellClose)="cellCloseHandler($event)"
    (cancel)="cancelHandler($event)"
    (save)="saveHandler($event)"
    (remove)="removeHandler($event)"
    (add)="addHandler($event)">

    <ng-template kendoGridToolbarTemplate>
        <button kendoGridAddCommand><span class="fa fa-plus addBtn"></span></button>
        <button kendoButton [disabled]="!editService.hasChanges()" (click)="saveChanges(grid);" [iconClass]="'fa fa-floppy-o'" class="saveBtn" ></button>
        <button kendoButton [disabled]="!editService.hasChanges()" (click)="cancelChanges(grid);" [iconClass]="'fa fa-ban'" class="cancelBtn" ></button>
        <span *ngIf='isSpinning' class="k-i-loading k-icon" style="font-size: 32px;"></span>
    </ng-template>

    <kendo-grid-column class="{{column.class}}"  *ngFor="let column of columns" width="{{column.width}}" field="{{column.field}}" title="{{column.title}}" [editor]="column.editor" [editable]="column.editable">
        <ng-template *ngIf="column.isBool" kendoGridCellTemplate let-dataItem>
            <input type="checkbox" id="{{column.field}}_{{ dataItem.Id }}" class="k-checkbox" [checked]="dataItem[column.field]" disabled/>
            <label class="k-checkbox-label"  for="{{column.field}}_{{ dataItem.Id }}"></label>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column class="k-text-center" title="command" width="100">
        <ng-template kendoGridCellTemplate let-isNew="isNew">
            <button kendoGridRemoveCommand><span class="fa fa-trash deleteBtn"></span></button>
            <button kendoGridSaveCommand><span class="fa fa-check checkBtn"></span></button>
            <button kendoGridCancelCommand><span class="fa fa-ban cancelBtn"></span></button>
        </ng-template>
    </kendo-grid-command-column>

</kendo-grid>

<div>
    <span style="color:red;">{{infoMessage}}</span>
</div>