import { Component, OnInit, Input, Output, ViewChild, EventEmitter,ChangeDetectionStrategy,ChangeDetectorRef } from '@angular/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { IArticle } from '../../../models.interfaces/article.model.interface';
import { EmptyArticle } from '../../../models/emptyArticle.model';
import { ArticlesService } from 'src/app/services/articles.service';

@Component({
  selector: 'app-article-selector',
  templateUrl: './article-selector.component.html',
  styleUrls: ['./article-selector.component.scss'],
  providers: [ArticlesService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleSelectorComponent implements OnInit {

  public articles: IArticle[] = [];
  public filteredArticles: IArticle[] = [];
  private _article: IArticle;
  @Input() public get article(): IArticle {
    return this._article;
  }

  public set article(article: IArticle) {
    if (article instanceof EmptyArticle) {
      this.dropdownlist.reset();
      this.articles = [];
    }
    this._article = article;
    this.articleChange.emit(this._article);
  }
  @Output() public articleChange: EventEmitter<IArticle> = new EventEmitter<IArticle>();
  @Input() public isPlannifiablesOnly = false;
  private _articlesToExclude: string[] = [];
  public get articlesToExclude(): string[] { return this._articlesToExclude; }
  @Input() public set articlesToExclude(value: string[]) {
    this._articlesToExclude = value;
    this.handleFilter(null);
  }
  @ViewChild(DropDownListComponent, {static: true}) dropdownlist: DropDownListComponent;

  constructor(private _articlesService: ArticlesService, private changeRef:ChangeDetectorRef) { }

  ngOnInit() {
    setTimeout(() => {
      this
        ._articlesService
        .getArticles(this.isPlannifiablesOnly)
        .subscribe(articles => {
          this.articles = articles;
          this.handleFilter(null);
        });
    });
  }
  
  public ngAfterViewInit(): void {
    this.changeRef.detectChanges();
  }
  public onValueChange(selectedArticle: IArticle) {
    this.article = selectedArticle;
  }

  public handleFilter(value: string) {
    this.filteredArticles = this.articles.filter((a) => this.articlesToExclude.indexOf(a.nom) === -1 && (!value || a.nom.toLowerCase().indexOf(value.toLowerCase()) !== -1));
  }

}
