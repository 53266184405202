<div>
    <span style="color:red;">{{infoMessage}}</span>
    <span style="color:green;">{{infoMessageSuccess}}</span>
    <div class="alert-section">
        <alert></alert>
      </div>
</div>
<kendo-grid
    [data]="factures | async"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="true"
    [selectable]="selectableSettings"
    [rowClass]="rowCallback"
    [kendoGridSelectBy]="'id'"
    [selectedKeys]="selectedFactureIds"
    (selectionChange)="onSelectionChange($event)"
    [sortable]="{ allowUnsort: true, mode: 'single' }"
    [sort]="sort"
    (sortChange)="sortChange($event)"
  >
    <ng-template kendoGridToolbarTemplate>
        <div class="row align-items-center col-md-12">
            <div class="col-md-3">
                <button title="Recharger" class="btn btn-primary" (click)="refreshClick()"><span class="fa fa-refresh"></span></button>
                <button title="Rebobiner" class="btn btn-warning" (click)="goBackwardClick()"><span class="fa fa-backward"></span></button>
                <button title="Régénérer" class="btn btn-warning" (click)="regenererClick()"><span class="fa fa-repeat"></span></button>
                <button title="Numéroter" class="btn numeroteeLegend" (click)="numeroterClick()"><span class="fa fa-sort-numeric-asc"></span></button>
                <button title="Envoyer" class="btn btn-info" (click)="sendToConseillerClick()"><span class="fa fa-paper-plane"></span></button>
                <button title="Exporter" class="btn btn-info" (click)="exportToComptaClick()"><span class="fa fa-upload"></span></button>
                <button title="Annuler" class="btn btn-danger" (click)="annulerClick()"><span class="fa fa-ban"></span></button>
            </div>
            <div class="col-md-2">
                <span *ngIf='isSpinning' class="k-i-loading k-icon" style="font-size: 32px;"></span>
            </div>
            <div class="col-md-7">
                <span style="padding-right:7px;">Légende:</span>
                <span class="enPreparationLegend" style="padding:7px;">En préparation</span>
                <span class="numeroteeLegend" style="padding:7px;">Numérotée</span>
                <span class="envoyeeLegend" style="padding:7px;">Envoyée</span>
                <span class="aPayerLegend" style="padding:7px;">A payer</span>
                <span class="payeeLegend" style="padding:7px;">Payée</span>
                <span class="annuleeLegend" style="padding:7px;">Annulée</span>
            </div>
        </div>
    </ng-template>
    <kendo-grid-checkbox-column showSelectAll="true" [class]="{'codeColumn': true}" width="60">
    </kendo-grid-checkbox-column>
    <kendo-grid-column field="numero" title="Numéro">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <a href='{{dataItem.documenturl}}' style="text-decoration: underline; color: blue;" target="_blank">{{dataItem.numero}}</a>
          </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="date" title="Date" type="date" format="{0:dd/MM/yy}"></kendo-grid-column>
    <kendo-grid-column field="echeance" title="Echeance" type="date" format="{0:dd/MM/yy}"></kendo-grid-column>
    <kendo-grid-column field="conseiller" title="Conseiller"></kendo-grid-column>
    <kendo-grid-column field="codeImputationClient" title="Code"></kendo-grid-column>
    <kendo-grid-column field="totalHt" title="HT" format="{0:c}">
            <ng-template
                kendoGridFooterTemplate
                let-column="column">{{totalHt | kendoNumber:'c' }}</ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="totalTva" title="TVA" format="{0:c}">
            <ng-template
                kendoGridFooterTemplate
                let-column="column">{{totalTva | kendoNumber:'c' }}</ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="totalTtc" title="TTC" format="{0:c}">
            <ng-template
                kendoGridFooterTemplate
                let-column="column">{{totalTtc | kendoNumber:'c' }}</ng-template>
    </kendo-grid-column>
    <div *kendoGridDetailTemplate="let dataItem">
        <app-facture-pack-details-grid [factureId]="dataItem.id" [statut]="dataItem.statut"></app-facture-pack-details-grid>
    </div>
    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="100"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-info></kendo-pager-info>
    </ng-template>
</kendo-grid>
