import { Component, OnInit, Input } from '@angular/core';
import { IFactureCommissionDetail } from '../../../models.interfaces/factureCommissionDetail.model.interface';
import { FactureCommissionService } from 'src/app/services/factureCommission.service';

@Component({
  selector: 'app-facture-commission-details-grid',
  templateUrl: './facture-commission-details-grid.component.html',
  providers: [FactureCommissionService]
})
export class FactureCommissionDetailsGridComponent implements OnInit {
  @Input() public factureId: string;
  public factureDetails: IFactureCommissionDetail[];
  constructor(private _factureCommissionService: FactureCommissionService) { }
  ngOnInit() { this.refresh(); }
  public refresh() {
    this._factureCommissionService.getFactureDetails(this.factureId)
    .subscribe(factureDetails => this.factureDetails = factureDetails);
  }
}