import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { environment } from "src/environments/environment";
import { IEvenementDto } from "../dtos/evenement.dto";
import { IEnvelope } from "../dtos/envelope.dto";
import { IEvenement } from "../models.interfaces/evenement.model.interface";
import { Helper } from "../utils/helper";
import { BuInterceptorService } from "./buInterceptor.service";

@Injectable()
export class EvenementsService {
    public currentSoldeConseillerId: string;
    constructor(private http: HttpClient) {}

    private getApiUrl(): string {
        return `${environment.apiBaseUrl}/SoldesEvenements`
    }

    private bu(): string {
        return BuInterceptorService.Bu;
    }

    public getEvenements(idSoldesConseiller: string): Observable<IEvenement[]> {
        debugger;
        return this
            .http
            .get<IEnvelope<IEvenementDto[]>>(`${this.getApiUrl()}/?soldeConseillerId=${idSoldesConseiller}`)
            .map(dtos => Helper.mapDtoToModels(dtos.Result, this.mapEvenement));
    }

    public addEvenement(evenement: IEvenementDto): Observable<any> {
        return this.http
            .post<any>(`${this.getApiUrl()}`, evenement);
    }

    public updateEvenement(evenement: IEvenementDto): Observable<any> {
        return this.http
            .put(`${this.getApiUrl()}/${evenement.Id}`, evenement);
    }

    public deleteEvenement(evenementId: string) : Observable<any> {
        return this.http
            .delete<any>(`${this.getApiUrl()}/?evenementId=${evenementId}`);
    }

    private mapEvenement(dto: IEvenementDto): IEvenement {
        const res = <IEvenement>{
            Id: dto.Id,
            SoldeConseillerId: dto.SoldeConseillerId,
            CreateurEvenement: dto.CreateurEvenement,
            Texte: dto.Texte,
            DateEvenement: dto.DateEvenement,
            TypeId: dto.TypeId            
        };
        return res;
    }

}