import { ChangeDetectorRef, Injectable } from "@angular/core";

@Injectable()
export class LoggerService {
    
  public logs: ILog[] = [];
  public isVerbose: boolean;


  public info(msg: string) {
      this.log(new InfoLog(msg));
  }

  public async verbose(msg: string) {
      if(this.isVerbose){
        await delay(1);
        this.log(new VerboseLog(msg));
      }
  }

  public error(msg: string) {
      this.log(new ErrorLog(msg));
  }

  public success(msg: string) {
      this.log(new SuccessLog(msg));
  }
  
  public clear() {
    this.logs = [];
  }
  
  private log(log: ILog) {
    this.logs.push(log);
  }
  
}

interface ILog {
  readonly type: string;
  readonly text: string;
  readonly color: string;
}

abstract class BaseLog implements ILog {
  public readonly type: string;
  public readonly text: string;
  public readonly color: string;

  constructor(type: string, text: string, color: string) {
    this.type = type;
    this.text = text;
    this.color = color;
  }
}

class InfoLog extends BaseLog {
  constructor(text: string) {
    super('Info', text, 'black');
  }
}

class ErrorLog extends BaseLog {
  constructor(text: string) {
    super('Error', text, 'red');
  }
}

class SuccessLog extends BaseLog {
  constructor(text: string) {
    super('Success', text, 'green');
  }
}

class VerboseLog extends BaseLog {
  constructor(text: string) {
    super('Verbose', text, 'gray');
  }
}

function delay(ms) {
  return new Promise(function (resolve) {
      setTimeout(resolve, ms);
  });
}
  