import { Component } from "@angular/core";
import { ServeurErreur } from "../../../models/serveurErreur.model";
import { Observable, Subscription } from 'rxjs';
import { ServeurErreurService } from "../../../services/serveur-erreur.service";

@Component({
    selector: 'app-serveur-erreur',
    templateUrl: './serveur-erreur.component.html'
})
export class ServeurErreurComponent {
    private subscription: Subscription;
    constructor(private serveurErreurService: ServeurErreurService) {
        this.subscription = serveurErreurService.serveurErreur.subscribe((erreur) => {this.open(erreur)});
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    
    public erreur: ServeurErreur = new ServeurErreur();
    public opened: boolean = false;
    private open(erreur: ServeurErreur) {
        this.erreur = erreur;
        this.opened = true;
    }
    private close() {
        this.opened = false;
    }
}