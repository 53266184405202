import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Observable } from 'rxjs/Observable';
import { EditService } from '../../../services/edit.service';
import { IArticleDto } from '../../../dtos/article.dto';
import { BaseCrudComponent, IColumn } from '../base-crud/base-crud.component';

@Component({
  selector: 'app-articles',
  templateUrl: '../base-crud/base-crud.component.html',
  styleUrls: ['../base-crud/base-crud.component.scss', './articles.component.scss'],
  providers: [EditService]
})
export class ArticlesComponent extends BaseCrudComponent<IArticleDto> {

    constructor(formBuilder: FormBuilder, editService: EditService<IArticleDto>,) {
        super(
            formBuilder,
            editService,
            (dataItem: IArticleDto) => formBuilder.group({
                'Id': dataItem.Id,
                'Designation': dataItem.Designation,
                'PrixUnitaireHt': dataItem.PrixUnitaireHt,
                'IsPlannifiable': dataItem.IsPlannifiable,
                'Code': dataItem.Code,
                'CompteDeProduitMetropole': dataItem.CompteDeProduitMetropole,
                'CompteDeProduitDomTom': dataItem.CompteDeProduitDomTom,
                'CompteDeProduitGuyaneMayote': dataItem.CompteDeProduitGuyaneMayote,
                'CompteDeProduitSaintMartin': dataItem.CompteDeProduitSaintMartin,
                'NePasAppliquerTva': dataItem.NePasAppliquerTva
            }),
            () => formBuilder.group(<IArticleDto> {
                Id: '',
                Designation: '',
                PrixUnitaireHt: 0,
                IsPlannifiable: false,
                Code: '',
                CompteDeProduitMetropole: '',
                CompteDeProduitDomTom: '',
                CompteDeProduitGuyaneMayote: '',
                CompteDeProduitSaintMartin: '',           
                NePasAppliquerTva: false
            }),
            [
                <IColumn> { field: "Designation", title: "Désignation", editor: "text", editable: true},
                <IColumn> { field: "PrixUnitaireHt", width:"100", title: "Ht", editor: "numeric", editable: true},
                <IColumn> { field: "IsPlannifiable", width:"100", title: "Plannifiable?", editor: "boolean", class:"k-text-center", isBool: true, editable: true},
                <IColumn> { field: "Code", title: "Code API", editor: "text", editable: true},
                <IColumn> { field: "CompteDeProduitMetropole", width:"100", title: "Metropole", editor: "text", editable: true},
                <IColumn> { field: "CompteDeProduitDomTom", width:"100", title: "DomTom", editor: "text", editable: true},
                <IColumn> { field: "CompteDeProduitGuyaneMayote", width:"100" ,title: "GuyaneMayote", editor: "text", editable: true},
                <IColumn> { field: "CompteDeProduitSaintMartin", width:"100" ,title: "Saint-Martin", editor: "text", editable: true},
                <IColumn> { field: "NePasAppliquerTva", width:"110", title: "Pas de TVA", editor: "boolean", class:"k-text-center", isBool: true, editable: true},
            ],
            true,
            "articles"
        );
    }
}
