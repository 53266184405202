import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { IMouvementBancaire } from '../models.interfaces/mouvementBancaire.model.interface';
import { IMouvementBancaireDto } from '../dtos/mouvementBancaire.dto';
import { IFactureTiersChercherResultDto } from '../dtos/factureTiersChercherResult.dto';
import { Helper } from '../utils/helper';
import { Injectable } from "@angular/core";
import { BuInterceptorService } from "./buInterceptor.service";

@Injectable()
export class MouvementsBancairesService {

    constructor(private http: HttpClient) {
    }

    private getApiUrl(): string {
        return `${environment.apiBaseUrl}/MouvementsBancaires/${this.bu()}`
    }

    private bu(): string {
        return BuInterceptorService.Bu;
    }

    public getMouvementsBancaires(): Observable<IMouvementBancaire[]> {
        return this
            .http
            .get<any>(`${this.getApiUrl()}`)
            .map(res => Helper.mapDtoToModels(res.Result, this.mapMouvementBancaire));
    }

    public importerFichierBanque(file: File): Observable<any> {
        const payload = new FormData();
        payload.append('file', file);
        return this
            .http
            .post<any>(`${this.getApiUrl()}/import`, payload);
    }

    public updateMouvementBancaire(mouvementId: string, supprimer: boolean, numeroFactureTiers: number, payerFactureTiers: boolean): Observable<any> {
        const payload = { Supprimer: supprimer, NumeroFactureTiers: numeroFactureTiers, PayerFactureTiers: payerFactureTiers};
        return this.http
            .put<any>(`${this.getApiUrl()}/${mouvementId}`, payload);
    }

    public chercherFacture(mouvementId: string): Observable<IFactureTiersChercherResultDto> {
        return this.http
            .post<IFactureTiersChercherResultDto>(`${this.getApiUrl()}/${mouvementId}/chercherFacture`, null);
    }

    public payerFacture(mouvementId: string): Observable<any> {
        return this.http
            .post<string[]>(`${this.getApiUrl()}/${mouvementId}/payerFacture`, null);
    }
    public exporterMouvementsBancaires(): Observable<any> {
      return this
        .http
        .post<any>(`${this.getApiUrl()}/export`, null);
    }
    private mapMouvementBancaire(dto: IMouvementBancaireDto): IMouvementBancaire {
        return <IMouvementBancaire>{
            id : dto.Id,
            iban : dto.Iban,
            credit : dto.Credit,
            debit : dto.Debit,
            date : new Date(dto.Date + 'Z'),
            operation : dto.Operation,
            libelle : dto.Libelle,
            supprimer: dto.Supprimer,
            numeroFactureTiers : dto.NumeroFactureTiers,
            factureTiersTrouvee : dto.FactureTiersTrouvee,
            erreur : dto.FactureTiersErreur,
            transactionCodeNum : dto.TransactionCode,
            payerFactureTiers : dto.PayerFactureTiers,
            factureTiersPayee : dto.FactureTiersPayee,
            comptegeneral : dto.CompteGeneral,
            adb : dto.Adb
        };
    }
}
