<kendo-grid #grid
    [data]="gridView"
    [pageSize]="gridState.take" [skip]="gridState.skip" [sort]="gridState.sort"
    [pageable]="true" [sortable]="true"
    (sortChange)="sortChange($event)"
    (dataStateChange)="onStateChange($event)"
    [kendoGridInCellEditing]="createFormGroup"
    [resizable]="true"
>

    <ng-template kendoGridToolbarTemplate>
        <input type="file" (change)="fileChange($event)" placeholder="Télécharger ficher" accept=".csv">

        <ng-container *ngIf="Bu!='Digit'">

            <button kendoButton [disabled]="isSpinning || mouvements.length == 0" (click)="chercherFactures(grid);" class="chercherBtn" >Chercher factures</button>
            <button kendoButton [disabled]="isSpinning || mouvements.length == 0 || this.totalErreurs > 0" (click)="payerFactures(grid);" class="payerBtn" >Payer factures</button>

        </ng-container>

        <button kendoButton [disabled]="isSpinning || mouvements.length == 0" (click)="exporter();" class="exporterBtn" >Exporter</button>

        <ng-container *ngIf="Bu!='Digit'">

            <button kendoButton [disabled]="isSpinning || mouvements.length == 0" (click)="cocherTout();" class="cocherToutBtn" >Tout cocher</button>
            <button kendoButton [disabled]="isSpinning || mouvements.length == 0" (click)="decocherTout();" class="decocherToutBtn" >Tout decocher</button>
            <button kendoButton [disabled]="isSpinning || mouvements.length == 0" (click)="decocherActe();" class="decocherActeBtn" >Decocher actés</button>

        </ng-container>

        <span *ngIf='isSpinning' class="k-i-loading k-icon" style="font-size: 32px;"></span>
        <span *ngIf='statut'>{{statut}}</span>
        <span *ngIf='erreur' class="erreurMsg">{{erreur}}</span>
    </ng-template>

    <kendo-grid-column field="debit" title="Débit" [editable]="false" width="70" [style]="{'text-align': 'right'}"></kendo-grid-column>
    <kendo-grid-column field="credit" title="Crédit" [editable]="false" width="70" [style]="{'text-align': 'right'}"></kendo-grid-column>
    <kendo-grid-column field="date" title="Date" format="{0:dd-MMM}" [editable]="false" width="100"></kendo-grid-column>
    <kendo-grid-column field="operation" title="Opération" [editable]="false" width="170"></kendo-grid-column>
    <kendo-grid-column field="libelle" title="Libellé" [editable]="false"></kendo-grid-column>
    <kendo-grid-column field="comptegeneral" title="Compte general" width="150"></kendo-grid-column>
    <kendo-grid-column field="adb" title="Adb" [editor]="'boolean'" width="70">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <input type="checkbox" id="adb_{{ rowIndex }}" class="k-checkbox" [checked]="dataItem.adb" disabled/>
            <label class="k-checkbox-label"  for="adb_{{ rowIndex }}"></label>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="supprimer" title="Supprimer" [editor]="'boolean'" width="90">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <input type="checkbox" id="supprimer_{{ rowIndex }}" class="k-checkbox" [checked]="dataItem.supprimer" disabled/>
            <label class="k-checkbox-label"  for="supprimer_{{ rowIndex }}"></label>
        </ng-template>
    </kendo-grid-column>

    <ng-container *ngIf="Bu!='Digit'">

        <kendo-grid-column field="payerFactureTiers" title="Payer" [editor]="'boolean'" width="70">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <input type="checkbox" id="payerFactureTiers_{{ rowIndex }}" class="k-checkbox" [checked]="dataItem.payerFactureTiers" disabled/>
                <label class="k-checkbox-label"  for="payerFactureTiers_{{ rowIndex }}"></label>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="numeroFactureTiers" title="Nº Facture" [editor]="'numeric'" width="100"></kendo-grid-column>
        <kendo-grid-column field="transactionCodeNum" title="Transaction" width="100">
            <ng-template kendoGridCellTemplate let-dataItem>
                <a *ngIf='dataItem.transactionCodeNum' 
                    href="{{ dataItem.tremplinTransactionUrl }}"
                    target="_blank" style="text-decoration:underline">
                    {{ dataItem.transactionCodeNum}}
                </a>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="erreur" title="Erreur" [editable]="false" width="150"></kendo-grid-column>

    </ng-container>

</kendo-grid>
