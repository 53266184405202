import { Component, OnInit, Input, ViewChild, ViewContainerRef, Injectable } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Observable } from 'rxjs/Observable';
import { EditService, HasId } from '../../../services/edit.service';

@Injectable()
export abstract class BaseCrudComponent<TDto extends HasId> implements OnInit {
    public id: string = "";
  public view: Observable<GridDataResult>;
  public gridState: State = {
      sort: [],
      skip: 0,
      take: 15
  };
  public changes: any = {};
  public infoMessage: string;
  private isSpinning: boolean;

  public onStateChange(state: State) {
      this.gridState = state;
      this.editService.read();
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
      if (!isEdited) {
          sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
      }
  }

  public cellCloseHandler(args: any) {
      const { formGroup, dataItem } = args;

      if (!formGroup.valid) {
           //prevent closing the edited cell if there are invalid values.
          args.preventDefault();
      } else if (formGroup.dirty) {
          this.editService.assignValues(dataItem, formGroup.value);
          this.editService.update(dataItem);
      }
  }

  public addHandler({ sender }) {
      sender.addRow(this.createEmptyFormGroup());
  }

  public cancelHandler({ sender, rowIndex }) {
      sender.closeRow(rowIndex);
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
      if (formGroup.valid) {
          this.editService.create(formGroup.value);
          sender.closeRow(rowIndex);
      }
  }

  public removeHandler({ sender, dataItem }) {
      this.editService.remove(dataItem);
      sender.cancelCell();
  }

  public saveChanges(grid: any): void {
      grid.closeCell();
      grid.cancelCell();
      this.editService.saveChanges();
  }

  public cancelChanges(grid: any): void {
      grid.cancelCell();
      this.editService.cancelChanges();
  }

  constructor(
    private formBuilder: FormBuilder,
    public editService: EditService<TDto>,
    private createFormGroup: (TDto) => FormGroup,
    private createEmptyFormGroup: () => FormGroup,
    public columns: IColumn[],
    public hasDetails: boolean,
    apiPath: string,
    id:string ="") {
        this.editService.setApiPath(apiPath);
        this.editService.id = this.id;
    }

  
    ngOnInit() {
        this.view = this
            .editService
            .do(() => this.infoMessage = null)
            .map(data => process(data, this.gridState));
        this.editService.errors.finally(() => this.isSpinning = false).subscribe(error => this.infoMessage = error);
        
        this.editService.read();
    }

}

export interface IColumn {
  field: string;
  title: string;
  isBool: boolean;
  editor: string;
  editable: boolean;
  width:string;
  class:string;
}