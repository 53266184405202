import { Component, OnInit, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/Observable';
import { RowClassArgs, PageChangeEvent, GridDataResult, GridComponent, DataStateChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { FactureStatutEnum } from '../../../enums/factureStatut.enum';
import { IFacture } from '../../../models.interfaces/facture.model.interface';
import { environment } from '../../../../environments/environment';
import { IExportDto } from '../../../dtos/export.dto';
import { Helper } from '../../../utils/helper';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { FactureHonorairesService } from 'src/app/services/factureHonoraires.service';
import { IFactureHonorairesSearchModel } from 'src/app/models.interfaces/factureHonorairesSearch.model.interface';
import { EmptyFactureHonorairesSearchModel } from 'src/app/models/emptyFactureHonorairesSearch.model';
import { IFactureHonorairesSummary } from 'src/app/models.interfaces/factureHonorairesSummary.model.interface';
import { suiviEnvoiMailDto } from 'src/app/dtos/suiviEnvoiMail.dto';

import { AlertService} from '../../../services/alert.service';

@Component({
  selector: 'app-factures-honoraires-grid',
  templateUrl: './factures-honoraires-grid.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./factures-honoraires-grid.component.scss'],
  providers: [FactureHonorairesService]
})
export class FacturesHonorairesGridComponent implements OnInit {

  public searchModel: IFactureHonorairesSearchModel = new EmptyFactureHonorairesSearchModel();
  public factures: Observable<GridDataResult>;
  private errors: Observable<string>;
  private totalHt: number;
  private totalTva: number;
  private totalTtc: number;
  private selectedFactures: IFactureHonorairesSummary[] = [];
  public selectedFactureIds: string[] = [];
  public pageSize = 100;
  public skip: number;
  public infoMessage: string;
  private isSpinning: boolean;
  public showPopupSuivi: boolean = false;
  public infosSuivi: suiviEnvoiMailDto[];
  public showPopupPaiement: boolean = false;
  public datePaiement: Date = new Date();
  public modeReglementCheque: boolean = true;

  @ViewChild(GridComponent, {static: true}) private grid: GridComponent;

  public selectableSettings: SelectableSettings = {
    checkboxOnly: true,
    mode: 'multiple'
  };

  public sort: SortDescriptor[] = [{
    field: 'numero',
    dir: 'desc'
  }]; 

  constructor(private _factureHonorairesService: FactureHonorairesService, private alertService: AlertService) {
    this.factures = _factureHonorairesService.results;
    this.errors = _factureHonorairesService.errorMessages;
  }

  public refresh() {
    this.isSpinning = true;
    this.infoMessage = null;
    this._factureHonorairesService.query(this.searchModel, { skip: this.skip, take: this.pageSize });
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refresh();
  }

  public ngOnInit(): void {
    this
      .factures
      .subscribe(
        f =>
        {
          if (!f) return;
          this.setFacturesAndSum(f.data);
          f.data = orderBy(f.data, this.sort);
        },
        error => this.infoMessage = error.message
    );
    this
      .errors
      .subscribe(x => this.infoMessage = x);
    Observable
      .merge(this.factures, this.errors)
      .do(() => this.isSpinning = false)
      .subscribe();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.grid
        .dataStateChange
        .do(({ skip, take }: DataStateChangeEvent) => {
            this.skip = skip;
            this.pageSize = take;
        })
        .subscribe(x => this.refresh());    
    });
  }

  public applySearchModel(searchModel: IFactureHonorairesSearchModel) {
    this.searchModel = searchModel;
    this.refresh();
  }

  public rowCallback(context: RowClassArgs) {
    switch (context.dataItem.statut as FactureStatutEnum) {
      default:
        return {};
      case FactureStatutEnum.enPreparation:
        return { enPreparation: true };
      case FactureStatutEnum.numerotee:
        return { numerotee: true };
      case FactureStatutEnum.envoyee:
        return { envoyee: true };
      case FactureStatutEnum.aPayer:
        return { aPayer: true };
      case FactureStatutEnum.payee:
        return { payee: true };
      case FactureStatutEnum.annulee:
        return { annulee: true };
    }
  }

  public onSelectionChange(event: SelectionEvent) {
    if(event.selectedRows.length === 0) this.clearSelection();
    else event.selectedRows.forEach(r => this.selectedFactures.push(r.dataItem));
  }

  public numeroterClick() {
    this.isSpinning = true;
    this.infoMessage = null;
    this.numeroterHonoraires(0);
  }

  private numeroterHonoraires(index:number) {
    if (index >= this.selectedFactures.length) {
      this.clearSelection();
      this.infoMessage  = "Numérotation terminée";
      this.isSpinning = false;
      return;
    }

    this.infoMessage  = `Numérotation factures (${index+1}/${this.selectedFactures.length})`;

    this
      ._factureHonorairesService
      .numeroterFactureHonoraires(this.selectedFactures[index].id)
      .subscribe(
        res => {
          let result = JSON.parse(JSON.stringify(res));
          if (result && result.ErrorMessage)  {
            this.displayError(result.ErrorMessage);       
            this.isSpinning = false;
          }
          else{
            this.selectedFactures[index].statut = FactureStatutEnum.numerotee;
            this.selectedFactures[index].numero = result.numero;
            this.numeroterHonoraires(index + 1);
          }
        }
      );
  } 

  public envoyerClick() {
    this.isSpinning = true;
    this.infoMessage = null;
    this.envoyerFacture(0);
  }

  private envoyerFacture(index:number) {
    if (index >= this.selectedFactures.length) {
      this.clearSelection();
      this.infoMessage  = "Envoi terminée";
      this.isSpinning = false;
      return;
    }

    this.infoMessage  = `Envoi factures (${index+1}/${this.selectedFactures.length})`;

    this
      ._factureHonorairesService
      .envoyerFactureHonoraires(this.selectedFactures[index].id)
      .map((resu) => {
        let result = JSON.parse(JSON.stringify(resu))
        if (result && result.ErrorMessage)  {
          this.displayError(result.ErrorMessage);          
          this.isSpinning = false;
        }
      })
      .subscribe(
        res => {
          if(this.isSpinning){
            this.selectedFactures[index].statut = FactureStatutEnum.envoyee;
            this.envoyerFacture(index + 1);
          }
        }
      );
  } 

  public suivreEnvoiClick() {
    if (this.selectedFactures.length == 0) {
      alert('Aucune facture sélectionnée');
      return;
    }
    var factureId = this.selectedFactures[0].id;
    this.isSpinning = true;
    this.infoMessage = "Récupération information suivi envoi facture";
    this
      ._factureHonorairesService
      .getSuiviEnvoi(factureId)
      .finally(() => {
        this.isSpinning = false;
        this.infoMessage = '';
        this.showPopupSuivi = true;
      })
      .subscribe(
        res => {
          this.isSpinning = false;
          this.infoMessage = '';
          this.infosSuivi = res;
          this.showPopupSuivi = true;
        }
      );
  }

  public hidePopupSuivi() {
    this.showPopupSuivi = false;
  }

  public payerClick() {
    this.showPopupPaiement = true;    
  }

  public cancelPopupPaiement() {
    this.showPopupPaiement = false;
  }

  public closePopupPaiement(){
    this.showPopupPaiement = false;
    this.isSpinning = true;
    this.infoMessage = null;
    this.payerHonoraires(0);
  }

  private payerHonoraires(index:number) {
    if (index >= this.selectedFactures.length) {
      this.clearSelection();
      this.infoMessage  = "Paiement terminée";
      this.isSpinning = false;
      return;
    }

    this.infoMessage  = `Paiement factures (${index+1}/${this.selectedFactures.length})`;

    this
      ._factureHonorairesService
      .payerFactureHonoraires(this.selectedFactures[index].id, this.datePaiement, this.modeReglementCheque ? "CHEQUE" : "VIREMENT")
      .subscribe(
        res => {
          this.selectedFactures[index].statut = FactureStatutEnum.payee;
          this.payerHonoraires(index + 1);
        }
      );
  } 

  public annulerClick() {
    this.isSpinning = true;
    this.infoMessage = null;
    this.annulerHonoraires(0);
  }

  private annulerHonoraires(index:number) {
    if (index >= this.selectedFactures.length) {
      this.clearSelection();
      this.infoMessage  = "Annulation terminée";
      this.isSpinning = false;
      return;
    }

    this.infoMessage  = `Annulation factures (${index+1}/${this.selectedFactures.length})`;

    this
      ._factureHonorairesService
      .annuler(this.selectedFactures[index].id)
      .map((resu) => {
        let result = JSON.parse(JSON.stringify(resu))
        if (result && result.ErrorMessage)    {
          this.displayError(result.ErrorMessage);          
          this.isSpinning = false;
        }
      })
      .subscribe(
        res => {
          if(this.isSpinning){
            this.selectedFactures[index].statut = FactureStatutEnum.annulee;
            this.annulerHonoraires(index + 1);
          }
        }
      );
  } 

  private setFacturesAndSum(factures: IFacture[]) {
    let totHt = 0;
    let totTva = 0;
    let totTtc = 0;
    factures.forEach(f => {
      totHt += f.totalHt;
      totTva += f.totalTva;
      totTtc += f.totalTtc;
    });
    this.totalHt = totHt;
    this.totalTva = totTva;
    this.totalTtc = totTtc;
  }

  private clearSelection() {
    this.selectedFactures = [];
    this.selectedFactureIds = [];
  }

  private displayError(message: string) {
    this.alertService.error(message,
      { autoClose: false }
    );
  }

}

