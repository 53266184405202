import { Component } from "@angular/core";
import { ClientErreur } from "../../../models/clientErreur.model";
import { Observable, Subscription } from 'rxjs';
import { ClientErreurService } from "../../../services/client-erreur.service";

@Component({
    selector: 'app-client-erreur',
    templateUrl: './client-erreur.component.html'
})
export class ClientErreurComponent {
    private subscription: Subscription;
    constructor(private clientErreurService: ClientErreurService) {
        this.subscription = clientErreurService.clientErreur.subscribe((erreur) => {this.open(erreur)});
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    
    public erreur: ClientErreur = new ClientErreur();
    public opened: boolean = false;
    private open(erreur: ClientErreur) {
        this.erreur = erreur;
        this.opened = true;
    }
    private close() {
        this.opened = false;
    }
}