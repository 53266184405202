import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FactureStatutsArray } from '../../../models.interfaces/factureStatut.model.interface';
import { EmptyFactureHonorairesSearchModel } from 'src/app/models/emptyFactureHonorairesSearch.model';
import { IFactureHonorairesSearchModel } from 'src/app/models.interfaces/factureHonorairesSearch.model.interface';

@Component({
  selector: 'app-factures-honoraires-search',
  templateUrl: './factures-honoraires-search.component.html',
  styleUrls: ['./factures-honoraires-search.component.scss']
})
export class FacturesHonorairesSearchComponent implements OnInit {

  public statuts = FactureStatutsArray;
  public searchModel: IFactureHonorairesSearchModel = new EmptyFactureHonorairesSearchModel();
  @Output() public searchClicked: EventEmitter<IFactureHonorairesSearchModel> = new EventEmitter<IFactureHonorairesSearchModel>();

  constructor() {
  }

  ngOnInit() {
  }

  public onSearchClick() {
    this.searchClicked.emit(this.searchModel);
  }

  public onClearClick() {
    this.searchModel = new EmptyFactureHonorairesSearchModel();
  }
}
