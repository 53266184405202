import { Component, OnInit, Input } from '@angular/core';
import { IFacturePackDetail } from '../../../models.interfaces/facturePackDetail.model.interface';
import { FactureStatutEnum } from '../../../enums/factureStatut.enum';
import { FacturePackService } from 'src/app/services/facturePack.service';

@Component({
  selector: 'app-facture-pack-details-grid',
  templateUrl: './facture-pack-details-grid.component.html',
  styleUrls: ['./facture-pack-details-grid.component.scss'],
  providers: [FacturePackService]
})
export class FacturePackDetailsGridComponent implements OnInit {

  @Input() public factureId: string;
  @Input() public statut: FactureStatutEnum;
  public get isEnPreparation(): boolean {
    return this.statut == FactureStatutEnum.enPreparation;
  }
  public factureDetails: IFacturePackDetail[];

  constructor(private _facturePackService: FacturePackService) { }

  ngOnInit() {
    this.refresh();
  }

  public deleteClick(detailId: string) {
    this
    ._facturePackService
    .deleteDetail(detailId)
    .subscribe(
      res => this.refresh(),
      null);
  }

  public refresh() {
    this._facturePackService
    .getFactureDetails(this.factureId)
    .subscribe(factureDetails => this.factureDetails = factureDetails);
  }

}
