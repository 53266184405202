import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { environment } from "src/environments/environment";
import { IPackageDto } from "../dtos/package.dto";
import { IPackage } from "../models.interfaces/package.model.interface";
import { Helper } from "../utils/helper";
import { BuInterceptorService } from "./buInterceptor.service";

@Injectable()
export class PackagesService {
    
    constructor(private http: HttpClient) {}
    
    private getApiUrl(): string {
        return `${environment.apiBaseUrl}/Packages/${this.bu()}`
    }

    private bu(): string {
        return BuInterceptorService.Bu;
    }
    
    public getPackages(searchText: string): Observable<IPackage[]> {
        return this.http
            .get<IPackageDto[]>(`${this.getApiUrl()}/${searchText}`)
            .map(dtos => Helper.mapDtoToModels(dtos, this.mapPackage));
    }

    private mapPackage(dto: IPackageDto): IPackage {
        return <IPackage>{
            id: dto.Id,
            nom: dto.Nom,
            ht: dto.Ht,
            quantite: 1
        };
    }
}