import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { IConseiller } from '../../../models.interfaces/conseiller.model.interface';
import { EmptyConseiller } from '../../../models/emptyConseiller.model';
import { IPlanning } from '../../../models.interfaces/planning.model.interface';
import { Planning } from '../../../models/planning.model';
import { FacturePackDetailPlannifiesService } from 'src/app/services/facturePackDetailPlannifies.service';

@Component({
  selector: 'app-planned-facture-detail-form',
  templateUrl: './planned-facture-detail-form.component.html',
  styleUrls: ['./planned-facture-detail-form.component.scss'],
  providers: [FacturePackDetailPlannifiesService]
})
export class PlannedFactureDetailFormComponent implements OnInit {

  private _observableConseiller = new Subject<IConseiller>();
  private _observableAnnee = new Subject<number>();
  private _selectedConseiller: IConseiller = new EmptyConseiller();
  public get selectedConseiller(): IConseiller {
    return this._selectedConseiller;
  }
  public set selectedConseiller(value: IConseiller) {
    this._selectedConseiller = value;
    this._observableConseiller.next(value);
  }
  private _selectedAnnee: number = new Date().getFullYear();
  public get selectedAnnee(): number {
    return this._selectedAnnee;
  }
  public set selectedAnnee(value: number) {
    this._selectedAnnee = value;
    this._observableAnnee.next(value);
  }
  public isPlanningVisible = false;
  public planning: IPlanning = new Planning();
  public errorMessage: string;

  constructor(private _facturePackDetailPlannifiesService: FacturePackDetailPlannifiesService) {
    Observable
    .merge(this._observableConseiller, this._observableAnnee)
    .distinctUntilChanged()
    .subscribe(
      response => {
        this.isPlanningVisible = this.selectedConseiller && this.selectedConseiller.codeClient && this.selectedAnnee != null;
        if (this.isPlanningVisible) {
          this
            ._facturePackDetailPlannifiesService
            .getFactureDetailPlannifies(this.selectedConseiller.codeClient, this.selectedAnnee)
            .subscribe(
              planningItems => this.planning.items = planningItems,
              error => this.errorMessage = error.message);
      }
    });
  }

  ngOnInit() {
  }

  public addPlanningItemClick() {
    this.planning.add();
  }

  public onOccurenChange(i: number, annee: number, mois: number) {
    this.planning.updateOccurence(i, annee, mois);
  }

  public onCancelClick() {
    this.planning.reset();
  }

  public onSaveClick() {
    this
      ._facturePackDetailPlannifiesService
      .postNewFactureDetailPlannifies(this.selectedConseiller, this.selectedAnnee, this.planning.items)
      .subscribe(
        success => this.planning.save(),
        error => this.errorMessage = error.message);
  }

}


