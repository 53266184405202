import { Component, OnInit } from '@angular/core';
import { MouvementsBancairesService } from '../../../services/mouvementsBancaires.service';
import { IMouvementBancaire } from '../../../models.interfaces/mouvementBancaire.model.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SortDescriptor, orderBy, State } from '@progress/kendo-data-query';
import { RowClassArgs, PageChangeEvent, GridDataResult, GridComponent, DataStateChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { BuInterceptorService } from '../../../services/buInterceptor.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-banque',
    templateUrl: './banque.component.html',
    providers: [MouvementsBancairesService],
    styleUrls: ['./banque.component.scss'],
})
export class BanqueComponent implements OnInit {
    public isSpinning: boolean;
    public gridView: GridDataResult;
    public mouvements: IMouvementBancaire[] = [];
    public totalErreurs: number;
    private mouvementsChercherFacture: IMouvementBancaire[] = [];
    private mouvementsPayerFacture: IMouvementBancaire[] = [];

    public erreur: string;
    public statut: string;

    constructor(private _mouvementsBancairesService: MouvementsBancairesService, private formBuilder: FormBuilder) {
    }

    public sortGrid: SortDescriptor[] = [{
        field: 'numeroFacture',
        dir: 'asc'
    }];

    public gridState: State = {
        sort: this.sortGrid,
        skip: 0,
        take: 100
    };
    
  public onStateChange(state: State) {
    this.gridState = state;
    this.refresh();
}
    ngOnInit() {
        this.getMouvementsBancaires();
    }

    public get Bu(): string {
        return BuInterceptorService.Bu;
    }

    public sortChange(sort: SortDescriptor[]): void {
        this.sortGrid = sort;
        this.refresh();
    }

    private refresh(): void {
        this.gridView = {
            data: orderBy(this.mouvements, this.sortGrid).slice(this.gridState.skip, this.gridState.skip + this.gridState.take),
            total: this.mouvements.length

        };
    }

    public createFormGroup = ({ dataItem }): FormGroup => {
        const form = this.formBuilder.group({
            id: dataItem.id,
            supprimer: dataItem.supprimer,
            payerFactureTiers: dataItem.payerFactureTiers,
            numeroFactureTiers: [dataItem.numeroFactureTiers, { updateOn: 'blur' }]
        });
        if (dataItem.debit > 0) {
            form.get('payerFactureTiers').disable();
        }
        form.get('supprimer').valueChanges.subscribe((value) => {
            form.value.supprimer = value;
            if (value) {
                form.patchValue({payerFactureTiers: false});
            }
            this.update(form.value);
        });
        form.get('payerFactureTiers').valueChanges.subscribe((value) => {
            form.value.payerFactureTiers = value;
            if (value) {
                form.patchValue({supprimer: false});
            }
            this.update(form.value);
        });
        form.get('numeroFactureTiers').valueChanges.subscribe((value) => {
            form.value.numeroFactureTiers = value;
            this.update(form.value);
        });
        return form;
    }

    update(values) {
        this.isSpinning = true;
        this._mouvementsBancairesService.updateMouvementBancaire(values.id, values.supprimer, values.numeroFactureTiers, values.payerFactureTiers)
            .subscribe(data => {
                this.isSpinning = false;
            });
    }

    fileChange(event) {
        this.isSpinning = true;
        this.statut = 'Import fichier en cours...';
        const fileList: FileList = event.target.files;
        if (fileList.length == 0) { return; }
        const file: File = fileList[0];
        this._mouvementsBancairesService
            .importerFichierBanque(file)
            .finally(() => {
                this.isSpinning = false;
                this.statut = '';
            })
            .subscribe(data => {
                if (data.Result.createdCount === 0) {
                  alert('Aucune nouvelle ligne importée !\nLe fichier contient '
                    + data['Count'] + ' lignes, mais aucune ne sont nouvelles.');
                }
                this.getMouvementsBancaires();
            },
            (err) => {
                this.isSpinning = false;
                this.erreur = err;
                this.statut = '';
                console.log(err);
            });
    }
    getMouvementsBancaires() {
        this.isSpinning = true;
        this.statut = 'Lecture mouvements en cours...';
        this._mouvementsBancairesService.getMouvementsBancaires()
            .finally(() => {
                this.isSpinning = false;
                this.statut = '';
            })
            .subscribe(
                data => {
                    this.mouvements = data;
                    this.mouvements.forEach((mouvement) => this.setUrlToTransaction(mouvement));
                    this.totalErreurs = this.mouvements.length; // Assume all bad
                    this.refresh();
                },
                (err) => {
                    this.isSpinning = false;
                    this.erreur = err;
                    this.statut = '';
                    console.log(err);
                }
            );
    }

    public chercherFactures(grid: any) {
        grid.closeCell();
        this.mouvementsChercherFacture =
            this.mouvements.filter(mouvement => mouvement.payerFactureTiers);
        if (this.mouvementsChercherFacture.length == 0) { return; }
        this.isSpinning = true;
        this.totalErreurs = this.mouvementsChercherFacture.length;
        this.chercherFacture(0);
    }

    private chercherFacture(index: number) {
        this.statut = 'Recherche factures en cours : (' + (index + 1) + '/' + this.mouvementsChercherFacture.length + ')';
        const mouvement = this.mouvementsChercherFacture[index];
        mouvement.erreur = '';
        this._mouvementsBancairesService
            .chercherFacture(mouvement.id)
            .subscribe(res => {
                    mouvement.factureTiersTrouvee = res.FactureTiersTrouvee;
                    mouvement.numeroFactureTiers = res.NumeroFactureTiers;
                    mouvement.erreur = res.Erreur;
                    mouvement.payerFactureTiers = res.PayerFactureTiers;
                    mouvement.transactionCodeNum = res.TransactionCodeNum;
                    this.setUrlToTransaction(mouvement);
                    if ((mouvement.erreur == null) || (mouvement.erreur == '')) {
                        this.totalErreurs--;
                    }
                    if (index == this.mouvementsChercherFacture.length - 1) {
                        this.chercherFacturesTerminee();
                    } else {
                        index++;
                        this.chercherFacture(index);
                    }
                },
                err => this.erreur = err
            );
    }

    private setUrlToTransaction(mouvement: IMouvementBancaire) {
        if (mouvement.transactionCodeNum != null) {
            mouvement.tremplinTransactionUrl = `${environment.tremplinUrl[this.Bu]}/Transaction/0/Remuneration/EditByCodeNum/${mouvement.transactionCodeNum}`;
        } else {
            mouvement.tremplinTransactionUrl = null;
        }
    }

    private chercherFacturesTerminee() {
        this.statut = 'Recherche factures terminée : ' + this.totalErreurs + ' erreur(s)';
        this.isSpinning = false;
    }

    public payerFactures(grid: any) {
        grid.closeCell();
        this.mouvementsPayerFacture =
            this.mouvements.filter(mouvement => mouvement.payerFactureTiers);
        if (this.mouvementsPayerFacture.length == 0) { return; }
        this.isSpinning = true;
        this.payerFacture(0);
    }

    private payerFacture(index: number) {
        this.statut = 'Paiement factures en cours : (' + (index + 1) + '/' + this.mouvementsPayerFacture.length + ')';
        const mouvement = this.mouvementsPayerFacture[index];
        this._mouvementsBancairesService
            .payerFacture(mouvement.id)
            .subscribe(res => {
                    mouvement.factureTiersPayee = true;
                    if (index == this.mouvementsPayerFacture.length - 1) {
                        this.payerFactureTerminee();
                    } else {
                        index++;
                        this.payerFacture(index);
                    }
                },
                err => this.erreur = err
            );
    }

    private payerFactureTerminee() {
        this.statut = 'Paiement factures terminé';
        this.isSpinning = false;
    }

    public exporter() {

        const enAttentePaiement = this.mouvements.filter(mouvement => mouvement.payerFactureTiers && !mouvement.factureTiersPayee).length;
        if (enAttentePaiement > 0) {
            if (!confirm(`Voulez-vous exporter?  Il y a ${enAttentePaiement} facture(s) en attente de paiement.`)) {
                return;
            }
        }

        this.isSpinning = true;
        this.statut = 'Export en cours...';
        this._mouvementsBancairesService
            .exporterMouvementsBancaires()
            .subscribe(
                res => {
                    this.isSpinning = false;
                    this.statut = res.Message;
                },
                err => {
                    this.isSpinning = false;
                    this.erreur = err;
                }
            );
    }

    public cocherTout() {
        this.mouvements.forEach(mouvement => {
            mouvement.payerFactureTiers = true;
        });
    }

    public decocherTout() {
        this.mouvements.forEach(mouvement => {
            mouvement.payerFactureTiers = false;
        });
    }

    public decocherActe() {
        this.mouvements.forEach(mouvement => {
            if (mouvement.erreur) {
                if (mouvement.erreur.endsWith('Acté')) {
                    mouvement.payerFactureTiers = false;
            }
                }
        });
    }

}
