<form class="k-form row" #form="ngForm">
    <div class="col-md-2">
        <label class="k-form-field ">
            <span>Numéro de facture:</span>            
            <kendo-textbox 
            [(ngModel)]="searchModel.numero"
                name="numero"></kendo-textbox>
        </label>
        <label class="k-form-field">
            <span>Conseiller:</span>
            <kendo-textbox 
            [(ngModel)]="searchModel.codeImputationClient"
                name="codeImputationClient"></kendo-textbox>
        </label>
    </div>
    <div class="col-md-2">
        <label class="k-form-field">
            <span>Montant Min:</span>
            <kendo-numerictextbox
                [(ngModel)]="searchModel.min" name="min" [min]="0">
            </kendo-numerictextbox>
        </label>
        <label class="k-form-field">
            <span>Montant Max:</span>
            <kendo-numerictextbox
                [(ngModel)]="searchModel.max"  name="min" [min]="0">
            </kendo-numerictextbox>
        </label>
    </div>
    <div class="col-md-3">
        <div class="row h-100">
            <div class="col-md-6">
                <label class="k-form-field">
                    <span>Du:</span>
                    <kendo-datepicker
                        [(ngModel)]="searchModel.du" name="du">
                    </kendo-datepicker>
                </label>
                <label class="k-form-field">
                    <span>Au:</span>
                    <kendo-datepicker
                        [(ngModel)]="searchModel.au"  name="au">
                    </kendo-datepicker>
                </label>
            </div>
            <div class="col-md-6">
                <div class="divButtonContent">
                    <button type="button" (click)="setMoisPasses()" class="btn btn-sm btn-primary btn-block">Mois passés</button>
                    <button type="button" (click)="setMoisEnCours()" class="btn btn-sm btn-primary btn-block">Mois en cours</button>
                    <button type="button" (click)="setMoisFuturs()" class="btn btn-sm btn-primary btn-block">Mois futurs</button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-2">
        <label class="k-form-field">
            <span>Statut:</span>
            <kendo-dropdownlist 
                textField="statutString"
                valueField="statut"
                [(ngModel)]="searchModel.statut"
                [data]="statuts"
                name="statut"></kendo-dropdownlist>
        </label>
        <label class="k-form-field">
            <span>Packages:</span>
            <app-packages-selector
                [(selectedPackages)]="searchModel.packages"
                name="packages">
            </app-packages-selector>
        </label>
    </div>
        <div class="col-md-2">
            <div class="divButtonContent">
                <button kendoButton  class="buttonSearch" (click)="onSearchClick()" [primary]="true">Rechercher</button>
                <button kendoButton (click)="onClearClick()">Effacer</button>
            </div>
        </div>
</form>
