import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';
import { IFactureDto } from '../dtos/facture.dto';
import { FacturePackFilterDto } from '../dtos/facturePackFilter.dto';
import { IFacture } from '../models.interfaces/facture.model.interface';
import { IFacturePackSearchModel } from '../models.interfaces/facturePackSearch.model.interface';
import { environment } from '../../environments/environment';
import { Helper } from '../utils/helper';
import { IDictionary } from '../models/dictionary.model';
import { BuInterceptorService } from './buInterceptor.service';
import { IFacturePackDetail } from '../models.interfaces/facturePackDetail.model.interface';
import { IFactureDetailOneShotDto, IFactureDetailOutDto as IFacturePackDetailDto, IFactureDetailPackDto } from '../dtos/factureDetail.dto';
import { INewFactureDetailModel } from '../models.interfaces/newFactureDetail.model.interface';
import { TypeDeProduitEnum } from '../enums/typeDeProduit.enum';

@Injectable()
export class FacturePackService {

    public results: BehaviorSubject<GridDataResult> = new BehaviorSubject<GridDataResult>(null);
    public errorMessages: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private http: HttpClient) {}
    
    private getApiUrl(): string {
        return `${environment.apiBaseUrl}/FacturesPack/${this.bu()}`
    }

    private bu(): string {
        return BuInterceptorService.Bu;
    }

    public query(searchModel: IFacturePackSearchModel, state: any): void {
        this.getFactures(searchModel, state)
            .subscribe(
                x => this.results.next(x),
                (err: Error) => this.errorMessages.next(err.message))
    }

    public getFactures(searchModel: IFacturePackSearchModel, state: any): Observable<GridDataResult> {
        return this
            .http
            .post<any>(
                `${this.getApiUrl()}/search/`,
                <FacturePackFilterDto> {
                    skip: state.skip,
                    take: state.take,
                    numeroFacture: searchModel.numero,
                    dateFactureDu: searchModel.du,
                    dateFactureAu: searchModel.au,
                    statutFacture: searchModel.statut.statut,
                    montantTtcMin: searchModel.min,
                    montantTtcMax: searchModel.max,
                    codeImputationClient: searchModel.codeImputationClient,
                    packageIds: searchModel.packages.map(p => p.id),
                }
            )
            .map(res => 
                <GridDataResult>{
                    total: res.Count, 
                    data: Helper.mapDtoToModels(res.Result, this.mapFacture)});
    }


    private mapFacture(dto: IFactureDto): IFacture {
        return <IFacture> {
          id: dto.Id,
          date: new Date(dto.DateFacture),
          echeance: new Date(dto.Echeance),
          statut: dto.Statut,
          numero: dto.NumeroFacture === "None" ? "" : dto.NumeroFacture,
          documenturl: Helper.fileManagerUrlFacturePack(BuInterceptorService.Bu, dto.Id),
          conseiller: dto.Conseiller,
          codeImputationClient: dto.CodeImputationClient,
          totalHt: dto.Ht,
          totalTva: dto.Tva,
          totalTtc: dto.Ht + dto.Tva,
        };
    }

    public genererFactures(asOfDate: Date): Observable<IDictionary<string>> {
        return this
            .http
            .get<IDictionary<string>>(`${this.getApiUrl()}/generer/${Helper.urlFormatDate(asOfDate)}`);    
    }

    public numeroterAll(take: number): Observable<IDictionary<string>> {
        return this
            .http
            .get<IDictionary<string>>(`${this.getApiUrl()}/numeroter/${take}`)
            .timeout(10 * 60 * 1000);
    }

    public postFactureIdsToNumeroter(ids: string[]): Observable<any> {
        return this
            .http
            .post<string>(`${this.getApiUrl()}/numeroter/`, ids);
    }


    public sendAllToConseiller(take: number): Observable<string> {
        return this
            .http
            .get<string>(`${this.getApiUrl()}/envoyer/${take}`)
            .timeout(10 * 60 * 1000);
    }

    public exportAll(): Observable<string> {
        return this.http.post<string>(`${this.getApiUrl()}/exporter/all`, null);
    }

    public postFactureIdsToGoBackward(ids: string[]): Observable<any> {
        return this
            .http
            .post<string>(`${this.getApiUrl()}/retour/`, ids);
    }

    public reGenererFactures(id: string): Observable<any> {
        return this
            .http
            .get<IDictionary<string>>(`${this.getApiUrl()}/regenerer/${id}`);
    }

    public postFactureIdsToSendToConseiller(ids: string[]): Observable<string> {
        return this
            .http
            .post<string>(`${this.getApiUrl()}/envoyer/`, ids);
    }

    public postFactureIdsToExportToConseiller(ids: string[]): Observable<string> {
        return this.http.post<string>(`${this.getApiUrl()}/exporter/`, ids);
    }

    public annuler(ids: string[]): Observable<string> {
        return this
            .http
            .post<string>(`${this.getApiUrl()}/annuler/`, ids);
    }

    
    public getFactureDetails(factureId: string): Observable<IFacturePackDetail[]> {
        return this
            .http
            .get<IFacturePackDetailDto[]>(`${this.getApiUrl()}/${factureId}/details`)
            .map(dtos => Helper.mapDtoToModels(dtos, this.mapFactureDetail));
    }

    private mapFactureDetail(dto: IFacturePackDetailDto): IFacturePackDetail {
        return <IFacturePackDetail>{
            id : dto.Id,
            libelle: dto.Libelle,
            quantite: dto.Quantite,
            proRataPrixUnitaireHt: dto.ProRataPrixUnitaireHt,
            ht: dto.Ht,
            tva: dto.Tva,
            ttc: dto.Ttc
        };
    }
    
    public deleteDetail(detailId: string): Observable<any> {
        return this
            .http
            .delete<IDictionary<string>>(`${this.getApiUrl()}/details/${detailId}`);
    }
    
    public postNewFactureDetail(factureDetails: INewFactureDetailModel): Observable<any> {
        return factureDetails.typeDeProduit == TypeDeProduitEnum.article
                ? this.postNewFactureDetailOneShot(this.MapToOneShotDto(factureDetails))
                : this.postNewFactureDetailPack(this.MapToOnePackDto(factureDetails));
    }

    private postNewFactureDetailOneShot(dto: IFactureDetailOneShotDto): Observable<any> {
        return this.http.post<string>(`${this.getApiUrl()}/detail/oneshot/`, dto);
    }

    private postNewFactureDetailPack(dto: IFactureDetailPackDto): Observable<any> {
        return this.http.post<string>(`${this.getApiUrl()}/detail/pack/`, dto);
    }

    private MapToOneShotDto(factureDetails: INewFactureDetailModel): IFactureDetailOneShotDto {
        return <IFactureDetailOneShotDto>{
            CodeImputationClient: factureDetails.conseiller.codeClient,
            DateConsommation: new Date(factureDetails.le.valueOf() - factureDetails.le.getTimezoneOffset() * 60000),
            Compte: factureDetails.compte,
            Designation: factureDetails.designation,
            PrixUnitaireHt: factureDetails.puHt,
            Quantite: factureDetails.quantite,
            NePasAppliquerTva:factureDetails.NePasAppliquerTva
        }
    }

    private MapToOnePackDto(factureDetails: INewFactureDetailModel): IFactureDetailPackDto {
        return <IFactureDetailPackDto>{
            CodeImputationClient: factureDetails.conseiller.codeClient,
            DateDebutConsommation: new Date(factureDetails.du.valueOf() - factureDetails.du.getTimezoneOffset() * 60000),
            DateFinConsommation: new Date(factureDetails.au.valueOf() - factureDetails.au.getTimezoneOffset() * 60000),
            Compte: factureDetails.compte,
            Designation: factureDetails.designation,
            PrixUnitaireHt: factureDetails.puHt,
            PackageId: factureDetails.pack.id,
            NePasAppliquerTva: factureDetails.NePasAppliquerTva
        }
    }

}
