import { environment } from "src/environments/environment";
import { IExportDto } from "../dtos/export.dto";

export class Helper {

    public static fileManagerUrlFactureHonoraires(bu: string, factureId: string) {
        return `${environment.fileManagerUrl}/api/facturation/${bu}/facture/honoraires/${factureId}`;
    }

    public static fileManagerUrlFactureCommission(bu: string, factureId: string) {
        return `${environment.fileManagerUrl}/api/facturation/${bu}/facture/commission/${factureId}`;
    }

    public static fileManagerUrlFacturePack(bu: string, factureId: string) {
        return `${environment.fileManagerUrl}/api/facturation/${bu}/facture/pack/${factureId}`;
    }

    public static tremplinTransactionRemunerationUrl(bu: string, transactionCodeNum: number) {
        return `${environment.tremplinUrl[bu]}/Transaction/0/Remuneration/EditByCodeNum/${transactionCodeNum}`
    }

    public static downloadCsv(csvContent: string, filename: string) {
        let filetype = "text/plain";    
        let a = document.createElement("a");
        let dataURI = "data:" + filetype + ";base64," + this.b64EncodeUnicode(csvContent);
        a.href = dataURI;
        a['download'] = filename;
        let e = document.createEvent("MouseEvents");
        // Use of deprecated function to satisfy TypeScript.
        e.initMouseEvent("click", true, false, document.defaultView, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        a.dispatchEvent(e);
        a.remove();
    }

    public static mapDtoToModels<TIn, TOut>(dtos: TIn[], mapDtoToModel: (TIn) => TOut): TOut[] {
        const models: TOut[] = [];
        for (const dto of dtos) {
            models.push(mapDtoToModel(dto));
        }
        return models;
    }

    public static generateCsvString(dtos: IExportDto[]) : string {
        var firstRow = true
        var res = "";
        dtos.forEach(dto => {
          if(!firstRow) res += "\r\n";
          res += dto.Journal + ";";
          res += dto.Piece + ";";
          res += dto.Date + ";";
          res += dto.Compte + ";";
          res += dto.Libelle.replace("é", "e").replace("€", "eur") + ";";
          res += dto.Debit + ";";
          res += dto.Credit + ";";
          res += dto.Axe1 + ";";
          res += dto.Axe2 + ";";
          res += dto.Echeance + ";"
          res += dto.ModeDePaiement + ";"
          firstRow = false;
        });
        return res;
      }
      
      
    private static b64EncodeUnicode(str: string) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
            return String.fromCharCode(parseInt(p1, 16))
        }))
    }

    public static urlFormatDate(date: Date): string {
        return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    }

}