<div class="row">
    <div class="col-md-4">
        <h3>Factures Honoraires</h3>
    </div>
</div>

<div class="row">
    <div class="col-md-3">
        <h4>Générer</h4>
        <button class="btn enPreparationLegend" [disabled]="isGenererHonorairesSpinning"
            (click)="genererHonorairesClick($event)">
            <span class="fa fa-repeat"></span>
        </button>
        <span *ngIf="isGenererHonorairesSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span><br />
        <span [ngStyle]="{ color: genererHonorairesInfoColor }">{{
            genererHonorairesInfoMessage
            }}</span>
    </div>
    <div class="col-md-3">
        <h4>Numéroter</h4>
        <button class="btn numeroteeLegend" [disabled]="isNumeroterHonorairesSpinning"
            (click)="numeroterHonorairesAllClick()">
            <span class="fa fa-sort-numeric-asc"></span>
        </button>
        <span *ngIf="isNumeroterHonorairesSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span><br />
        <span [ngStyle]="{ color: numeroterHonorairesInfoColor }">{{
            numeroterHonorairesInfoMessage
            }}</span>
    </div>
    <div class="col-md-3">
        <h4>Envoyer</h4>
        <button class="btn btn-info" [disabled]="isEnvoyerHonorairesSpinning" (click)="envoyerHonorairesAllClick()">
            <span class="fa fa-paper-plane"></span>
        </button>
        <span *ngIf="isEnvoyerHonorairesSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span><br />
        <span class="SpanMargin" [ngStyle]="{ color: envoyerHonorairesInfoColor }">{{ envoyerHonorairesInfoMessage
            }}</span>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <ul>
            <li *ngFor="let res of genererHonorairesResults">
                <a href="{{ res.transactionUrl }}" target="_blank">
                    {{ res.transactionCodeNum }}
                </a>
                <span [ngStyle]="{ color: 'red' }">
                {{ res.erreurMessage }}
                </span>
            </li>
        </ul>
    </div>
</div>

<hr />

<div class="row">
    <div class="col-md-4">
        <h3>Factures Commission</h3>
    </div>
</div>

<div class="row">
    <div class="col-md-2">
        <h4>Valider</h4>
        <button class="btn btn-warning" [disabled]="isValiderFacturesCommissionSpinning" (click)="validerFacturesCommission()">
            <span class="fa fa-calculator"></span>
        </button>
        <span *ngIf="isValiderFacturesCommissionSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span>
        <span class="SpanMargin" [ngStyle]="{ color: validerFacturesCommissionInfoColor }">{{validerFacturesCommissionInfoMessage}}</span>
    </div>
    <div class="col-md-2">
        <h4>Payer</h4>
        <button class="btn btn-success" [disabled]="isPayerFacturesCommissionSpinning" (click)="payerFacturesCommission()">
            <span class="fa fa-money"></span>
        </button>
        <span *ngIf="isPayerFacturesCommissionSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span>
        <span class="SpanMargin" [ngStyle]="{ color: payerFacturesCommissionInfoColor }">{{payerFacturesCommissionInfoMessage}}</span>
    </div>
    <div class="col-md-2">
        <h4>Exporter</h4>
        <button class="btn btn-danger" (click)="exporterFacturesCommissionClick()">
            <span class="fa fa-upload"></span>
        </button>
        <span *ngIf="isExporterFacturesCommissionSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span>
        <span class="SpanMargin" [ngStyle]="{ color: exporterFacturesCommissionInfoColor }">{{exporterFacturesCommissionInfoMessage}}</span>
    </div>
    <div class="col-md-6">
        <h4>Provision</h4>
        <kendo-datepicker [(ngModel)]="dateDebutProvision" name="dateDebutProvision"></kendo-datepicker>
        <kendo-datepicker [(ngModel)]="dateFinProvision" name="dateFinProvision"></kendo-datepicker>
        <button class="btn enPreparationLegend" [disabled]="isGenererProvisionSpinning"
            (click)="genererProvisionClick()">
            <span class="fa fa-repeat"></span>
        </button>
        <span *ngIf="isGenererProvisionSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span>
        <span class="SpanMargin" [ngStyle]="{ color: genererProvisionInfoColor }">{{
            genererProvisionInfoMessage
            }}</span>
    </div>
</div>

<hr />

<div class="row">
    <div class="col-md-4">
        <h3>Factures Pack</h3>
    </div>
</div>

<div class="row">
    <div class="col-md-3">
        <h4>Générer</h4>
        <kendo-datepicker [(ngModel)]="asOfDate" name="asOfDate"></kendo-datepicker>
        <button class="btn enPreparationLegend" [disabled]="isGenererPackSpinning" (click)="genererClick($event)">
            <span class="fa fa-repeat"></span>
        </button>
        <span *ngIf="isGenererPackSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span><br />
        <span [ngStyle]="{ color: genererPackInfoColor }">{{
            genererPackInfoMessage
            }}</span>
    </div>
    <div class="col-md-2">
        <h4>Numéroter</h4>
        <button class="btn numeroteeLegend" [disabled]="isNumeroterPackSpinning" (click)="numeroterAllClick()">
            <span class="fa fa-sort-numeric-asc"></span>
        </button>
        <span *ngIf="isNumeroterPackSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span><br />
        <span>{{ numeroterPackInfoMessage }}</span>
    </div>
    <div class="col-md-2">
        <h4>Envoyer</h4>
        <button class="btn btn-info" [disabled]="isEnvoyerPackSpinning" (click)="envoyerAllClick()">
            <span class="fa fa-paper-plane"></span>
        </button>
        <span *ngIf="isEnvoyerPackSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span><br />
        <span class="SpanMargin">{{ envoyerPackInfoMessage }}</span>
    </div>
    <div class="col-md-2">
        <h4>Exporter</h4>
        <button class="btn btn-danger" (click)="exportAllClick()">
            <span class="fa fa-upload"></span>
        </button>
        <span *ngIf="isExportPackSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span>
        <span class="SpanMargin" [ngStyle]="{ color: exporterInfoColor }">{{
            exporterSageInfoMessage
            }}</span>
    </div>
    <div class="col-md-2">
        <h4>Actualiser Règles Conseillers</h4>
        <button class="btn enPreparationLegend" [disabled]="isActualiserReglesSpinning" (click)="actualiserReglesBancairesConseillers()">
            <span class="fa fa-users"></span>
        </button>
        <span *ngIf="isActualiserReglesSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span><br />
        <span>{{ actualiserReglesInfoMessage }}</span>
    </div>
</div>

<hr />

<div class="row">
    <div class="col-md-4">
        <h3>Factures Article</h3>
    </div>
</div>
  
<div class="row SpanMargin">    
    <div classe="col-md-1">
        <h6>Article</h6> &nbsp;
    </div>
    <div class="col-md-4">
    <label class="Width100 k-form-field">
        <app-article-selector
            [(article)]='article'
            name="article">
        </app-article-selector>
    </label>
    </div>
    <div class="col-md-6">        
        <input type="file" (change)="fileChange($event)" placeholder="Télécharger ficher" accept=".csv">
    
        <span *ngIf='isSpinning' class="k-i-loading k-icon" style="font-size: 32px;"></span>
        <span [ngStyle]="{ color: genererArticleInfoColor }">{{
            genererArticleInfoMessage
            }}</span>
        <span *ngIf='erreur' class="erreurMsg">{{erreur}}</span>
        &nbsp;
        <button class="btn enPreparationLegend"
            (click)="genererOperations()">
            <span class="fa fa-repeat"></span>
        </button>
    </div>
</div>

<hr />

<div class="row">
    <div class="col-md-4">
        <h3>Clôture comptes</h3>
    </div>
</div>

<div class="row">
    <div class="col-md-3">
        <kendo-datepicker [(ngModel)]="dateCloture" name="dateCloture"></kendo-datepicker>
        <button class="btn enPreparationLegend" [disabled]="isSauvegarderDateClotureSpinning" (click)="sauvegarderDateCloture()">
            <span class="fa fa-floppy-o"></span>
        </button>
        <span *ngIf="isSauvegarderDateClotureSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span>
    </div>
</div>

<hr />

<div class="row">
    <div class="col-md-4">
        <h3>Conseillers Débiteurs</h3>
    </div>
</div>

<div class="row">
    <div class="col-md-2">
        <h4>Import Soldes</h4>
        <input type="file" (change)="fileSoldesChange($event)" placeholder="Télécharger ficher" accept=".csv" />
        <br />
        <br />
        <button class="btn enPreparationLegend" [disabled]="isImportSoldesSpinning" (click)="importSoldes()"><span class="fa fa-floppy-o"></span></button>
        <span *ngIf="isImportSoldesSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span>
        <span class="SpanMargin" [ngStyle]="{ color: importSoldesInfoColor }">{{importSoldesInfoMessage}}</span>
    </div>
    <div class="col-md-2">
        <h4>Suspension impayés</h4>
        <button title="Suspension impayés" class="btn btn-danger" [disabled]='isSuspendreImpayesSpinning' (click)="suspendreImpayes()"><span class="fa fa-ban"></span></button> 
        <br/>
        Suspendre pour motif impayés avec envoi mail tout conseiller ayant un solde débiteur (plus commission compromis signature prévu dans les 6 mois à venir) supérieur à deux fois montant pack.
        <span *ngIf="isSuspendreImpayesSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span>
        <span class="SpanMargin" [ngStyle]="{ color: suspendreImpayesInfoColor }">{{suspendreImpayesInfoMessage}}</span>
    </div>
    <div class="col-md-2">
        <h4>Export impayés actifs</h4>
        <button title="Export impayés actifs" class="btn btn-danger" [disabled]='isExportImpayesSpinning' (click)="exportImpayes()"><span class="fa fa-upload"></span></button>
        <h4>Export impayés inactifs</h4>
        <button title="Export impayés inactifs" class="btn btn-danger" [disabled]='isExportImpayesSpinning' (click)="exportImpayesInactif()"><span class="fa fa-upload"></span></button>
        <span *ngIf="isExportImpayesSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span>
        <span class="SpanMargin" [ngStyle]="{ color: exportImpayesInfoColor }">{{exportImpayesInfoMessage}}</span>
    </div>
    <div class="col-md-2">
        <h4>Relance impayés</h4>
        <button class="btn btn-info" [disabled]="isRelanceImpayesSpinning" (click)="relanceImpayes()"><span class="fa fa-bullhorn"></span></button>
        <span *ngIf="isRelanceImpayesSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span>
        <span class="SpanMargin" [ngStyle]="{ color: relanceImpayesInfoColor }">{{relanceImpayesInfoMessage}}</span>
    </div>
    <div class="col-md-2">
        <h4>Mise en recouvrement</h4>
        <button class="btn btn-danger" [disabled]="isMiseEnRecouvrementSpinning" (click)="miseEnRecouvrement()"><span class="fa fa-hand-scissors-o"></span></button>
        <br/>
        Suspendre pour motif recouvrement tout conseiller déjà suspendu pour impayé plus de 60 jours.
        <span *ngIf="isMiseEnRecouvrementSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span>
        <span class="SpanMargin" [ngStyle]="{ color: miseEnRecouvrementInfoColor }">{{miseEnRecouvrementInfoMessage}}</span>
    </div>
    <div class="col-md-2">
        <h4>Export recouvrement</h4>
        <button class="btn btn-danger" [disabled]="isExportRecouvrementSpinning" (click)="exportRecouvrement()"><span class="fa fa-upload"></span></button>
        <span *ngIf="isExportRecouvrementSpinning" class="k-i-loading k-icon" style="font-size: 32px"></span>
        <span class="SpanMargin" [ngStyle]="{ color: exportRecouvrementInfoColor }">{{exportRecouvrementInfoMessage}}</span>
    </div>
    
</div>

<hr />

<div class="row">
    <div class="col-md-4">
        <h3>Impôts</h3>
    </div>
</div>

<div class="row">
    <div class="col-md-2">
        <h4>Export DAS2</h4>
        <select [(ngModel)]="buExportDAS2">
            <option value="None">-- Sélectionner BU --</option>
            <option value="Capi">Capi</option>
            <option value="Oh">Oh</option>
            <option value="Digit">Digit</option>
            <option value="DigitAcademie">DigitAcademie</option>
            <option value="ImmobilierNeuf">ImmobilierNeuf</option>
            <option value="Dili">Dili</option>
            <option value="Difinn">Difinn</option>
            <option value="TopDifinn">TopDifinn</option>
            <option value="Fidelity19">Fidelity19</option>
            <option value="Reflex">Reflex</option>
        </select>
        <input type="file" (change)="fileDAS2Change($event)" placeholder="Télécharger ficher" accept=".csv" />
        <br />
        <br />
        <button class="btn enPreparationLegend" [disabled]="isExportDAS2Spinning" (click)="exportDAS2()"><span class="fa fa-floppy-o"></span></button>
        <span *ngIf="isExportDAS2Spinning" class="k-i-loading k-icon" style="font-size: 32px"></span>
        <span class="SpanMargin" [ngStyle]="{ color: exportDAS2InfoColor }">{{exportDAS2InfoMessage}}</span>
    </div>
</div>