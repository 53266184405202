<div [ngClass]="Bu">

    <app-serveur-erreur></app-serveur-erreur>
    <app-client-erreur></app-client-erreur>

    <kendo-tabstrip [keepTabContent]="true">
        <ng-container *ngIf="Bu=='Capi' || Bu=='Oh'">
            <kendo-tabstrip-tab [title]="'Factures Honoraires'">
                <ng-template kendoTabContent *loadTabContentOnDemand>
                    <h3>Factures Honoraires</h3>
                    <app-factures-honoraires-search (searchClicked)="factureHonorairesGrid.applySearchModel($event)"></app-factures-honoraires-search>
                    <br />
                    <app-factures-honoraires-grid #factureHonorairesGrid></app-factures-honoraires-grid>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab [title]="'Factures Commission'">
                <ng-template kendoTabContent *loadTabContentOnDemand>
                    <h3>Factures Commission</h3>
                    <app-factures-commission-search (searchClicked)="factureCommissionGrid.applySearchModel($event)"></app-factures-commission-search>
                    <br />
                    <app-factures-commission-grid #factureCommissionGrid></app-factures-commission-grid>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab [title]="'Factures Pack'">
                <ng-template kendoTabContent *loadTabContentOnDemand>
                    <h3>Factures Pack</h3>
                    <app-factures-pack-search (searchClicked)="facturePackGrid.applySearchModel($event)"></app-factures-pack-search>
                    <br />
                    <app-factures-pack-grid #facturePackGrid></app-factures-pack-grid>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab [title]="'Opérations'">
                <ng-template kendoTabContent *loadTabContentOnDemand>
                    <h3>Opérations</h3>
                    <app-bulks></app-bulks>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab [title]="'Planning'">
                <ng-template kendoTabContent *loadTabContentOnDemand>
                    <h3>Plannification d'articles</h3>
                    <app-planned-facture-detail-form></app-planned-facture-detail-form>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab [title]="'Ajouter'">
                <ng-template kendoTabContent *loadTabContentOnDemand>
                    <h3>Ajouter des lignes de facturation</h3>
                    <app-new-facture-detail-form></app-new-facture-detail-form>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab [title]="'Articles'">
                <ng-template kendoTabContent *loadTabContentOnDemand>
                    <h3>Maintenance des articles</h3>
                    <app-articles></app-articles>
                </ng-template>
            </kendo-tabstrip-tab>                
        </ng-container>

            <kendo-tabstrip-tab [title]="'Regles Bancaires'">
                <ng-template kendoTabContent *loadTabContentOnDemand>
                    <h3>Maintenance des regles</h3>
                    <app-regles></app-regles>
                </ng-template>
            </kendo-tabstrip-tab>

        <ng-container *ngIf="Bu=='Capi' || Bu=='Oh'">
            <kendo-tabstrip-tab [title]="'Soldes'" >
                <ng-template kendoTabContent *loadTabContentOnDemand>
                    <h3>Soldes</h3>
                    <app-soldes></app-soldes>
                </ng-template>
            </kendo-tabstrip-tab>
        </ng-container>

            <kendo-tabstrip-tab [title]="'Banque'" [selected]="true">
                <ng-template kendoTabContent *loadTabContentOnDemand>
                    <h3>Banque</h3>
                    <app-banque></app-banque>
                </ng-template>
            </kendo-tabstrip-tab>

        <ng-container *ngIf="Bu=='Capi' || Bu=='Oh'">
            <kendo-tabstrip-tab [title]="'Transactions'">
                <ng-template kendoTabContent *loadTabContentOnDemand>
                    <h3>Transactions</h3>
                    <app-payer-transaction></app-payer-transaction>
                </ng-template>
            </kendo-tabstrip-tab>
        </ng-container>
    </kendo-tabstrip>
</div>