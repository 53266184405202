import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { IFactureDetailPlanifiesDto, IFactureDetailPlannifiesDto, IOccurenceDto } from "../dtos/factureDetailPlanifies.dto";
import { IConseiller } from "../models.interfaces/conseiller.model.interface";
import { IPlanningItem } from "../models.interfaces/planningItem.model.interface";
import { IYearAndMonth } from "../models.interfaces/yearAndMonth.model.interface";
import { PlanningItem } from "../models/planningItem.model";
import { Observable } from 'rxjs/Observable';
import { BuInterceptorService } from "./buInterceptor.service";

@Injectable()
export class FacturePackDetailPlannifiesService {
    
    constructor(private http: HttpClient) {}
    
    private getApiUrl(): string {
        return `${environment.apiBaseUrl}/FacturePackDetailPlannifies/${this.bu()}`
    }

    private bu(): string {
        return BuInterceptorService.Bu;
    }
    
    public getFactureDetailPlannifies(codeImputationClient: string, annee: number): Observable<IPlanningItem[]> {
        let params: HttpParams = new HttpParams();
        params = params.set('codeImputationClient', codeImputationClient);
        params = params.append('annee', annee.toString());
        return this
            .http
            .get<IFactureDetailPlanifiesDto>(`${this.getApiUrl()}`, { params: params })
            .map(dto => {
                const result = new Map<string, IPlanningItem>();
                dto.Articles.forEach(articleDto => {
                    if (!result.has(articleDto.Article))
                        result.set(articleDto.Article, new PlanningItem(articleDto.Article, false));
                    articleDto.Occurences.forEach(occ =>
                        result.get(articleDto.Article).occurences.push(<IYearAndMonth>{ annee: occ.Annee, mois: occ.Mois }));
                });
                return Array.from(result.values());
            });
    }

    
    public postNewFactureDetailPlannifies(conseiller: IConseiller, annee: number, planningItems: IPlanningItem[]): Observable<any> {
        const payload = <IFactureDetailPlanifiesDto>{
            CodeImputationClient: conseiller.codeClient,
            Annee: annee,
            Articles: planningItems.map(item => <IFactureDetailPlannifiesDto>{
                Article: item.article.nom,
                Occurences: item.occurences.map(occ => <IOccurenceDto>{
                    Annee: occ.annee,
                    Mois: occ.mois
                })
            })
        };
        return this.http.post<string>(`${this.getApiUrl()}`, payload);
    }

}