import { Component, OnInit, ChangeDetectionStrategy,ChangeDetectorRef } from '@angular/core';
import { INewFactureDetailModel } from '../../../models.interfaces/newFactureDetail.model.interface';
import { EmptyFactureDetailModel } from '../../../models/emptyFactureDetail.model';
import { IConseiller } from '../../../models.interfaces/conseiller.model.interface';
import { FacturePackService } from 'src/app/services/facturePack.service';

@Component({
  selector: 'app-new-facture-detail-form',
  templateUrl: './new-facture-detail-form.component.html',
  styleUrls: ['./new-facture-detail-form.component.scss'],
  providers: [FacturePackService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewFactureDetailFormComponent implements OnInit {

  public infoMessage: string;
  public infoColor: string;
  public isSpinning: boolean;
  public newFactureDetailModel: INewFactureDetailModel = new EmptyFactureDetailModel();

  constructor(private _facturePackService: FacturePackService, private changeRef:ChangeDetectorRef) { }

  ngOnInit() {
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
    this.changeRef.detectChanges();
    });
  }

  public setConseiller(value: IConseiller) {
    this.newFactureDetailModel.conseiller = value;
  }

  public onTypeDeDocChange(type: number) {
      this.newFactureDetailModel.typeDeDoc = type;
  }

  public onTypeDeProduitChange(type: number) {
      this.newFactureDetailModel.typeDeProduit = type;
  }

  public onSaveClick() {
    if(this.newFactureDetailModel.compte == null || this.newFactureDetailModel.compte == ""){
      this.setInfoMessage("Compte obligatroire", "red")
      return;
    }

    this.clearInfoMessage();
    this.isSpinning = true;
    this
      ._facturePackService
      .postNewFactureDetail(this.newFactureDetailModel)
      .finally(() => {
        this.isSpinning = false;
    })
      .subscribe(
        _ => this.setInfoMessage("Ajouté", "green"),
        
        
        (err) => {
          this.isSpinning = false; 
          this.setInfoMessage(err.message, "red");
        }
      );
  }

  public onClearClick() {    
    this.clearInfoMessage();
    this.newFactureDetailModel = new EmptyFactureDetailModel();
  }

  private setInfoMessage(msg: string, color: string) {
    this.infoMessage = msg;
    this.infoColor = color;
  }

  private clearInfoMessage() {
    this.infoMessage = "";
  }

  public changeNoTva(noTva: boolean) {
    this.newFactureDetailModel.NePasAppliquerTva = !noTva;
  }

}
