import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Helper } from '../utils/helper';
import { IFactureCommissionSummaryDto } from '../dtos/factureCommissionSummary.dto';
import { IFactureCommissionSummary } from '../models.interfaces/factureCommissionSummary.model.interface';
import { IFactureCommissionSearchModel } from '../models.interfaces/factureCommissionSearch.model.interface';
import { BuInterceptorService } from "./buInterceptor.service";
import { IFactureCommissionOperationResult } from '../models.interfaces/factureCommissionOperationResult.model.interface';
import { IFactureCommissionDetailDto } from '../dtos/factureCommissionDetail.dto';
import { IFactureCommissionDetail } from '../models.interfaces/factureCommissionDetail.model.interface';


@Injectable()
export class FactureCommissionService {

    public results: BehaviorSubject<GridDataResult> = new BehaviorSubject<GridDataResult>(null);
    public errorMessages: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    constructor(private http: HttpClient) {
    }

    private getApiUrl(): string {
        return `${environment.apiBaseUrl}/FacturesCommission/${this.bu()}`
    }
    
    private bu(): string {
        return BuInterceptorService.Bu;
    }

    public query(searchModel: IFactureCommissionSearchModel, state: any): void {
        this.getFactures(searchModel, state)
            .subscribe(
                x => this.results.next(x),
                (err: Error) => this.errorMessages.next(err.message))
    }

    public getFactures(searchModel: IFactureCommissionSearchModel, state: any): Observable<GridDataResult> {
        return this
            .http
            .post<any>(
                `${this.getApiUrl()}/search`,
                {
                    skip: state.skip,
                    take: state.take,
                    numeroFacture: searchModel.numero,
                    dateFactureDu: searchModel.du,
                    dateFactureAu: searchModel.au,
                    statutFacture: searchModel.statut.statut,
                    montantTtcMin: searchModel.min,
                    montantTtcMax: searchModel.max,
                    transactionCodeNum: searchModel.transactionCodeNum,
                    codeFournisseur: searchModel.codeFournisseur,
                    anneeSignature: searchModel.anneeSignature
                }
            )
            .map(res => <GridDataResult>{total: res.Count, data: Helper.mapDtoToModels(res.Result, this.mapFacture)});
    }


    private mapFacture(dto: IFactureCommissionSummaryDto): IFactureCommissionSummary {
        return <IFactureCommissionSummary> {
          id: dto.Id,
          dateFacture: new Date(dto.DateFacture),
          datePaiement: (dto.DatePaiement == null) ? null : new Date(dto.DatePaiement),
          statut: dto.Statut,
          numero: dto.NumeroFacture,
          documenturl: Helper.fileManagerUrlFactureCommission(BuInterceptorService.Bu, dto.Id),
          typeAffaire: dto.TypeAffaire,
          transactionCodeNum: dto.TransactionCodeNum,
          transactionUrl: Helper.tremplinTransactionRemunerationUrl(BuInterceptorService.Bu, dto.TransactionCodeNum),
          baseCalculHt: dto.BaseCalculHt,
          taux: dto.Taux,
          totalHt: dto.Ht,
          totalTva: dto.Tva,
          totalTtc: dto.Ttc,
          nomClients: dto.NomClients,
          nomEtPrenom: dto.NomEtPrenom
        };
    }

    public getFactureDetails(factureId: string): Observable<IFactureCommissionDetail[]> {
        return this
            .http
            .get<IFactureCommissionDetailDto[]>(`${this.getApiUrl()}/${factureId}/details`)
            .map(dtos => Helper.mapDtoToModels(dtos, this.mapFactureDetail));
    }

    private mapFactureDetail(dto: IFactureCommissionDetailDto): IFactureCommissionDetail {
        return <IFactureCommissionDetail>{
            libelle: dto.Libelle,
            caMin: dto.CaMin,
            caMax: dto.CaMax,
            taux: dto.Taux / 100,
            fractionBaseCalculHt: dto.FractionBaseCalculHt,
            ht: dto.Ht
        };
    }

    public getFacturesPourValidation(): Observable<string[]>{
        return this
            .http
            .get<string[]>(`${this.getApiUrl()}/pourValidation`);
    }

    public valider(factureId: string): Observable<IFactureCommissionOperationResult>{
        return this
            .http
            .post<IFactureCommissionOperationResult>(`${this.getApiUrl()}/valider/${factureId}`, null);
    }

    public getFacturesPourPaiement(): Observable<string[]>{
        return this
            .http
            .get<string[]>(`${this.getApiUrl()}/pourPaiement`);
    }

    public payer(factureId: string): Observable<IFactureCommissionOperationResult>{
        return this
            .http
            .post<IFactureCommissionOperationResult>(`${this.getApiUrl()}/payer/${factureId}`, null);
    }

    public exporter(): Observable<string>{
        return this
            .http
            .post<any>(`${this.getApiUrl()}/export`, null);
    }

    public annuler(factureId: string): Observable<IFactureCommissionOperationResult>{
        return this
            .http
            .post<IFactureCommissionOperationResult>(`${this.getApiUrl()}/annuler/${factureId}`, null);
    }

}