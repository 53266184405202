<kendo-dropdownlist
    [data]="filteredArticles"
    [filterable]="true"
    [textField]="'nom'"
    [valueField]="'nom'"
    [value]="article"
    (valueChange)="onValueChange($event)"
    (filterChange)="handleFilter($event)"
    class="Width100"
>
</kendo-dropdownlist>